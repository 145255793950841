import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getItemFromLocalStorage, tabFilter } from "../../../helpers/utils";
import {
  clearMySchedule,
  getScheduleDetails,
} from "../../MySchedules/actions/myScheduleActions";
import Header from "../Header";
import { PAGE_DATA, TAB_TYPES, WORDINGS } from "../../../helpers/constants";
import NewButton from "../../../components/NewButton";
import Loader from "../../../components/Loader";
import ScheduleTab from "./ScheduleTab";
import ConfirmationTab from "./ConfirmationTab";
import CrewTab from "./CrewTab";
import styles from "./index.module.css";

function ScheduleDetails(props) {
  const dispatch = useDispatch();
  const { units } = getItemFromLocalStorage("user") || {};
  const scheduleId = props.match.params.scheduleId;

  const localTab = getItemFromLocalStorage(PAGE_DATA.TAB) || "scheduleDetails";

  const [loader, setLoader] = useState(false),
    [activeTab, setActiveTab] = useState(localTab);

  useEffect(() => {
    tabFilter(activeTab);
    if (scheduleId) {
      setLoader(true);
      dispatch(getScheduleDetails(units[0]?.id, scheduleId, setLoader));
    }
    return () => dispatch(clearMySchedule());
  }, [activeTab]);

  const tabs = [
    {
      id: TAB_TYPES.SCHEDULE_DETAILS,
      name: WORDINGS.SCHEDULE_DETAILS,
    },
    {
      id: TAB_TYPES.CONFIRMATIONS,
      name: WORDINGS.CONFIRMATIONS,
    },
    {
      id: TAB_TYPES.CREW,
      name: WORDINGS.CREW,
    },
  ];

  return (
    <div className={styles["schedule-details"]}>
      <Header details />
      <div className={styles["schedule-details__tab-container"]}>
        {tabs.map((item, index) =>
          activeTab === item?.id ? (
            <NewButton key={index} smallTabButton smallTabButtonActive>
              {item?.name}
            </NewButton>
          ) : (
            <NewButton
              key={index}
              smallTabButton
              onClick={() => setActiveTab(item.id)}
            >
              {item?.name}
            </NewButton>
          )
        )}
      </div>
      {loader ? (
        <div className={styles["schedule_loader"]}>
          <Loader />
        </div>
      ) : (
        <div>
          {activeTab === TAB_TYPES.SCHEDULE_DETAILS && <ScheduleTab />}
          {activeTab === TAB_TYPES.CONFIRMATIONS && <ConfirmationTab />}
          {activeTab === TAB_TYPES.CREW && <CrewTab />}
        </div>
      )}
    </div>
  );
}

export default ScheduleDetails;
