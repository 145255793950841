import { joinClass } from "../../helpers/utils";
import styles from "./index.module.css";

const NewButton = ({
  children,
  className,
  borderButton,
  shadow,
  onClick,
  disabled,
  largeButton,
  popupButton,
  smallButton,
  cancelButton,
  largePopupButton,
  smallTabButton,
  smallTabButtonActive,
  labelButton,
  modalButton,
  type,
  isLoading,
}) => {
  return (
    <div className={styles["container"]}>
      {isLoading && <div className={styles["loader"]} />}
      <button
        className={joinClass(
          styles.button,
          shadow && styles["button--shadow"],
          cancelButton && styles["button--cancel"],
          largeButton && styles["button--large"],
          smallButton && styles["button--small"],
          popupButton && styles["button--popup"],
          largePopupButton && styles["button--large-popup"],
          smallTabButton && styles["button--small-tab"],
          smallTabButtonActive && styles["button--small-tab-active"],
          labelButton && styles["button--label-button"],
          modalButton && styles["button--modal-button"],
          className
        )}
        onClick={onClick}
        disabled={disabled}
        type={type}
      >
        {!isLoading && children}
      </button>
    </div>
  );
};

export default NewButton;
