import requestMethods from "../../helpers/request";
import { LOGIN_ACTIONS } from "./constants";
import { permissionUtil } from "../../helpers/permission";
import { handleAlertError, history } from "../../helpers/utils";

export const login =
  (username, password, firebaseToken, loader) => async (dispatch) => {
    try {
      const res = await requestMethods.POST({
        endpoint: "v1/user/pwa-login",
        body: {
          email: username,
          password,
          firebase_token: firebaseToken
            ? firebaseToken
            : "seemymachines-ot-module-dummy-fcm-token",
        },
      });
      if (res.status) {
        dispatch({ type: LOGIN_ACTIONS.LOGIN_SUCCESS, payload: res.data });
        localStorage.setItem(
          "user",
          JSON.stringify({
            ...res,
            privileges: permissionUtil(res.privileges || []),
          })
        );
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("useraccess", JSON.stringify(res.token.token));
        localStorage.setItem(
          "userexpiry",
          JSON.stringify(res.token.expires_at)
        );
        history.replace("/home");
      }
    } catch (error) {
      dispatch(handleAlertError(error.message));
      if (loader) loader(false);
    }
  };

export const logout = () => async (dispatch) => {
  try {
    const res = await requestMethods.PUT({
      endpoint: "v1/user/pwa-logout",
    });
    if (res.status) {
      dispatch({ type: LOGIN_ACTIONS.LOGOUT, payload: res });
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("user");
      localStorage.removeItem("userexpiry");
      localStorage.removeItem("useraccess");
      localStorage.removeItem("tab");
      history.replace("/");
    }
  } catch (error) {
    dispatch(handleAlertError(error.message));
  }
};
