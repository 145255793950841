import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoNotifications } from "react-icons/io5";
import { joinClass } from "../../helpers/utils";
import { TOASTER_ACTIONS } from "./contants";
import { ReactComponent as InfoIcon } from "../../assets/Icons/info.svg";
import { ReactComponent as Success } from "../../assets/Icons/success.svg";
import styles from "./index.module.css";

const NewToaster = () => {
  const dispatch = useDispatch();
  const { message, type } = useSelector((state) => state.newToasterReducer);

  useEffect(() => {
    setTimeout(() => {
      dispatch({ type: TOASTER_ACTIONS.RESET_NEW_TOASTER_DATA });
    }, 3500);
  }, []);

  const iconMap = {
    notification: <IoNotifications size={25} color="#0084ff" />,
    success: <Success size={25} color="#48B16E" />,
    error: <InfoIcon size={20} color="#D3D8DD" />,
  };

  return (
    <div className={joinClass(styles["new-toaster"])}>
      <div>{iconMap[type]}</div>
      <div>{message}</div>
    </div>
  );
};

export default NewToaster;
