import React, { useState } from "react";
import { BUTTON_TYPES, GLOBAL_CONSTANTS } from "../../../helpers/constants";
import Counter from "../../../components/Counter";
import Modal from "../../../components/Modal";
import NewButton from "../../../components/NewButton";
import styles from "./index.module.css";

const EquipmentModelUpdate = (props) => {
  const {
    isOpen,
    toggleOpen,
    equipmentModel,
    setEquipmentModel,
    equipmentModels,
    setEquipmentModels,
  } = props;

  const [qty, setQty] = useState(equipmentModel?.quantity);
  const decrement = () => {
    if (qty > 1) {
      setQty(qty - 1);
    }
  };

  const increment = () => {
    setQty(qty + 1);
  };

  return (
    <Modal
      isOpen={isOpen}
      onCancel={() => {
        toggleOpen(false);
        setEquipmentModel(null);
      }}
    >
      <div className={styles["equipment-model-update"]}>
        <div className={styles["equipment-model-update__title"]}>
          <span>{`${GLOBAL_CONSTANTS.EQUIPMENTS}: ${equipmentModel?.name}`}</span>
        </div>
        <Counter
          count={qty}
          increment={increment}
          decrement={decrement}
          minimumValue={1}
        />
        <div className={styles["equipment-model-update__button-container"]}>
          <NewButton
            shadow
            onClick={() => {
              if (+qty > 0) {
                setEquipmentModels({
                  ...equipmentModels,
                  [equipmentModel?.id]: {
                    ...equipmentModel,
                    quantity: +qty,
                  },
                });
                setEquipmentModel(null);
                toggleOpen(false);
              }
            }}
            disabled={+qty < 1}
          >
            {BUTTON_TYPES.UPDATE  }
          </NewButton>
          <NewButton
            shadow
            cancelButton
            onClick={() => {
              toggleOpen(false);
              setEquipmentModel(null);
            }}
          >
            {BUTTON_TYPES.CANCEL}
          </NewButton>
        </div>
      </div>
    </Modal>
  );
};

export default EquipmentModelUpdate;
