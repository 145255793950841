import { TOASTER_ACTIONS } from "./contants";

const initialState = {
  message: "",
  type: "",
  title: "",
};

export const newToasterReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOASTER_ACTIONS.SET_NEW_TOASTER_DATA: {
      return {
        ...state,
        message: action.notification.message,
        type: action.notification.type,
        title: action.notification.title,
      };
    }
    case TOASTER_ACTIONS.RESET_NEW_TOASTER_DATA: {
      return {
        ...state,
        message: "",
        type: "",
        title: "",
      };
    }
    default:
      return state;
  }
};
