import requestMethods from "../../../helpers/request";
import { getItemFromLocalStorage } from "../../../helpers/utils";
import { getRequestDetails } from "../../BookingOverview/actions/bookingActions";
import { MY_SCHEDULE_ACTIONS } from "./constants";

export const getMySchedules =
  (
    unitId,
    otId,
    surgeryTypeId,
    timestamp,
    page,
    size,
    sort,
    ignorePaging,
    loader
  ) =>
  async (dispatch) => {
    const { company: { id: companyId = "" } = {} } =
      getItemFromLocalStorage("user") || {};
    try {
      const res = await requestMethods.GET({
        endpoint: `v1/ot/schedule/web/${companyId}/${unitId}/pwa-get-all`,
        params: {
          otId,
          surgeryTypeId,
          timestamp,
          page,
          size,
          sort,
          ignorePaging,
        },
      });
      if (res.status) {
        dispatch({
          type: MY_SCHEDULE_ACTIONS.GET_MY_SCHEDULE_SUCCESS,
          payload: res.schedule_requests,
          totalCount: res.total_count,
        });
        if (loader) loader(false);
      }
    } catch (error) {
      dispatch({
        type: "SET_TOASTER_DATA",
        notification: {
          message: error.message,
          title: "Error",
          type: "error",
        },
      });
      if (loader) loader(false);
    }
  };

export const getScheduleDetails =
  (unitId, scheduleId, loader) => async (dispatch) => {
    const { company: { id: companyId = "" } = {} } =
      getItemFromLocalStorage("user") || {};
    try {
      const res = await requestMethods.GET({
        endpoint: `/v1/ot/schedule/web/${companyId}/${unitId}/get-all-schedules`,
        params: {
          scheduleId,
        },
      });
      if (res.status) {
        dispatch(getRequestDetails(unitId, res.schedules[0].tentative_id));
        dispatch({
          type: MY_SCHEDULE_ACTIONS.GET_SCHEDULE_DETAILS,
          payload: res.schedules[0],
        });
        if (loader) loader(false);
      }
    } catch (error) {
      dispatch({
        type: "SET_TOASTER_DATA",
        notification: {
          message: error.message,
          title: "Error",
          type: "error",
        },
      });
      if (loader) loader(false);
    }
  };

export const clearMySchedule = () => {
  return {
    type: MY_SCHEDULE_ACTIONS.CLEAR_MY_SCHEDULE_STORE,
  };
};
