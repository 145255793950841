import { initializeApp } from "@firebase/app";
import { getToken, getMessaging, onMessage } from "@firebase/messaging";

// Firebase configuration object
const firebaseConfig = {
  apiKey: "AIzaSyBr-ejAlvrzgwZjmbnlqr7edAWfVnhL7tM",
  authDomain: "seemyporters-supervisor.firebaseapp.com",
  databaseURL: "https://seemyporters-supervisor-default-rtdb.firebaseio.com",
  projectId: "seemyporters-supervisor",
  storageBucket: "seemyporters-supervisor.appspot.com",
  messagingSenderId: "282122947784",
  appId: "1:282122947784:web:52bc7d372c0055d47aa9fd",
  measurementId: "G-42TPCBSZZ6",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

const getOrRegisterServiceWorker = async () => {
  if ("serviceWorker" in navigator) {
    const serviceWorker = await window.navigator.serviceWorker.getRegistration(
      "/firebase-cloud-messaging-push-scope"
    );

    if (serviceWorker) return serviceWorker;

    return window.navigator.serviceWorker.register(
      "/firebase-messaging-sw.js",
      {
        scope: "/firebase-cloud-messaging-push-scope",
      }
    );
  }
  throw new Error("The browser doesn`t support service worker.");
};

export const requestPermission = () => {
  console.log("Requesting User Permission......");

  if (window.Notification) {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        console.log("Notification User Permission Granted.");

        getOrRegisterServiceWorker().then(async (serviceWorkerRegistration) => {
          //for getting updated service worker
          await serviceWorkerRegistration.update();

          getToken(messaging, {
            vapidKey: process.env.REACT_APP_VAPID_KEY,
            serviceWorkerRegistration,
          })
            .then((currentToken) => {
              if (currentToken) {
                console.log("Client Token: ", currentToken);
                localStorage.setItem("@fcmtoken", currentToken);
              } else {
                console.log("Failed to generate the app registration token.");
              }
            })
            .catch((err) => {
              console.log(
                "An error occurred when requesting to receive the token.",
                err
              );
            });
        });
      } else {
        console.log("User Permission Denied.");
      }
    });
  } else {
    localStorage.setItem(
      "@fcmtoken",
      "seemymachines-ot-module-dummy-fcm-token"
    );
  }
};

export const onMessageListener = () => {
  return new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
};
