import React from "react";
import scissors from "../../assets/Icons/scissors-cut-01.svg";
import clock from "../../assets/Icons/clock.svg";
import location from "../../assets/Icons/location.svg";
import hospital from "../../assets/Icons/hospital.svg";
import styles from "./index.module.css";
import { joinClass } from "../../helpers/utils";

const DetailCard = ({ ot, time, speciality, surgery, handleClick }) => {
  return (
    <div className={styles["detail-card"]} onClick={handleClick}>
      <div className={styles["detail-card__item"]}>
        <img src={location} alt="OT" />
        <label>{ot}</label>
      </div>
      <div className={styles["detail-card__item"]}>
        <img src={clock} alt="time" />
        <label>{time}</label>
      </div>
      <div className={styles["detail-card__item"]}>
        <img src={hospital} alt="speciality" />
        <label>{speciality}</label>
      </div>
      <div
        className={joinClass(
          styles["detail-card__item"],
          surgery.length > 1 && styles["detail-card__last"]
        )}
      >
        <img src={scissors} alt="surgery" />
        {surgery.length > 1 ? (
          <label>
            {surgery.map((surgery, index) => (
              <div key={index}>
                {index + 1}. {surgery.name}
              </div>
            ))}
          </label>
        ) : (
          <label>
            {surgery.map((surgery, index) => (
              <div key={index}>{surgery.name}</div>
            ))}
          </label>
        )}
      </div>
    </div>
  );
};

export default DetailCard;
