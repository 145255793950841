import { IoMdAdd, IoMdRemove } from "react-icons/io";
import { joinClass } from "../../helpers/utils";
import styles from "./index.module.css";

const Counter = ({
  count,
  decrement,
  increment,
  className,
  label,
  minimumValue,
}) => {
  return (
    <div className={joinClass(styles["counter"], className && className)}>
      {label && <div className={styles["counter__label"]}>{label}</div>}
      <div className={styles["counter__data-group"]}>
        <div
          onClick={decrement}
          className={joinClass(
            styles["counter__data-group-buttons"],
            minimumValue === count &&
              styles["counter__data-group-buttons__disabled"],styles["counter__data-group-buttons-minus"]
          )}
        >
          <IoMdRemove fontSize={20} />
        </div>
        <span className={styles["counter__data-group-value"]}>{count}</span>
        <div
          onClick={increment}
          className={styles["counter__data-group-buttons"]}
        >
          <IoMdAdd fontSize={22} />
        </div>
      </div>
    </div>
  );
};

export default Counter;
