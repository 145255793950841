import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { validateSurgeryTimeForRequest } from "../../BookingOverview/actions/bookingActions";
import {
  getItemFromLocalStorage,
  handleAlertSuccess,
} from "../../../helpers/utils";
import {
  BUTTON_TYPES,
  DATE_TIME_FORMAT,
  GLOBAL_CONSTANTS,
  GLOBAL_MESSAGES,
  WORDINGS,
} from "../../../helpers/constants";
import Modal from "../../../components/Modal";
import AdvancedDropdown from "../../../components/AdvancedDropdown";
import NewButton from "../../../components/NewButton";
import styles from "./index.module.css";
import TextInput from "../../../components/TextInput";

const createTimeObject = (time) => {
  return {
    id: moment(time).format(DATE_TIME_FORMAT.X).toString(),
    time: moment(time),
    label: moment(time).format(DATE_TIME_FORMAT.hh_mm_A),
  };
};

const TimeValidation = (props) => {
  const dispatch = useDispatch();

  const { units } = getItemFromLocalStorage("user") || {};

  const {
    setNewStartTime,
    setNewEndTIme,
    newStartTime,
    newEndTime,
    isOpen,
    toggleOpen,
    bufferTime,
    otId,
    chiefSurgeonId,
    procedureTime,
    selectedOtStartTime,
    selectedOtEndTime,
    isEmergency,
    newBufferTime,
    setNewBufferTime,
  } = props;

  const [startTime, setStartTime] = useState(createTimeObject(newStartTime));
  const [endTime, setEndTime] = useState(
    createTimeObject(moment(newEndTime).subtract(newBufferTime, "milliseconds"))
  );
  const [isValidate, toggleValidate] = useState(false);
  const [data, setData] = useState(null);
  const [isStartTimeValid, setIsStartTimeValid] = useState(false);
  const [isEndTimeValid, setIsEndTimeValid] = useState(false);
  const [editBufferTime, setEditBufferTime] = useState(newBufferTime);

  useEffect(() => {
    let newTime = +moment(startTime.time);
    if (isEmergency) {
      if (newTime <= selectedOtStartTime) {
        setIsStartTimeValid(false);
      } else {
        setIsStartTimeValid(true);
      }
    } else {
      if (newTime >= selectedOtStartTime && newTime < selectedOtEndTime) {
        setIsStartTimeValid(false);
      } else {
        setIsStartTimeValid(true);
      }
    }
  }, [startTime, selectedOtStartTime, selectedOtEndTime, endTime]);

  const startTimes = () => {
    const data = [];

    let start = moment(newStartTime).startOf(DATE_TIME_FORMAT.DAY);

    while (moment(start) <= moment(newStartTime).endOf(DATE_TIME_FORMAT.DAY)) {
      data.push({
        id: moment(start)
          .set({ seconds: 0, milliseconds: 0 })
          .format(DATE_TIME_FORMAT.X)
          .toString(),
        time: moment(start),
        label: moment(start).format(DATE_TIME_FORMAT.hh_mm_A),
      });

      start = moment(start).add(10, "minute");
    }

    return data;
  };

  const endTimes = () => {
    const data = [];
    let start = moment(startTime.time).add(30, "minutes");

    while (moment(start) < moment(endTime.time).endOf(DATE_TIME_FORMAT.DAY)) {
      data.push({
        id: moment(start).format(DATE_TIME_FORMAT.X).toString(),
        time: moment(start),
        label: moment(start).format(DATE_TIME_FORMAT.hh_mm_A),
      });
      start = moment(start).add(10, "minutes");
    }

    return data;
  };

  return (
    <Modal
      isOpen={isOpen}
      onCancel={toggleOpen}
      closeButton
      title={WORDINGS.UPDATE_SURGERY_TIME}
    >
      <div className={styles["time-validation"]}>
        <div className={styles["time-validation__input-container"]}>
          <div className={styles["time-validation__start-time"]}>
            <AdvancedDropdown
              value={startTime}
              onChange={(value) => {
                setStartTime(value);
                setEndTime({
                  id: moment(value?.time)
                    .add(procedureTime, "milliseconds")
                    .format(DATE_TIME_FORMAT.X)
                    .toString(),
                  time: moment(value?.time).add(procedureTime, "milliseconds"),
                  label: moment(value?.time)
                    .add(procedureTime, "milliseconds")
                    .format(DATE_TIME_FORMAT.hh_mm_A),
                });
                if (!!data) setData(null);
              }}
              label="Start Time"
              options={startTimes()}
            />
            <div
              className={
                isStartTimeValid
                  ? styles["time-validation__error-selection"]
                  : styles["time-validation__start-time-text"]
              }
            >
              {`${GLOBAL_MESSAGES.SELECT_TIME_ERROR}
              ${moment(selectedOtStartTime).format(
                DATE_TIME_FORMAT.hh_mm_A
              )} - ${moment(selectedOtEndTime - 60000).format(
                DATE_TIME_FORMAT.hh_mm_A
              )}`}
            </div>
          </div>
          <AdvancedDropdown
            value={endTime}
            onChange={(value) => {
              setEndTime(value);
              if (!!data) setData(null);
            }}
            label="End Time"
            options={endTimes()}
          />
          <TextInput
            label={`${GLOBAL_CONSTANTS.CLEANING_TIME} (mins)`}
            value={editBufferTime / 60000}
            onChange={(value) => setEditBufferTime(value * 60000)}
            required
          />
          <div className={styles["time-validation__notes-main"]}>
            <div className={styles["time-validation__notes"]}>
              <span>{`${GLOBAL_CONSTANTS.STANDARD_PROCEDURE_TIME}- ${
                procedureTime / 60000
              } ${GLOBAL_CONSTANTS.MINS}`}</span>
            </div>
            <div className={styles["time-validation__notes"]}>
              <span>{`${GLOBAL_CONSTANTS.STANDARD_CLEANING_TIME}- ${
                bufferTime / 60000
              } ${GLOBAL_CONSTANTS.MINS}`}</span>
            </div>
          </div>

          {!(!isValidate && data) && (
            <div className={styles["time-validation__validation-action"]}>
              <NewButton
                largeButton
                onClick={() => {
                  toggleValidate(true);
                  dispatch(
                    validateSurgeryTimeForRequest(
                      units[0]?.id,
                      {
                        surgery_start_time: +startTime?.time,
                        surgery_end_time: +endTime?.time,
                        buffer_time: editBufferTime,
                        ot_id: otId,
                        chief_surgeon_id: chiefSurgeonId,
                        anesthetist_id: "",
                      },
                      (newData) => {
                        toggleValidate(false);
                        if (newData) setData(newData);
                      }
                    )
                  );
                }}
                isLoading={isValidate}
                disabled={isStartTimeValid}
              >
                {BUTTON_TYPES.CHECK_AVAILABILITY}
              </NewButton>
            </div>
          )}
        </div>

        {!!data &&
          (data?.is_available ? (
            <div className={styles["time-validation__success"]}>
              {GLOBAL_MESSAGES.SLOT_AVAILABLE}
            </div>
          ) : (
            <div className={styles["time-validation__error"]}>
              {GLOBAL_MESSAGES.SLOT_NOT_AVAILABLE}
            </div>
          ))}

        {data?.is_available && !isValidate && (
          <div className={styles["time-validation__button-container"]}>
            <NewButton
              onClick={() => {
                setNewStartTime(startTime.time);
                setNewEndTIme(
                  moment(endTime.time).add(editBufferTime, "milliseconds")
                );
                setNewBufferTime(editBufferTime);
                toggleOpen();
                dispatch(handleAlertSuccess(GLOBAL_MESSAGES.TIME_SLOT_UPDATED));
              }}
            >
              {BUTTON_TYPES.UPDATE}
            </NewButton>
            <NewButton cancelButton onClick={toggleOpen}>
              {BUTTON_TYPES.CANCEL}
            </NewButton>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default TimeValidation;
