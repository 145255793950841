import { getItemFromLocalStorage } from "../../helpers/utils";
import { LOGIN_ACTIONS } from "./constants";

const initialState = {
  user: getItemFromLocalStorage("user") || {},
  isLoggedIn: getItemFromLocalStorage("isLoggedIn") || false,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_ACTIONS.LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: action.payload,
      };
    default:
      return state;
  }
};
