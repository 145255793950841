import React from "react";
import { useDispatch } from "react-redux";
import { logout } from "../login/userActions";
import NewButton from "../../components/NewButton";
import { BUTTON_TYPES, WORDINGS } from "../../helpers/constants";
import Modal from "../../components/Modal";
import styles from "./index.module.css";

export const LogoutModal = ({ isLogout, toggleLogout }) => {
  const dispatch = useDispatch();

  return (
    <Modal isOpen={isLogout} onCancel={toggleLogout} bottomModal>
      <div className={styles["logout-modal"]}>
        <span>{WORDINGS.LOGOUT_NOW}</span>
        <div className={styles["logout-modal__button-container"]}>
          <NewButton
            onClick={() => {
              dispatch(logout());
            }}
            className={styles["logout-modal__button"]}
          >
            {BUTTON_TYPES.LOGOUT}
          </NewButton>
          <NewButton
            cancelButton
            onClick={() => toggleLogout(false)}
            className={styles["logout-modal__cancel-button"]}
          >
            {BUTTON_TYPES.CANCEL}
          </NewButton>
        </div>
      </div>
    </Modal>
  );
};
