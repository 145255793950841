import React, { Fragment } from "react";
import Routes from "./routes/index.js";
import Notification from "./components/Notification";
import { useSelector } from "react-redux";
import { Toaster } from "react-hot-toast";
import "./App.css";

const App = () => {
  const { message } = useSelector((state) => state.toasterReducer);

  return (
    <Fragment>
      <Routes />
      {message && <Toaster />}
      <Notification />
    </Fragment>
  );
};

export default App;
