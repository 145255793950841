import React from "react";
import styles from "./index.module.css";

function Card({ title, children }) {
  return (
    <div className={styles["Card"]}>
      <div className={styles["Card__title"]}>{title}</div>
      <div className={styles["Card__data"]}>{children}</div>
    </div>
  );
}

export default Card;
