import React, { useEffect } from "react";
import { Toaster, toast } from "react-hot-toast";
import { requestPermission, onMessageListener } from "../../firebase";

function Notification() {
  useEffect(() => {
    requestPermission();
    const unsubscribe = onMessageListener().then((payload) => {
      toast.success(`${payload?.data?.title}\n${payload?.data?.message}`, {
        duration: 6000,
        position: "top-center",
      });
    });

    return () => {
      unsubscribe.catch((err) => console.log("failed: ", err));
    };
  }, []);

  return <Toaster />;
}
export default Notification;
