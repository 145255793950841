import React, { useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { IoIosArrowBack, IoIosCut } from "react-icons/io";
import { FaClock, FaHospital, FaStethoscope } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { getItemFromLocalStorage } from "../../../helpers/utils";
import {
  BOOKING_MODE,
  BOOKING_TYPE,
  BUTTON_TYPES,
  DATE_TIME_FORMAT,
  GLOBAL_CONSTANTS,
  WORDINGS,
} from "../../../helpers/constants";
import NewButton from "../../../components/NewButton";
import Modal from "../../../components/Modal";
import NewLoader from "../../../components/NewLoader";
import TimeValidation from "../TimeValidation";
import { createBookingRequest } from "../../BookingOverview/actions/bookingActions";
import { ReactComponent as CalendarIcon } from "../../../assets/Icons/calendar.svg";
import { ReactComponent as ScissorCut } from "../../../assets/Icons/scissors-cut-01.svg";
import PharmacyIcon from "../../../assets/Icons/PharmacyIcon.png";
import userGroup from "../../../assets/Icons/user-group.png";
import editIcon from "../../../assets/Icons/editIcon.svg";
import styles from "./index.module.css";

const RequestOverView = (props) => {
  const {
    day,
    selectedOt,
    surgeryNames,
    speciality,
    chiefSurgeon,
    patientName,
    patientMR,
    bedNumber,
    notes,
    procedureTime,
    bufferTime,
    crewDetails,
    equipmentModels,
    specialPharmacyList,
    setActiveStep,
    bookingMode,
    isEmergency,
  } = props;

  const dispatch = useDispatch();
  const { units = [] } = getItemFromLocalStorage("user") || {};

  const [listEquipments, toggleListEquipments] = useState(false);
  const [listPharmacy, toggleListPharmacy] = useState(false);
  const [isTimeEdit, toggleTimeEdit] = useState(false);
  const [newEndTime, setNewEndTIme] = useState(selectedOt?.time?.end);
  const [newStartTime, setNewStartTime] = useState(selectedOt?.time?.start);
  const [newBufferTime, setNewBufferTime] = useState(bufferTime);
  const [isRequest, toggleRequest] = useState(false);

  const handleRequest = () => {
    const newCrewDetails = {};
    const equipmentRequest = {};

    Object.keys(crewDetails)?.forEach((key) => {
      newCrewDetails[surgeryNames[0]?.crew_type?.id] = {
        ...newCrewDetails[surgeryNames[0]?.crew_type?.id],
        [key]: +crewDetails[key],
      };
    });

    Object.keys(equipmentModels)?.forEach((item) => {
      equipmentRequest[item] = {
        quantity: +equipmentModels[item]?.quantity,
      };
    });

    const newPharmacyStandards = [];
    const newCssdKits = [];

    for (const surgery of surgeryNames) {
      if (surgery?.pharmacy_standard?.pharmacy_standard_items?.length > 0)
        newPharmacyStandards.push(
          ...surgery?.pharmacy_standard?.pharmacy_standard_items
        );
      if (surgery?.cssd_kit_list?.length > 0)
        newCssdKits.push(...surgery?.cssd_kit_list);
    }

    const body = {
      surgery_number: "",
      surgery_start_time: +moment(newStartTime),
      surgery_end_time: +moment(newEndTime) - newBufferTime,
      surgery_type_ids: surgeryNames?.map((surgery) => surgery?.id),
      cssd_kit_list: newCssdKits?.map((kit) => ({
        kit_name: kit?.kit_name,
        quantity: kit?.quantity,
      })),
      pharmacy_standard_list: newPharmacyStandards?.map((item) => ({
        name: item?.name,
        quantity: item?.quantity,
      })),
      buffer_time_required: newBufferTime,
      patient_mrn: patientMR?.trim(),
      patient_name: patientName?.trim(),
      notes: notes?.trim(),
      pharmacy_notes: "",
      bed_number: bedNumber?.trim(),
      requested_department: speciality?.id,
      requested_ot: selectedOt?.ot?.id,
      pharmacy_special_request: specialPharmacyList?.map((item) => ({
        ...item,
        quantity: +item?.quantity,
      })),
      equipment_request: equipmentRequest,
      crew_data_details: {
        ["chief_surgeon"]: [chiefSurgeon?.id],
      },
      crew_details: newCrewDetails,
      major_surgery_type_id: surgeryNames[0]?.id,
      booking_mode: bookingMode
        ? BOOKING_MODE.UN_PLANNED
        : BOOKING_MODE.PLANNED,
      booking_type: isEmergency ? BOOKING_TYPE.EMERGENCY : BOOKING_TYPE.NORMAL,
    };

    toggleRequest(true);

    dispatch(
      createBookingRequest(units[0]?.id, body, () => toggleRequest(false))
    );
  };

  const timeValidationProps = {
    newEndTime,
    setNewEndTIme,
    newStartTime,
    setNewStartTime,
    isOpen: isTimeEdit,
    toggleOpen: () => toggleTimeEdit(false),
    bufferTime,
    otId: selectedOt?.ot?.id,
    chiefSurgeonId: chiefSurgeon?.id,
    procedureTime,
    selectedOtStartTime: selectedOt?.ot?.ot_slot_timings?.start_time,
    selectedOtEndTime: selectedOt?.ot?.ot_slot_timings?.end_time,
    isEmergency,
    newBufferTime,
    setNewBufferTime,
  };

  return (
    <div className={styles["request-overview"]}>
      {isTimeEdit && <TimeValidation {...timeValidationProps} />}
      {listPharmacy && (
        <Modal
          isOpen={listPharmacy}
          onCancel={() => {
            toggleListPharmacy(false);
          }}
          title={WORDINGS.PHARMACY_SUPPLIES}
          closeButton
        >
          <div className={styles["request-overview__pharmacy-modal"]}>
            <div className={styles["request-overview__pharmacy-list"]}>
              <div>{GLOBAL_CONSTANTS.VENDOR}</div>
              <div>{GLOBAL_CONSTANTS.ITEM}</div>
              <div>{GLOBAL_CONSTANTS.QUANTITY}</div>
            </div>
            {specialPharmacyList.length > 0 ? (
              Object.values(specialPharmacyList)?.map((item, index) => (
                <div className={styles["request-overview__pharmacy-list"]}>
                  <div>{item?.vendor}</div>
                  <div>{item?.item}</div>
                  <div>{item?.quantity}</div>
                </div>
              ))
            ) : (
              <div className={styles["request-overview__no-item"]}>
                {WORDINGS.NO_PHARMACY_SUPPLIES_FOUND}
              </div>
            )}
          </div>
        </Modal>
      )}
      {listEquipments && (
        <Modal
          isOpen={listEquipments}
          onCancel={() => toggleListEquipments(false)}
          title={GLOBAL_CONSTANTS.EQUIPMENTS}
          closeButton
        >
          <div className={styles["request-overview__equipments-modal"]}>
            <div className={styles["request-overview__equipments-list"]}>
              <div>{GLOBAL_CONSTANTS.EQUIPMENTS}</div>
              <div>{GLOBAL_CONSTANTS.QUANTITY}</div>
            </div>
            {Object.keys(equipmentModels).length > 0 ? (
              Object.values(equipmentModels)?.map((item, index) => (
                <div className={styles["request-overview__equipments-list"]}>
                  <div>{item?.name}</div>
                  <div>{item?.quantity}</div>
                </div>
              ))
            ) : (
              <div className={styles["request-overview__no-item"]}>
                {WORDINGS.NO_EQUIPMENTS_FOUND}
              </div>
            )}
          </div>
        </Modal>
      )}
      <div className={styles["request-overview__title"]}>
        <IoIosArrowBack size={26} onClick={() => setActiveStep(4)} />
        <h2>{"Request Overview"}</h2>
      </div>
      <div className={styles["request-overview__details"]}>
        <span>{moment(day).format(DATE_TIME_FORMAT.Do_MMMM_YYYY)}</span>
        <label className={styles["request-overview__Ot-name"]}>
          {selectedOt?.ot?.name}
        </label>
        <section className={styles["request-overview__data-list"]}>
          <label>{WORDINGS.SURGERY_DETAILS}</label>
          <div className={styles["request-overview__data-list__items"]}>
            {(bookingMode && isEmergency && (
              <div className={styles["request-overview__bookingmode"]}>
                <CalendarIcon size={20} color="#0E376E" />
                {WORDINGS.UN_PLANNED}, {WORDINGS.EMERGENCY}
              </div>
            )) ||
              ((bookingMode || isEmergency) && (
                <div className={styles["request-overview__bookingmode"]}>
                  <CalendarIcon size={20} color="#0E376E" />
                  {bookingMode && WORDINGS.UN_PLANNED}
                  {!bookingMode && isEmergency && WORDINGS.EMERGENCY}
                </div>
              ))}
            <div>
              <ScissorCut />
              {`${surgeryNames?.map((surgery) => surgery?.name).join(", ")}`}
            </div>
            <div>
              <FaHospital size={20} color="#0E376E" />
              {speciality?.name}
            </div>
            <div>
              <FaStethoscope size={20} color="#0E376E" />
              {chiefSurgeon?.name}
            </div>
          </div>
        </section>
        <section className={styles["request-overview__data-list"]}>
          <label>{WORDINGS.PATIENT_INFO}</label>
          <div className={styles["request-overview__data-list__items"]}>
            {patientName && (
              <div>
                <img src={userGroup} alt="icon" width={20} height={20} />
                {patientName}
              </div>
            )}
            {patientMR && (
              <div>{`${GLOBAL_CONSTANTS.MR_NO}: ${patientMR}`}</div>
            )}
            {bedNumber && (
              <div>{`${GLOBAL_CONSTANTS.BED_NO}: ${bedNumber}`}</div>
            )}
            {notes && <div>{`${GLOBAL_CONSTANTS.NOTES}: ${notes}`}</div>}
          </div>
        </section>
        <section className={styles["request-overview__data-list"]}>
          <label>{WORDINGS.TIME_SLOT}</label>
          <div className={styles["request-overview__data-list__items"]}>
            <div>
              <FaClock size={20} color="#0E376E" />
              {`${moment(newStartTime).format(
                DATE_TIME_FORMAT.hh_mm_A
              )} - ${moment(newEndTime).format(DATE_TIME_FORMAT.hh_mm_A)}`}
              <img
                src={editIcon}
                alt="edit"
                onClick={() => toggleTimeEdit(true)}
              />
            </div>
            <div>{`${GLOBAL_CONSTANTS.PROCEDURE_TIME}: ${
              (+moment(newEndTime) - newBufferTime - +moment(newStartTime)) /
              60000
            } ${GLOBAL_CONSTANTS.MINS}`}</div>
            <div>{`${GLOBAL_CONSTANTS.CLEANING_TIME}: ${
              newBufferTime / 60000
            } ${GLOBAL_CONSTANTS.MINS}`}</div>
          </div>
        </section>
        <div className={styles["request-overview__data-list__buttons"]}>
          <NewButton popupButton onClick={() => toggleListEquipments(true)}>
            <label
              className={styles["request-overview__data-list__button__label"]}
            >
              <span>{GLOBAL_CONSTANTS.EQUIPMENTS}</span>
              <IoIosCut />
            </label>
          </NewButton>
          <NewButton
            popupButton
            className={styles["request-overview__data-list__button"]}
            onClick={() => toggleListPharmacy(true)}
          >
            <label
              className={styles["request-overview__data-list__button__label"]}
            >
              {WORDINGS.PHARMACY_SUPPLIES}
              <img src={PharmacyIcon} width={14} height={18} alt="icon" />
            </label>
          </NewButton>
        </div>
      </div>
      <NewButton largeButton onClick={handleRequest}>
        {BUTTON_TYPES.REQUEST}
      </NewButton>
      {isRequest && <NewLoader />}
    </div>
  );
};

export default RequestOverView;
