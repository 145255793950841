import { QUICK_BOOKING_ACTIONS } from "./constants";

const initialState = {
  quickBookingAvailability: {},
  patientList: [],
};

export const quickBookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case QUICK_BOOKING_ACTIONS.GET_QUICK_BOOKING_AVAILABILITY_SUCCESS:
      return {
        ...state,
        quickBookingAvailability: action.payload,
      };
    case QUICK_BOOKING_ACTIONS.GET_PATIENT_LIST_SUCCESS: {
      return { ...state, patientList: action.payload };
    }
    default:
      return state;
  }
};
