import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoIosArrowBack } from "react-icons/io";
import moment from "moment";
import { getQuickSlotAvailability } from "../Actions/quickBookingActions";
import NewButton from "../../../components/NewButton";
import NewLoader from "../../../components/NewLoader";
import Modal from "../../../components/Modal";
import UpdateSurgeryTime from "../UpdateSurgeryTime";
import { handleAlertError, joinClass } from "../../../helpers/utils";
import {
  BUTTON_TYPES,
  GLOBAL_MESSAGES,
  WORDINGS,
} from "../../../helpers/constants";
import { ReactComponent as EditICon2 } from "../../../assets/Icons/editIcon2.svg";
import styles from "./index.module.css";

const OtSelection = (props) => {
  const {
    units,
    surgeryStartTime,
    surgeryEndTime,
    selectedOt,
    surgeryName,
    setActiveStep,
    setSurgeryStartTime,
    setSurgeryEndTime,
    setSelectedOt,
    setStep,
    handleSetSteps,
    surgeryDate,
    bufferTime,
    procedureTime,
  } = props;
  const dispatch = useDispatch();
  const {
    quickBookingAvailability: { available_ot: otAvailable },
  } = useSelector((state) => state.quickBookingReducer);

  const [loader, toggleLoader] = useState(false);
  const [tempSelectedOt, setTempSelectedOt] = useState(selectedOt);
  const [updateTime, toggleUpdateTime] = useState(false);

  useEffect(() => {
    toggleLoader(true);
    const params = {
      surgeryStartTime: +moment(surgeryStartTime),
      surgeryEndTime: +moment(surgeryEndTime),
      surgeryTypeId: surgeryName?.id,
    };
    dispatch(getQuickSlotAvailability(units[0].id, params, toggleLoader));
  }, [surgeryStartTime, surgeryEndTime]);

  const updateTimeProps = {
    surgeryStartTime,
    surgeryEndTime,
    surgeryDate,
    setSurgeryStartTime,
    setSurgeryEndTime,
    bufferTime,
    procedureTime,
    setTempSelectedOt,
    toggleUpdateTime,
  };

  const handleSubmitProceed = () => {
    if (tempSelectedOt) {
      setSelectedOt(tempSelectedOt);
      handleSetSteps(1, 2);
      setActiveStep(2);
    } else {
      dispatch(handleAlertError(GLOBAL_MESSAGES.SELECT_OT));
    }
  };

  return (
    <div className={styles["ot-selection"]}>
      <section>
        <div className={styles["ot-selection__title"]}>
          <IoIosArrowBack size={26} onClick={() => setStep(1)} />
          <h2>{GLOBAL_MESSAGES.AVAILABLE_OT}</h2>
        </div>
        <div className={styles["ot-selection__timing"]}>
          <section>{`${WORDINGS.SURGERY_TIMING} (${WORDINGS.INCLUDES_CLEANING_TIME})`}</section>
          <section>
            <div className={styles["ot-selection__surgery-time"]}>
              {`${moment(surgeryStartTime).format("hh:mm A")} - ${moment(
                surgeryEndTime
              ).format("hh:mm A")}`}
            </div>
            <NewButton className={styles["ot-selection__edit-button"]}>
              <label
                className={styles["ot-selection__edit-button__label"]}
                onClick={() => toggleUpdateTime(true)}
              >
                {BUTTON_TYPES.EDIT_TIME} <EditICon2 size={17} />
              </label>
            </NewButton>
          </section>
        </div>
      </section>
      <section className={styles["ot-selection__slot-list"]}>
        {loader ? (
          <NewLoader />
        ) : otAvailable?.length > 0 ? (
          <div className={styles["ot-selection__slot-data"]}>
            <div className={styles["ot-selection__slot-group"]}>
              <label>{WORDINGS.PREFERRED}</label>
              <div className={styles["ot-selection__slots"]}>
                {otAvailable
                  ?.filter((ot) => ot.feasibility === "preferred")
                  ?.map((ot, index) => (
                    <div key={index}>
                      <div
                        className={joinClass(
                          styles["ot-selection__timing__ot"],
                          tempSelectedOt?.id === ot?.id &&
                            styles["ot-selection__timing__ot-selected"]
                        )}
                        onClick={() => setTempSelectedOt(ot)}
                      >
                        {ot.name}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className={styles["ot-selection__slot-group"]}>
              <label>{WORDINGS.NON_PREFERRED}</label>
              <div className={styles["ot-selection__slots"]}>
                {otAvailable
                  ?.filter((ot) => ot.feasibility === "not_preferred")
                  ?.map((ot, index) => (
                    <div key={index}>
                      <div
                        className={joinClass(
                          styles["ot-selection__timing__ot"],
                          tempSelectedOt?.id === ot?.id &&
                            styles["ot-selection__timing__ot-selected"]
                        )}
                        onClick={() => setTempSelectedOt(ot)}
                      >
                        {ot.name}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ) : (
          <div>{"no data"}</div>
        )}
      </section>
      <div className={styles["ot-selection__proceed-button"]}>
        <NewButton
          largeButton
          className={styles["ot-selection__proceed-button__bottom"]}
          onClick={handleSubmitProceed}
        >
          {BUTTON_TYPES.PROCEED}
        </NewButton>
      </div>
      {updateTime && (
        <Modal
          isOpen={updateTime}
          onCancel={() => {
            toggleUpdateTime(false);
          }}
          closeButton
          bottomModal
          className={styles["ot-selection__modal-size"]}
          title={WORDINGS.UPDATE_TIMINGS}
        >
          <UpdateSurgeryTime {...updateTimeProps} />
        </Modal>
      )}
    </div>
  );
};

export default OtSelection;
