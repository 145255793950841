import React from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { MdError } from "react-icons/md";
import { joinClass } from "../../helpers/utils";
import styles from "./index.module.css";

const TextInputV2 = ({
  value,
  onChange,
  type,
  name,
  label,
  dark,
  autoFocus,
  required,
  className,
  inputRef,
  disabled,
  showPassword,
  toggleShowPassword,
  hideRequired,
  errorMessage,

  ...props
}) => {
  return (
    <div
      className={joinClass(
        styles["text-input-v2"],
        errorMessage
          ? styles["text-input-v2__error"]
          : styles["text-input-v2__success"]
      )}
    >
      {label && (
        <label className={styles["text-input-v2__label"]}>
          {label}
          {!hideRequired && required && <span>{" *"}</span>}
        </label>
      )}
      <input
        type={type}
        value={value}
        autoFocus={autoFocus}
        required={required}
        onChange={(event) => onChange(event.target.value)}
        ref={inputRef}
        disabled={disabled}
        {...props}
        className={styles["text-input-v2__error"]}
      />
      {name === "password" && (
        <div className={styles["text-input-v2__password"]}>
          {!showPassword ? (
            <AiFillEye
              fontSize={18}
              className={styles["text-input-v2__hidepassword-icon"]}
              onClick={toggleShowPassword}
            />
          ) : (
            <AiFillEyeInvisible
              fontSize={18}
              className={styles["text-input-v2__showpassword-icon"]}
              onClick={toggleShowPassword}
            />
          )}
        </div>
      )}
      {errorMessage && (
        <div className={styles["text-input-v2__error-message"]}>
          <MdError fontSize={16} />
          <span>{errorMessage}</span>
        </div>
      )}
    </div>
  );
};

TextInputV2.defaultProps = {
  type: "text",
};

export default TextInputV2;
