import React, { useEffect, useState, createContext } from "react";
import { getItemFromLocalStorage } from "./helpers/utils";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import App from "./App";

export const appContext = createContext();

const Setup = () => {
  const userInfo = getItemFromLocalStorage("user") || {};
  const theme = !!getItemFromLocalStorage("theme");

  const [userData, setUserData] = useState(userInfo);
  const [selectedTheme, setTheme] = useState(theme);

  const toggleTheme = (value) => {
    localStorage.setItem("theme", value);
    setTheme(value);
  };

  useEffect(() => {
    if (selectedTheme) {
      document.documentElement.style.setProperty("--theme-color1", "#6e5fde");
      document.documentElement.style.setProperty("--theme-color2", "#4c48b3");
    } else {
      document.documentElement.style.setProperty("--theme-color1", "#9f488c");
      document.documentElement.style.setProperty("--theme-color2", "#e44d38");
    }
  }, [selectedTheme]);

  return (
    <Provider store={store}>
      <appContext.Provider
        value={{
          userData,
          setUserData,
          theme: selectedTheme,
          setTheme: toggleTheme,
        }}
      >
        <App />
      </appContext.Provider>
    </Provider>
  );
};

export default Setup;
