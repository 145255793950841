import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoIosCut } from "react-icons/io";
import { CgTrash } from "react-icons/cg";
import moment from "moment";
import {
  BUTTON_TYPES,
  DATE_TIME_FORMAT,
  GLOBAL_CONSTANTS,
  GLOBAL_MESSAGES,
  WORDINGS,
} from "../../../helpers/constants";
import {
  getEquipmentModels,
  getSurgeons,
  getSurgeryTypes,
  resetBookingAvailabilityOverview,
} from "../../BookingOverview/actions/bookingActions";
import AdvancedDropdown from "../../../components/AdvancedDropdown";
import NewButton from "../../../components/NewButton";
import iconCalendar from "../../../assets/Icons/iconCalendar.png";
import Modal from "../../../components/Modal";
import NewCalender from "../../../components/NewCalendar";
import {
  checkTimeDifference,
  getMomentTimeDate,
  handleAlertError,
} from "../../../helpers/utils";
import NewModal from "../../../components/NewModal";
import EquipmentModelUpdate from "../../NewBookingRequest/SurgeryDetails/EquipmentModelUpdate";
import NewTimeDropdown from "../../../components/NewTimeDropdown";
import editIcon from "../../../assets/Icons/editIcon.svg";
import styles from "./index.module.css";

const SurgeryDetails = (props) => {
  const {
    surgeryDate,
    department,
    units,
    closureTime,
    equipmentModels,
    surgeryStartTime,
    procedureTime,
    bufferTime,
    setSurgeryEndTime,
    setSurgeryStartTime,
    setEquipmentModels,
    setClosureTime,
    surgeryNames,
    setSurgeryNames,
    setSurgeryDate,
    setStep,
  } = props;
  const dispatch = useDispatch();
  const { surgeryTypes, equipmentModels: eqModels } = useSelector(
    (state) => state.bookingReducer
  );

  const [surgeryName, setSurgeryName] = useState(null),
    [tempCheckDate, setTempCheckDate] = useState(false),
    [tempCheckToday, setTempCheckToday] = useState(false),
    [tempBookingMode, setTempBookingMode] = useState(false),
    [selectDate, setSelectDate] = useState(surgeryDate),
    [tempEquipments, setTempEquipments] = useState(equipmentModels),
    [equipmentModel, setEquipmentModel] = useState(null),
    [equipmentQty, setEquipmentQty] = useState("1"),
    [deleteSurgery, setDeleteSurgery] = useState(),
    [setDate, toggleSetDate] = useState(false);

  const [openEquipment, toggleEquipment] = useState(false);
  const [isEquipmentModelDelete, toggleEquipmentModelDelete] = useState(false);
  const [isEquipmentModelUpdate, toggleEquipmentModelUpdate] = useState(false);
  const [selectedEquipmentModel, setSelectedEquipmentModel] = useState(null);

  const handleDeleteSurgery = (surgery) => {
    setDeleteSurgery(surgery);
  };

  const handleDeleteModel = () => {
    const data = {};

    Object.values(tempEquipments).forEach((item) => {
      if (selectedEquipmentModel.id !== item?.id) {
        data[item.id] = { ...item };
      }
    });
    setTempEquipments({ ...data });
    toggleEquipmentModelDelete(false);
  };

  const handleEquipment = () => {
    if (surgeryNames?.length > 0) {
      toggleEquipment(true);
    } else {
      dispatch(handleAlertError(GLOBAL_MESSAGES.SELECT_SURGERY_FIRST));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(resetBookingAvailabilityOverview());
    };
  }, []);

  useEffect(() => {
    dispatch(
      getSurgeryTypes(units[0]?.id, {
        ignorePaging: true,
        departmentId: department?.id,
      })
    );
    dispatch(getSurgeons(units[0]?.id), department?.id);
    dispatch(getEquipmentModels(units[0]?.id, { ignorePaging: true }));
  }, []);

  useEffect(() => {
    if (surgeryName) {
      setSurgeryNames([...surgeryNames, surgeryName]);
      setSurgeryName(null);
    }
  }, [surgeryName]);

  useEffect(() => {
    if (surgeryNames?.length > 0) {
      let equipmentModels = {};

      for (const surgery of surgeryNames) {
        equipmentModels = {
          ...equipmentModels,
          ...surgery?.associated_equipment_models,
        };
      }

      setEquipmentModels(equipmentModels);
    }
  }, [surgeryNames]);

  useEffect(() => {
    setTempCheckDate(selectDate.isAfter(moment().add(1, DATE_TIME_FORMAT.DAY)));
    setTempCheckToday(selectDate.isSame(moment(), DATE_TIME_FORMAT.DAY));
    setClosureTime(checkTimeDifference(units[0]?.ot_booking_closure_time));
    setTempBookingMode((!tempCheckDate && !closureTime) || tempCheckToday);
  }, [selectDate, tempCheckDate, closureTime, tempCheckToday]);

  useEffect(() => {
    if (equipmentModel) {
      if (tempEquipments[equipmentModel?.id]) {
        setTempEquipments({
          ...tempEquipments,
          [equipmentModel?.id]: {
            ...equipmentModel,
            quantity:
              +equipmentQty + tempEquipments[equipmentModel?.id]?.quantity,
          },
        });
      } else {
        setTempEquipments({
          ...tempEquipments,
          [equipmentModel?.id]: {
            ...equipmentModel,
            quantity: +equipmentQty,
          },
        });
      }

      setEquipmentModel(null);
    }
  }, [equipmentModel]);

  useEffect(() => {
    if (surgeryStartTime) {
      setSurgeryStartTime(
        getMomentTimeDate(moment(surgeryStartTime), surgeryDate)
      );
    }
  }, [surgeryDate]);

  const handleSubmitProceed = () => {
    if (surgeryNames?.length < 1 || surgeryStartTime < +moment()) {
      dispatch(handleAlertError(GLOBAL_MESSAGES.FILL_REQUIRED_FIELDS));
    } else {
      setSurgeryEndTime(surgeryStartTime + procedureTime + bufferTime);
      setStep(2);
    }
  };

  return (
    <div className={styles["surgery-details"]}>
      {deleteSurgery && (
        <NewModal
          isOpen={deleteSurgery}
          onCancel={() => setDeleteSurgery(null)}
        >
          <div className={styles["surgery-details__delete-surgery-modal"]}>
            <label>{`${GLOBAL_MESSAGES.REMOVE} "${deleteSurgery?.name}" from list ?`}</label>
            <div
              className={
                styles["surgery-details__delete-surgery-modal__button"]
              }
            >
              <NewButton
                shadow
                onClick={() => {
                  setSurgeryNames(
                    surgeryNames?.filter(
                      (data) => data.id !== deleteSurgery?.id
                    )
                  );
                  setDeleteSurgery(false);
                }}
              >
                {BUTTON_TYPES.REMOVE}
              </NewButton>
              <NewButton
                cancelButton
                shadow
                onClick={() => setDeleteSurgery(false)}
              >
                {BUTTON_TYPES.CANCEL}
              </NewButton>
            </div>
          </div>
        </NewModal>
      )}
      {setDate && (
        <Modal
          isOpen={setDate}
          onCancel={() => {
            toggleSetDate(false);
            setSelectDate(moment(surgeryDate));
          }}
          fullModal
          closeButton
        >
          <div className={styles["surgery-details__modal__select-date"]}>
            <h2>{WORDINGS.SELECT_SURGERY_DATE}</h2>
            <label>
              {`${WORDINGS.SURGERY_DATE} : 
              ${moment(selectDate).format(DATE_TIME_FORMAT.Do_MMMM_YYYY)}`}
            </label>
            <NewCalender value={selectDate} setValue={setSelectDate} />
            <span
              className={
                styles["surgery-details__modal__select-date_booking-mode"]
              }
            >
              {tempBookingMode && WORDINGS.UNPLANNED_ALERT}
            </span>
            <div className={styles["surgery-details__modal-button"]}>
              <NewButton
                className={styles["surgery-details__modal-button"]}
                largeButton
                onClick={() => {
                  setSurgeryDate(selectDate);
                  toggleSetDate(false);
                }}
              >
                {WORDINGS.DONE}
              </NewButton>
            </div>
          </div>
        </Modal>
      )}
      <section>
        <h2 className={styles["surgery-details__title"]}>
          {GLOBAL_MESSAGES.ENTER_SURGERY_DETAILS}
        </h2>
        <NewTimeDropdown
          title={GLOBAL_CONSTANTS.START_TIME}
          selectedTime={surgeryStartTime}
          setSelectedTime={setSurgeryStartTime}
          selectedDate={surgeryDate}
        />
      </section>
      <section>
        <div className={styles["surgery-details__surgery-names"]}>
          <AdvancedDropdown
            label="Surgery Name"
            required={surgeryNames?.length === 0}
            value={surgeryName}
            onChange={(value) => setSurgeryName(value)}
            options={surgeryTypes?.filter(
              (surgery) =>
                !surgeryNames?.find(
                  (surgeryType) => surgeryType?.id === surgery?.id
                )
            )}
            placeholder={GLOBAL_MESSAGES.SELECT}
          />
        </div>
        {surgeryNames?.length === 0 && <div></div>}
        {surgeryNames?.length > 0 && (
          <div className={styles["surgery-details__surgeryNames"]}>
            {surgeryNames.map((surgery) => (
              <div
                key={surgery?.id}
                className={styles["surgery-details__surgeryName"]}
              >
                <div className={styles["surgery-details__surgery-detail"]}>
                  <div>{surgery?.name}</div>
                  <div>
                    <span>{`${GLOBAL_CONSTANTS.STANDARD_PROCEDURE_TIME}: ${
                      surgery?.standard_procedure_time / 60000
                    } ${GLOBAL_CONSTANTS.MINS}`}</span>
                    <span>{`${GLOBAL_CONSTANTS.CLEANING_TIME}: ${
                      surgery?.buffer_time_required / 60000
                    } ${GLOBAL_CONSTANTS.MINS}`}</span>
                  </div>
                </div>
                <div
                  onClick={() => handleDeleteSurgery(surgery)}
                  className={styles["surgery-details__delete-btn"]}
                >
                  <CgTrash color="#B51616" fontSize={24} />
                </div>
              </div>
            ))}
          </div>
        )}

        <div className={styles["surgery-details__button-group"]}>
          <NewButton popupButton shadow onClick={() => toggleSetDate(true)}>
            <label className={styles["surgery-details__button-label"]}>
              {moment(surgeryDate).format(DATE_TIME_FORMAT.DD_MMM_YYYY)}
              <img src={iconCalendar} alt="icon-calendar" />
            </label>
          </NewButton>
          <NewButton popupButton shadow onClick={handleEquipment}>
            <label className={styles["surgery-details__button-label"]}>
              {GLOBAL_CONSTANTS.EQUIPMENTS}
              <IoIosCut size={14} />
            </label>
          </NewButton>
        </div>
        {surgeryNames?.length === 0 && <div></div>}
        <NewButton largeButton onClick={handleSubmitProceed}>
          {BUTTON_TYPES.FIND_AVAILABLE_OT}
        </NewButton>
      </section>
      {openEquipment && (
        <Modal
          isOpen={openEquipment}
          fullModal
          closeButton
          onCancel={() => {
            toggleEquipment(false);
            setTempEquipments(equipmentModels);
          }}
          title={WORDINGS.SELECT_EQUIPMENTS}
        >
          <div className={styles["surgery-details__select-equipment"]}>
            <AdvancedDropdown
              value={equipmentModel}
              onChange={(value) => setEquipmentModel(value)}
              options={eqModels.filter((model) => !tempEquipments[model?.id])}
              placeholder={GLOBAL_MESSAGES.SELECT}
            />
            <div className={styles["surgery-details__select-equipment-list"]}>
              {Object.keys(tempEquipments).length > 0 ? (
                <div className={styles["surgery-details__equipments"]}>
                  {Object.values(tempEquipments)
                    ?.reverse()
                    ?.map((model) => (
                      <div
                        key={model?.id}
                        className={styles["surgery-details__equipment"]}
                      >
                        <div>{model?.name}</div>
                        <div
                          onClick={() => {
                            toggleEquipmentModelUpdate(true);
                            setSelectedEquipmentModel(model);
                          }}
                          className={
                            styles["surgery-details__equipment-edit-btn"]
                          }
                        >
                          <div>{model?.quantity}</div>
                          {/* <MdModeEdit color="#0E376E" size={18} /> */}
                          <img src={editIcon} alt="edit" />
                        </div>
                        <div
                          onClick={() => {
                            toggleEquipmentModelDelete(true);
                            setSelectedEquipmentModel(model);
                          }}
                          className={
                            styles["surgery-details__equipment-delete-btn"]
                          }
                        >
                          <CgTrash color="#B51616" fontSize={24} />
                        </div>
                      </div>
                    ))}
                </div>
              ) : (
                <p
                  className={styles["surgery-details__equipment-not-selected"]}
                >
                  {GLOBAL_MESSAGES.NO_SELECTED_EQUIPMENTS}
                </p>
              )}
              {isEquipmentModelUpdate && (
                <EquipmentModelUpdate
                  isOpen={isEquipmentModelUpdate}
                  toggleOpen={toggleEquipmentModelUpdate}
                  equipmentModel={selectedEquipmentModel}
                  setEquipmentModel={setSelectedEquipmentModel}
                  equipmentModels={tempEquipments}
                  setEquipmentModels={setTempEquipments}
                />
              )}
              {isEquipmentModelDelete && (
                <Modal
                  isOpen={isEquipmentModelDelete}
                  onCancel={() => toggleEquipmentModelDelete(false)}
                >
                  <div className={styles["equipment-model-delete"]}>
                    <div className={styles["equipment-model-delete__title"]}>
                      <span>{`${GLOBAL_MESSAGES.REMOVE} "${selectedEquipmentModel?.name}" ?`}</span>
                    </div>
                    <div
                      className={
                        styles["equipment-model-delete__button-container"]
                      }
                    >
                      <NewButton onClick={() => handleDeleteModel()}>
                        {BUTTON_TYPES.REMOVE}
                      </NewButton>
                      <NewButton
                        cancelButton
                        onClick={() => toggleEquipmentModelDelete(false)}
                      >
                        {BUTTON_TYPES.CANCEL}
                      </NewButton>
                    </div>
                  </div>
                </Modal>
              )}
            </div>
            <div className={styles["surgery-details__select-equipment-button"]}>
              <NewButton
                largeButton
                onClick={() => {
                  toggleEquipment(false);
                  setEquipmentModels(tempEquipments);
                }}
              >
                {WORDINGS.DONE}
              </NewButton>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default SurgeryDetails;
