import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { login } from "./userActions";
import { getItemFromLocalStorage } from "../../helpers/utils";
import TextInputV2 from "../../components/TextInputV2";
import Button from "../../components/Button";
import { isPasswordNull, validateEmail } from "./validate";
import { WORDINGS } from "../../helpers/constants";
import loginImage from "../../assets/loginImage2.png";
import brandLogo from "../../assets/bottomLogo.png";
import loginBanner from "../../assets/loginBanner.png";
import styles from "./index.module.css";
import NewToaster from "../../components/NewToaster";

const Login = () => {
  const dispatch = useDispatch();

  const isLoggedIn = getItemFromLocalStorage("isLoggedIn");
  const expiresAt = getItemFromLocalStorage("userexpiry");
  const fcmtoken = localStorage.getItem("@fcmtoken");

  const [loading, toggleLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, toggleShowPassword] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();

    toggleLoading(true);

    const emailValid = validateEmail(username, setEmailError);
    const passwordValid = isPasswordNull(password, setPasswordError);

    if (emailValid && passwordValid) {
      dispatch(login(username, password, fcmtoken, toggleLoading));
    } else {
      toggleLoading(false);
    }
  };

  useEffect(() => {
    setEmailError("");
  }, [username]);

  useEffect(() => {
    setPasswordError("");
  }, [password]);

  const handleClickShowPassword = () => toggleShowPassword(!showPassword);
  const { message } = useSelector((state) => state.newToasterReducer);

  return isLoggedIn && expiresAt && expiresAt > new Date().getTime() ? (
    <Redirect to="/home" />
  ) : (
    <div className={styles.login}>
      {message && <NewToaster />}
      <img
        src={loginBanner}
        alt="banner_image"
        className={styles["login__banner-image"]}
      />
      <img
        src={loginImage}
        alt="login_image"
        className={styles["login__image"]}
      />
      <div className={styles["login__form-container"]}>
        <div className={styles["login__header"]}>
          <h1>{"Login"}</h1>
        </div>
        <form className={styles.login__form} onSubmit={handleSubmit}>
          <TextInputV2
            label="Email"
            type="text"
            name="email"
            value={username}
            onChange={setUsername}
            autoFocus
            errorMessage={emailError}
          />
          <TextInputV2
            label="Password"
            type={showPassword ? "text" : "password"}
            name="password"
            value={password}
            onChange={setPassword}
            errorMessage={passwordError}
            showPassword={showPassword}
            toggleShowPassword={handleClickShowPassword}
          />
          <Button
            className={styles["login__login-button"]}
            isLoading={loading}
            type="submit"
          >
            {WORDINGS.LOGIN}
          </Button>
        </form>
      </div>
      <div className={styles["login__branding"]}>
        <img src={brandLogo} alt="company_logo" />
      </div>
    </div>
  );
};

export default Login;
