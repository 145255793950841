import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBookings } from "../BookingOverview/actions/bookingActions";
import moment from "moment";
import Header from "./Header";
import NewButton from "../../components/NewButton";
import DetailCard from "../../components/DetailCard";
import Modal from "../../components/Modal";
import NewCalender from "../../components/NewCalendar";
import Loader from "../../components/Loader";
import styles from "./index.module.css";
import iconCalendar from "../../assets/Icons/iconCalendar.png";
import { DATE_TIME_FORMAT, PAGE_DATA, WORDINGS } from "../../helpers/constants";
import {
  dateFilter,
  getItemFromLocalStorage,
  history,
  useClearLocalStorageExceptFilters,
} from "../../helpers/utils";

const MyRequests = () => {
  const dispatch = useDispatch();

  const { units = [], id = "" } = getItemFromLocalStorage("user") || {};

  useClearLocalStorageExceptFilters([PAGE_DATA.DATE]);

  const localDate = getItemFromLocalStorage(PAGE_DATA.DATE) || moment();

  const [loader, setLoader] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [day, setDay] = useState(moment(localDate));
  const [selectDate, setSelectDate] = useState(moment(day));
  const [isSelectDate, toggleSelectDate] = useState(false);

  const params = {
    otId: "",
    page: pageNumber,
    size: 10,
    sort: 1,
    ignorePaging: true,
    surgeryStartFrom: +moment(day).startOf("day"),
    surgeryStartTo: +moment(day).endOf("day"),
    from: "",
    to: "",
    chiefSurgeonId: "",
    surgeryNumber: "",
    status: "all",
    departmentId: null,
    surgeryTypeId: "",
    anaesthetistId: "",
    confirmationStatus: "all",
    bookingConfirmationStep: "",
    bookedUserId: id,
  };

  useEffect(() => {
    dateFilter(day);
    setLoader(true);
    dispatch(getBookings(units[0]?.id, params, setLoader));
  }, [pageNumber, day]);

  const { bookings, totalCount } = useSelector((state) => state.bookingReducer);

  const viewDetails = (id) => {
    history.push(`/requestDetails/${id}`);
  };

  return (
    <div className={styles["my-requests"]}>
      {isSelectDate && (
        <Modal
          isOpen={isSelectDate}
          onCancel={() => {
            toggleSelectDate(false);
          }}
          fullModal
          closeButton
          title={WORDINGS.SELECT_DATE}
        >
          <div className={styles["my-requests__modal__select-date"]}>
            <label>
              {`${WORDINGS.SURGERY_DATE} : 
              ${moment(selectDate).format(DATE_TIME_FORMAT.Do_MMMM_YYYY)}`}
            </label>
            <NewCalender
              value={selectDate}
              setValue={setSelectDate}
              disableOlder
            />
            <div className={styles["my-requestss__modal-button"]}>
              <NewButton
                className={styles["my-requestss__modal-button"]}
                largeButton
                onClick={() => {
                  setDay(selectDate);
                  toggleSelectDate(false);
                }}
              >
                {WORDINGS.DONE}
              </NewButton>
            </div>
          </div>
        </Modal>
      )}
      <Header />
      <NewButton popupButton shadow onClick={() => toggleSelectDate(true)}>
        <label className={styles["my-requests__select-date"]}>
          {moment(day).format(DATE_TIME_FORMAT.DD_MMM_YYYY)}
          <img src={iconCalendar} alt="icon-calendar" />
        </label>
      </NewButton>
      {/* <Pagination
        totalCount={totalCount}
        listCount={
          bookings?.length  
          // bookings?.filter((booking) => booking.booked_by?.id === id).length
        }
        page={pageNumber}
        setPage={setPageNumber}
        rowsPerPage={5}
      /> */}
      {loader ? (
        <Loader />
      ) : (
        <div className={styles["my-requests__details"]}>
          {bookings?.length > 0 ? (
            bookings
              // .filter((booking) => booking.booked_by?.id === id)
              .map((booking) => (
                <DetailCard
                  key={booking?.id}
                  ot={booking?.requested_ot?.name}
                  time={` ${
                    moment(booking?.surgery_start_time).format("hh:mm A") +
                    " - " +
                    moment(booking?.surgery_end_time).format("hh:mm A")
                  }`}
                  speciality={booking?.requested_department?.name}
                  surgery={booking?.surgery_types}
                  handleClick={() => viewDetails(booking?.id)}
                />
              ))
          ) : (
            <p>{WORDINGS.NO_REQUESTS_FOUND}</p>
          )}
        </div>
      )}
    </div>
  );
};

export default MyRequests;
