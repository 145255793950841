import React from "react";
import { history } from "../../helpers/utils";
import { WORDINGS } from "../../helpers/constants";
import arrowLeft from "../../assets/Icons/chevron-left.svg";
import styles from "./index.module.css";

function Header({ details }) {
  return (
    <div className={styles["Schedule-Header"]}>
      <header>
        {details && (
          <img
            src={arrowLeft}
            alt="back"
            className={styles["schedule-header__back"]}
            onClick={() => history.push("/newMySchedules")}
          />
        )}
        {WORDINGS.MY_SCHEDULES}
      </header>
    </div>
  );
}

export default Header;
