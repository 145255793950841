export const permissions = {
  SHIFTER_BREAK_REQUEST_VIEW: "shifterBreakRequestView",
  SHIFTER_BREAK_REQUEST_APPROVE: "shifterBreakRequestApprove",
  SHIFTER_BREAK_REQUEST_CANCEL: "shifterBreakRequestCancel",
  SHIFTER_TRIP_BOOK: "shifterTripBook",
  SHIFTER_TRIP_VIEW: "shifterTripView",
  GENERIC_TRIP_VIEW: "genericTripView",
  GENERIC_TRIP_BOOK: "genericTripBook",
  SHIFTER_TRIP_BOOKING_CONTROL_UPDATE: "shifterTripBookingControlUpdate",
  OT_SCHEDULE_REQUEST_CREATE: "otScheduleRequestCreate",
  OT_SCHEDULE_REQUEST_VIEW: "otScheduleRequestView",
  OT_CREW_MEMBER_DATA_VIEW: "otCrewMemberDataView",
};

export function permissionUtil(permissionList) {
  const alterPermissionList = {};

  permissionList.forEach((item) => {
    if (permissions[item.name]) {
      alterPermissionList[permissions[item.name]] = true;
    }
  });

  return alterPermissionList;
}
