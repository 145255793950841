import React from "react";
import { joinClass } from "../../helpers/utils";
import styles from "./index.module.css";

const NewModal = ({
  children,
  isOpen,
  onCancel,
  bottomModal,
  fullModal,
}) => {
  return (
    isOpen && (
      <>
        <div
          className={styles["back-drop"]}
          onClick={() => {
            onCancel(false);
          }}
        />
        <div
          className={joinClass(
            bottomModal ? styles["bottom-modal"] : styles["modal"],
            isOpen ? styles["modal-active"] : styles["modal-inactive"],
            fullModal && styles["modal-full"]
          )}
        >
          <div className={styles["content"]}>{children}</div>
        </div>
      </>
    )
  );
};

export default NewModal;
