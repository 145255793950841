import React, { useEffect, useState } from "react";
import { getItemFromLocalStorage, updateTimeOfDay } from "../../helpers/utils";

const Greeting = () => {
  const { name = "" } = getItemFromLocalStorage("user") || {};
  const [timeOfDayGreeting, setTimeOfDayGreeting] = useState("");

  useEffect(() => {
    const updateGreeting = () => {
      setTimeOfDayGreeting(updateTimeOfDay());
    };

    updateGreeting();
    const intervalId = setInterval(updateGreeting, 60000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <h2>
      {timeOfDayGreeting} <br />
      {`${name} !`}
    </h2>
  );
};

export default Greeting;
