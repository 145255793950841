import { createBrowserHistory } from "history";
import moment from "moment";
import {
  DATE_TIME_FORMAT,
  GLOBAL_MESSAGES,
  PAGE_DATA,
  PATIENT_NAME_REGEX,
} from "./constants";
import { TOASTER_ACTIONS } from "../components/NewToaster/contants";
import { useEffect } from "react";

export const joinClass = (...classNames) => classNames.join(" ");

export const history = createBrowserHistory();

export const redirect = (path) => history.push(path);

export const getItemFromLocalStorage = (id) =>
  JSON.parse(localStorage.getItem(id));

export const getFormatedDate = (timestamp) =>
  new Date(+timestamp).toLocaleDateString("en-US", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });

export const moveInArray = (arr, from, to) => {
  const newArr = arr.slice();
  const item = newArr.splice(from, 1);
  newArr.splice(to, 0, item[0]);

  return newArr;
};

export const timeDifference = (current, previous) => {
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;

  var elapsed = current - previous;

  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + " seconds ago";
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + " minutes ago";
  } else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + " hours ago";
  } else if (elapsed < msPerMonth) {
    return "approximately " + Math.round(elapsed / msPerDay) + " days ago";
  } else if (elapsed < msPerYear) {
    return "approximately " + Math.round(elapsed / msPerMonth) + " months ago";
  } else {
    return "approximately " + Math.round(elapsed / msPerYear) + " years ago";
  }
};

export const removeUnderScore = (word = "") => {
  const arrayString = word.split("_");
  return arrayString
    .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
    .join(" ");
};

export const sort = (element1, element2) => {
  const nameA = element1.name?.toLowerCase();
  const nameB = element2.name?.toLowerCase();
  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
};

export const filteredDepartmentList = (departmentList, type, cssdEnabled) => {
  let newList = departmentList;
  if (cssdEnabled) {
    newList = departmentList.filter((department) => department.cssd_enabled);
  }

  if (type === "owner") {
    newList = newList.filter((el) => el.asset_owner);
  } else if (type === "custodian") {
    newList = newList.filter((el) => el.asset_custodian);
  } else if (type === "all") {
    newList = (cssdEnabled && newList) || departmentList;
  }
  const sorted = newList.sort((element1, element2) => sort(element1, element2));
  return sorted;
};

export const handleSpeak = (value, synthRef, endFn) => {
  if (synthRef.current) {
    synthRef.current.cancel();
  }
  synthRef.current = window.speechSynthesis;
  var voices = synthRef.current.getVoices();
  const utter = new SpeechSynthesisUtterance();
  utter.voice = voices[2];
  utter.volume = 1;

  if (endFn) {
    utter.onend = (event) => {
      endFn();
    };
  }
  utter.text = value;
  synthRef.current.speak(utter);
};

export const groupedGropDownOptions = (allBuildings, allFloors, allAreas) => {
  const array = [];
  allBuildings.sort(sort).forEach((building) => {
    const filteredFloors = allFloors
      .sort(sort)
      .filter((floor) => floor.building.id === building.id);

    filteredFloors.sort(sort).forEach((floor) =>
      array.push({
        label: building?.name + ", " + floor?.name,
        options: allAreas
          .sort(sort)
          .filter((area) => area.floor?.id === floor?.id),
      })
    );
  });

  return array?.filter((item) => item?.options?.length >= 1);
};

export const timeListGenerator = (
  initialHour,
  initialMinute,
  initialMeridiem,
  isFullList
) => {
  let hour = initialHour;
  let minute = initialMinute;
  let meridiem = initialMeridiem;
  let timeList = [];

  const formatTime = (time) => (time < 10 ? `0${time}` : time.toString());

  const makeRequiredFormat = (hour, minute, meridiem) =>
    `${formatTime(hour)}:${formatTime(minute)} ${meridiem}`;

  if (hour > 12) hour = hour - 12;

  for (let i = 0; i < 1439; i++) {
    if (minute === 59) {
      if (hour === 12 && meridiem === "PM") {
        hour = 1;
        minute = 0;
        meridiem = "PM";
      } else if (hour === 11 && meridiem === "AM") {
        hour = 12;
        minute = 0;
        meridiem = "PM";
      } else if (hour === 11 && meridiem === "PM") {
        hour = 0;
        minute = 0;
        meridiem = "AM";
      } else if (hour === 12 && meridiem === "AM") {
        hour = 1;
        minute = 0;
        meridiem = "AM";
      } else {
        hour = hour + 1;
        minute = 0;
      }
    } else minute = minute + 1;

    if (isFullList) {
      timeList.push({
        label: makeRequiredFormat(hour, minute, meridiem),
        value: makeRequiredFormat(hour, minute, meridiem),
        show: minute % 15 === 0 ? true : false,
      });
    } else {
      if (minute % 15 === 0)
        timeList.push({
          label: makeRequiredFormat(hour, minute, meridiem),
          value: makeRequiredFormat(hour, minute, meridiem),
        });
    }
  }

  return timeList;
};

export const repeatElementByCount = (count, Element) => {
  return [...Array(count)].map((e, i) => <Element key={i} />);
};

export function checkTimeDifference(bookingClosureTime) {
  const currentTime = moment().utc().startOf("second").valueOf();
  const timeDifference = bookingClosureTime - currentTime;
  return timeDifference >= 0;
}

export const updateTimeOfDay = () => {
  const currentHour = new Date().getHours();

  if (currentHour < 12) {
    return GLOBAL_MESSAGES.GOOD_MORNING;
  } else if (currentHour < 16) {
    return GLOBAL_MESSAGES.GOOD_AFTERNOON;
  } else {
    return GLOBAL_MESSAGES.GOOD_EVENING;
  }
};

export const handleAlertError = (message) => {
  return {
    type: TOASTER_ACTIONS.SET_NEW_TOASTER_DATA,
    notification: {
      message,
      title: "Error",
      type: "error",
    },
  };
};

export const handleAlertSuccess = (message) => {
  return {
    type: TOASTER_ACTIONS.SET_NEW_TOASTER_DATA,
    notification: {
      message,
      title: "Success",
      type: "success",
    },
  };
};

export const allFilters = [
  PAGE_DATA.DATE,
  PAGE_DATA.TAB,
  PAGE_DATA.REDIRECT_PAGE,
];

export const dateFilter = (data) => {
  localStorage.setItem(PAGE_DATA.DATE, JSON.stringify(data));
};

export const tabFilter = (data) => {
  localStorage.setItem(PAGE_DATA.TAB, JSON.stringify(data));
};

export const setRedirectPage = (data) => {
  localStorage.setItem(PAGE_DATA.REDIRECT_PAGE, JSON.stringify(data));
};

export const useClearLocalStorageExceptFilters = (filters) => {
  useEffect(() => {
    allFilters.forEach((filterKey) => {
      if (!filters.includes(filterKey)) {
        localStorage.removeItem(filterKey);
      }
    });
  }, []);
};

export const getMomentTimeDate = (surgeryStartTime, surgeryDate) => {
  const time = surgeryStartTime.format("HH:mm A");
  const date = surgeryDate.format("YYYY-MM-DD");
  const dateTime = moment(`${date} ${time}`, "YYYY-MM-DD HH:mm A");
  return +moment(dateTime);
};

export const createTimeObject = (time) => {
  return {
    id: moment(time).format(DATE_TIME_FORMAT.X).toString(),
    time: moment(time),
    label: moment(time).format(DATE_TIME_FORMAT.hh_mm_A),
  };
};

export function getPatientDetailByMrn(records, mrn) {
  return records?.filter((record) => record?.mrn.includes(mrn));
}

export const vallidateName = (name) => {
  if (!PATIENT_NAME_REGEX.test(name)) {
    return false;
  } else {
    return true;
  }
};
