import React, { useState } from "react";
import chevronDown from "../../assets/Icons/chevron-down.svg";
import styles from "./index.module.css";
import { joinClass } from "../../helpers/utils";

const NewAccordion = ({ title, disabled, children }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className={styles["accordion"]}>
      <div
        className={styles["accordion-title"]}
        onClick={() => {
          if (!disabled) setIsActive(!isActive);
        }}
      >
        <div>{title}</div>
        {!disabled && (
          <>
            {
              <img
                src={chevronDown}
                alt={isActive ? "close" : "open"}
                className={joinClass(
                  styles["chevron"],
                  isActive ? styles["rotated"] : ""
                )}
              />
            }
          </>
        )}
      </div>
      {isActive && !disabled && <div>{children}</div>}
    </div>
  );
};

export default NewAccordion;
