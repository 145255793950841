import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { IoIosArrowBack } from "react-icons/io";
import {
  getBookingAvailabilityOverview,
  getEmergencyBookingAvailabilityOverview,
} from "../../BookingOverview/actions/bookingActions";
import {
  getItemFromLocalStorage,
  handleAlertError,
  joinClass,
} from "../../../helpers/utils";
import {
  BUTTON_TYPES,
  DATE_TIME_FORMAT,
  GLOBAL_MESSAGES,
  WORDINGS,
} from "../../../helpers/constants";
import ToggleButtonSwitch from "../../../components/ToggleButtonSwitch";
import Modal from "../../../components/Modal";
import NewButton from "../../../components/NewButton";
import NewLoader from "../../../components/NewLoader";
import styles from "./index.module.css";

const SlotSelection = (props) => {
  const {
    speciality,
    surgeryNames,
    chiefSurgeon,
    day,
    isCheckingAvailability,
    toggleCheckingAvailability,
    setActiveStep,
    selectedOt,
    setSelectedOt,
    handleSetSteps,
    isEmergency,
    setIsEmergency,
  } = props;

  const dispatch = useDispatch();
  const { units = [] } = getItemFromLocalStorage("user") || {};
  const { bookingAvailabilityOverview } = useSelector(
    (state) => state.bookingReducer
  );

  const [isSelectTime, toggleSelectTime] = useState(false);
  const [tempSelectedOt, setTempSelectedOt] = useState(null);
  const [tempSlotTime, setTempSlotTime] = useState(null);

  const handleSubmit = () => {
    if (selectedOt) {
      setActiveStep(5);
      handleSetSteps(4, 5);
    } else {
      dispatch(handleAlertError(GLOBAL_MESSAGES.SELECT_OT_AND_TIME_SLOT));
    }
  };

  useEffect(() => {
    if (
      surgeryNames?.length > 0 &&
      speciality &&
      chiefSurgeon &&
      !isCheckingAvailability
    ) {
      toggleCheckingAvailability(true);
      if (isEmergency) {
        dispatch(
          getEmergencyBookingAvailabilityOverview(
            units[0]?.id,
            {
              ot_ids: [],
              anaesthetist: "",
              timestamp: +moment(day).format("x"),
              surgery_types: surgeryNames?.map((surgery) => surgery?.id),
              chief_surgeon_ids: [chiefSurgeon?.id],
              department: speciality?.id,
              equipment_category_map: {},
            },
            () => toggleCheckingAvailability(false)
          )
        );
      } else {
        dispatch(
          getBookingAvailabilityOverview(
            units[0]?.id,
            {
              ot_ids: [],
              anaesthetist: "",
              timestamp: +moment(day).format("x"),
              surgery_types: surgeryNames?.map((surgery) => surgery?.id),
              chief_surgeon_ids: [chiefSurgeon?.id],
              department: speciality?.id,
              equipment_category_map: {},
            },
            () => toggleCheckingAvailability(false)
          )
        );
      }
    }
  }, [isEmergency]);

  useEffect(() => {
    setTempSlotTime(tempSelectedOt);
  }, [tempSelectedOt]);

  const bookNormal = () => {
    if (isEmergency) {
      setSelectedOt(null);
      setIsEmergency(false);
    }
  };

  const bookEmergency = () => {
    if (!isEmergency) {
      setSelectedOt(null);
      setIsEmergency(true);
    }
  };

  return (
    <div className={styles["slot-selection"]}>
      {isCheckingAvailability && <NewLoader />}
      <div className={styles["slot-selection__title"]}>
        <IoIosArrowBack size={26} onClick={() => setActiveStep(3)} />
        <h2>{GLOBAL_MESSAGES.SELECT_OT_TIME_SLOT}</h2>
      </div>
      <div>
        <span>{moment(day).format(DATE_TIME_FORMAT.Do_MMMM_YYYY)}</span>
      </div>
      <div>
        <ToggleButtonSwitch
          isEmergency={isEmergency}
          buttonClick1={bookNormal}
          buttonClick2={bookEmergency}
          button1={GLOBAL_MESSAGES.LISTED}
          button2={GLOBAL_MESSAGES.NON_LISTED}
        />
      </div>
      <div className={styles["slot-selection__ot-availability"]}>
        <div className={styles["slot-selection__ote-availability-list"]}>
          <label>{WORDINGS.PREFERRED}</label>
          <div className={styles["slot-selection__ot-availability-group"]}>
            {bookingAvailabilityOverview
              ?.filter(
                (slot) => slot.logs && slot.logs.length > 0 && slot.is_preferred
              )
              ?.map((slot, index) => (
                <div key={index} className={styles["slot-selection__ot"]}>
                  <div
                    className={joinClass(
                      styles["slot-selection__available-ot"],
                      selectedOt?.ot?.id === slot?.ot?.id &&
                        selectedOt?.time &&
                        styles["slot-selection__selected-ot"]
                    )}
                    onClick={() => {
                      setTempSelectedOt({ ot: slot?.ot });
                      toggleSelectTime(true);
                    }}
                  >
                    {slot?.ot?.name}
                  </div>
                  {selectedOt?.ot?.id === slot?.ot?.id && selectedOt?.time && (
                    <span>{`${moment(selectedOt?.time?.start).format(
                      DATE_TIME_FORMAT.hh_mm_A
                    )} - ${moment(selectedOt?.time?.end).format(
                      DATE_TIME_FORMAT.hh_mm_A
                    )}`}</span>
                  )}
                </div>
              ))}
          </div>
        </div>
        <div className={styles["slot-selection__ote-availability-list"]}>
          <label>{WORDINGS.NON_PREFERRED}</label>
          <div className={styles["slot-selection__ot-availability-group"]}>
            {bookingAvailabilityOverview
              ?.filter(
                (slot) =>
                  slot.logs && slot.logs.length > 0 && !slot.is_preferred
              )
              ?.map((slot, index) => (
                <div key={index} className={styles["slot-selection__ot"]}>
                  <div
                    className={joinClass(
                      styles["slot-selection__available-ot"],
                      selectedOt?.ot?.id === slot?.ot?.id &&
                        selectedOt?.time &&
                        styles["slot-selection__selected-ot"]
                    )}
                    onClick={() => {
                      setTempSelectedOt({ ot: slot?.ot });
                      toggleSelectTime(true);
                    }}
                  >
                    {slot?.ot?.name}
                  </div>
                  {selectedOt?.ot?.id === slot?.ot?.id && selectedOt?.time && (
                    <span>{`${moment(selectedOt?.time?.start).format(
                      DATE_TIME_FORMAT.hh_mm_A
                    )} - ${moment(selectedOt?.time?.end).format(
                      DATE_TIME_FORMAT.hh_mm_A
                    )}`}</span>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
      {isSelectTime && (
        <Modal
          isOpen={isSelectTime}
          onCancel={() => {
            toggleSelectTime(false);
            setTempSlotTime(null);
          }}
          closeButton
          bottomModal
          title={tempSlotTime?.ot?.name}
        >
          <div className={styles["slot-selection__modal"]}>
            <span>{moment(day).format(DATE_TIME_FORMAT.Do_MMMM_YYYY)}</span>
            <div className={styles["slot-selection__modal__time-list"]}>
              {bookingAvailabilityOverview
                .find((item) => item?.ot?.id === tempSlotTime?.ot?.id)
                ?.logs?.map((time, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      setTempSlotTime((prevData) => ({
                        ...prevData,
                        time,
                      }));
                    }}
                    className={joinClass(
                      styles["slot-selection__modal__time-list__item"],
                      time === tempSlotTime?.time &&
                        styles["slot-selection__modal__time-list__selected"]
                    )}
                  >
                    <div>{`${moment(time?.start).format(
                      DATE_TIME_FORMAT.hh_mm_A
                    )} - ${moment(time?.end).format(
                      DATE_TIME_FORMAT.hh_mm_A
                    )}`}</div>
                  </div>
                ))}
            </div>
            <NewButton
              largeButton
              onClick={() => {
                if (tempSlotTime?.time) {
                  setSelectedOt(tempSlotTime);
                  toggleSelectTime(false);
                } else {
                  dispatch(handleAlertError(GLOBAL_MESSAGES.SELECT_TIME_SLOT));
                }
              }}
            >
              {BUTTON_TYPES.SELECT}
            </NewButton>
          </div>
        </Modal>
      )}
      <div className={styles["slot-select__summary-div"]}>
        <NewButton
          largeButton
          onClick={handleSubmit}
          className={styles["slot-select__summary-button"]}
        >
          {WORDINGS.VIEW_REQUEST_SUMMARY}
        </NewButton>
      </div>
    </div>
  );
};

export default SlotSelection;
