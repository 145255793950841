import React, { useEffect, useState } from "react";
import Header from "./Header";
import styles from "./index.module.css";
import NewButton from "../../components/NewButton";
import moment from "moment";
import iconCalendar from "../../assets/Icons/iconCalendar.png";
import { DATE_TIME_FORMAT, PAGE_DATA, WORDINGS } from "../../helpers/constants";
import DetailCard from "../../components/DetailCard";
import { useDispatch, useSelector } from "react-redux";
import {
  dateFilter,
  getItemFromLocalStorage,
  history,
  useClearLocalStorageExceptFilters,
} from "../../helpers/utils";
import Pagination from "../../components/Pagination";
import { getMySchedules } from "../MySchedules/actions/myScheduleActions";
import Modal from "../../components/Modal";
import NewCalender from "../../components/NewCalendar";
import Loader from "../../components/Loader";

const NewMySchedules = () => {
  const dispatch = useDispatch();

  useClearLocalStorageExceptFilters([PAGE_DATA.DATE]);
  const localDate = getItemFromLocalStorage(PAGE_DATA.DATE) || moment();
  const { units = [] } = getItemFromLocalStorage("user") || {};
  const [loader, setLoader] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [day, setDay] = useState(moment(localDate));
  const [selectDate, setSelectDate] = useState(moment(day));
  const [isSelectDate, toggleSelectDate] = useState(false);

  useEffect(() => {
    dateFilter(day);
    setLoader(true);
    dispatch(
      getMySchedules(
        units[0]?.id,
        "",
        "",
        +day,
        pageNumber,
        10,
        1,
        true,
        setLoader
      )
    );
  }, [day, pageNumber]);

  const { mySchedule = [], totalCount } = useSelector(
    (state) => state.myScheduleReducer
  );

  const viewDetails = (id) => {
    history.push(`/scheduleDetails/${id}`);
  };

  return (
    <div className={styles["my-schedules"]}>
      {isSelectDate && (
        <Modal
          isOpen={isSelectDate}
          onCancel={() => {
            toggleSelectDate(false);
          }}
          fullModal
          closeButton
          title={WORDINGS.SELECT_DATE}
        >
          <div className={styles["my-schedules__modal__select-date"]}>
            <label>
              {`${WORDINGS.SURGERY_DATE} : 
              ${moment(selectDate).format(DATE_TIME_FORMAT.Do_MMMM_YYYY)}`}
            </label>
            <NewCalender
              value={selectDate}
              setValue={setSelectDate}
              disableOlder
            />
            <div className={styles["my-schedules__modal-button"]}>
              <NewButton
                className={styles["my-schedules__modal-button"]}
                largeButton
                onClick={() => {
                  setDay(selectDate);
                  toggleSelectDate(false);
                }}
              >
                {WORDINGS.DONE}
              </NewButton>
            </div>
          </div>
        </Modal>
      )}
      <Header />
      <NewButton popupButton shadow onClick={() => toggleSelectDate(true)}>
        <label className={styles["my-schedules__select-date"]}>
          {moment(day).format(DATE_TIME_FORMAT.DD_MMM_YYYY)}
          <img src={iconCalendar} alt="icon-calendar" />
        </label>
      </NewButton>
      {/* <Pagination
        totalCount={totalCount}
        listCount={
          mySchedule?.length
        }
        page={pageNumber}
        setPage={setPageNumber}
        rowsPerPage={10}
      /> */}
      {loader ? (
        <Loader />
      ) : (
        <div className={styles["my-schedules__details"]}>
          {mySchedule?.length > 0 ? (
            mySchedule.map((data, index) => (
              <DetailCard
                key={index}
                ot={data.ot.name}
                time={` ${
                  moment(data?.surgery_start_time).format("hh:mm A") +
                  " - " +
                  moment(data?.surgery_end_time).format("hh:mm A")
                }`}
                speciality={data?.department?.name}
                surgery={data.types_of_surgeries}
                handleClick={() => viewDetails(data?.id)}
              />
            ))
          ) : (
            <p>{WORDINGS.NO_SCHEDULES_FOUND}</p>
          )}
        </div>
      )}
    </div>
  );
};

export default NewMySchedules;
