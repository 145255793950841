import { MY_SCHEDULE_ACTIONS } from "./constants";

const initialState = {
  mySchedule: [],
  totalCount: 0,
  scheduleDetails: {},
};

export const myScheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case MY_SCHEDULE_ACTIONS.GET_MY_SCHEDULE_SUCCESS:
      return {
        ...state,
        mySchedule: action.payload,
        totalCount: action.totalCount,
      };
    case MY_SCHEDULE_ACTIONS.CLEAR_MY_SCHEDULE_STORE:
      return {
        ...state,
        mySchedule: [],
        totalCount: 0,
        scheduleDetails: {},
      };
    case MY_SCHEDULE_ACTIONS.GET_SCHEDULE_DETAILS:
      return {
        ...state,
        scheduleDetails: action.payload,
      };
    default:
      return state;
  }
};
