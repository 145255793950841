import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoIosArrowBack } from "react-icons/io";
import { BUTTON_TYPES, GLOBAL_MESSAGES } from "../../../helpers/constants";
import {
  getItemFromLocalStorage,
  handleAlertError,
  vallidateName,
} from "../../../helpers/utils";
import TextInput from "../../../components/TextInput";
import { TextArea } from "../../../components/TextArea";
import AdvancedDropdown from "../../../components/AdvancedDropdown";
import Button from "../../../components/Button";
import NewModal from "../../../components/NewModal";
import NewButton from "../../../components/NewButton";
import styles from "./index.module.css";
import { getPatientList } from "../../QuickBookingRequest/Actions/quickBookingActions";

const PatientInfo = (props) => {
  const {
    patientName,
    patientMR,
    bedNumber,
    notes,
    setPatientName,
    setPatientMR,
    setBedNumber,
    setNotes,
    handleSetSteps,
    setActiveStep,
    skipPharmacy,
    setSkipPharmacy,
    patientRecord,
    setPatientRecord,
    chiefSurgeon,
  } = props;

  const dispatch = useDispatch();

  const { units = [], enable_patient_records: isPatientRecords = false } =
    getItemFromLocalStorage("user") || {};
  const { patientList = [] } = useSelector(
    (state) => state.quickBookingReducer
  );

  const [skipStep, toggleSkipStep] = useState(false);
  const [tempPatientName, setTempPatientName] = useState(patientName);
  const [tempPatientMR, setTempPatientMR] = useState(patientMR);
  const [tempBedNumber, setTempBedNumber] = useState(bedNumber);
  const [tempNotes, setTempNotes] = useState(notes);
  const [patient, setPatient] = useState(patientRecord);
  const [errorMsg, setErrorMsg] = useState(null);

  const handleSkipPharmacy = () => {
    setSkipPharmacy(true);
    setActiveStep(4);
    handleSetSteps(2, 4);
  };

  const handleEnablePharmacy = () => {
    setSkipPharmacy(false);
    toggleSkipStep(false);
    setActiveStep(3);
    handleSetSteps(2, 3);
  };

  const handleSubmit = () => {
    if (tempPatientName || tempPatientMR) {
      if (vallidateName(tempPatientName)) {
        setPatientName(tempPatientName);
        setPatientMR(tempPatientMR);
        setBedNumber(tempBedNumber);
        setNotes(tempNotes);
        setPatientRecord(patient);
        if (skipPharmacy === null) {
          toggleSkipStep(true);
        } else if (skipPharmacy) {
          handleSkipPharmacy();
        } else {
          handleEnablePharmacy();
        }
      } else {
        setErrorMsg(GLOBAL_MESSAGES.ENTER_VALID_NAME);
      }
    } else {
      dispatch(handleAlertError("Fill Required Fields"));
    }
  };

  useEffect(() => {
    const params = {
      status: "Active",
      departmentId: "",
      searchText: "",
      surgeonId: chiefSurgeon?.id || "",
      page: 1,
      size: 25,
      sort: 1,
      ignorePaging: true,
    };
    dispatch(getPatientList(units[0]?.id, params));
  }, []);

  useEffect(() => {
    if (patient) {
      setTempPatientName(patient?.name);
      setTempPatientMR(patient?.mrn ? patient?.mrn : "");
    }
  }, [patient]);

  return (
    <div className={styles["patient-details"]}>
      <div className={styles["patient-details__title"]}>
        <IoIosArrowBack size={26} onClick={() => setActiveStep(1)} />
        <h2>{GLOBAL_MESSAGES.ENTER_PATIENT_INFO}</h2>
      </div>
      <div className={styles["patient-details__input-group"]}>
        {isPatientRecords ? (
          <AdvancedDropdown
            label="Patient Name"
            value={patient}
            onChange={(value) => setPatient(value)}
            options={patientList}
            placeholder={GLOBAL_MESSAGES.SELECT}
            required={!tempPatientMR}
          />
        ) : (
          <div>
            <TextInput
              label="Patient Name"
              value={tempPatientName}
              onChange={(value) => {
                setTempPatientName(value);
                setErrorMsg(null);
              }}
              required={!tempPatientMR}
              autoFocus
            />
            <p className={styles["patient-info__error-msg"]}>{errorMsg}</p>
          </div>
        )}
        <TextInput
          label="MR Number"
          value={tempPatientMR}
          onChange={(value) => setTempPatientMR(value)}
          required={!tempPatientName}
        />
        <TextInput
          label="Bed Number"
          value={tempBedNumber}
          onChange={(value) => setTempBedNumber(value)}
        />
        <TextArea
          label="Notes"
          value={tempNotes}
          onChange={(value) => setTempNotes(value)}
          totalCount={50}
        />
      </div>
      <div>
        <Button
          className={styles["patient-details__proceed-button"]}
          onClick={handleSubmit}
        >
          {BUTTON_TYPES.PROCEED}
        </Button>
      </div>
      {!skipPharmacy && skipStep && (
        <NewModal isOpen={skipStep} onCancel={() => toggleSkipStep(false)}>
          <div className={styles["pharmacy-supplies__modal"]}>
            <label>{GLOBAL_MESSAGES.SKIP_PHARMACY_SUPPLIES}</label>
            <label>{GLOBAL_MESSAGES.SKIP_PHARMACY_MESSAGE}</label>
            <div>
              <NewButton onClick={handleSkipPharmacy}>
                {BUTTON_TYPES.SKIP}
              </NewButton>
              <NewButton cancelButton onClick={handleEnablePharmacy}>
                {BUTTON_TYPES.CANCEL}
              </NewButton>
            </div>
          </div>
        </NewModal>
      )}
    </div>
  );
};

export default PatientInfo;
