import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  checkTimeDifference,
  getItemFromLocalStorage,
  handleAlertError,
} from "../../../helpers/utils";
import {
  getDepartments,
  getEquipmentModels,
  getSurgeons,
  getSurgeryTypes,
} from "../../BookingOverview/actions/bookingActions";
import {
  BUTTON_TYPES,
  DATE_TIME_FORMAT,
  GLOBAL_CONSTANTS,
  GLOBAL_MESSAGES,
  WORDINGS,
} from "../../../helpers/constants";
import AdvancedDropdown from "../../../components/AdvancedDropdown";
import NewButton from "../../../components/NewButton";
import NewModal from "../../../components/NewModal";
import NewCalender from "../../../components/NewCalendar";
import Modal from "../../../components/Modal";
import TextInput from "../../../components/TextInput";
import EquipmentModelUpdate from "./EquipmentModelUpdate";
import { CgTrash } from "react-icons/cg";
import { IoIosCut } from "react-icons/io";
import { MdModeEdit } from "react-icons/md";
import iconCalendar from "../../../assets/Icons/iconCalendar.png";
import editIcon from "../../../assets/Icons/editIcon.svg";
import styles from "./index.module.css";

function SurgeryDetails(props) {
  const dispatch = useDispatch();

  const {
    units = [],
    department,
    crew_details,
    designation,
  } = getItemFromLocalStorage("user") || {};
  const {
    departments,
    surgeryTypes,
    surgeons,
    equipmentModels: eqModels,
  } = useSelector((state) => state.bookingReducer);

  const {
    speciality,
    surgeryNames,
    chiefSurgeon,
    setSpeciality,
    setSurgeryNames,
    setChiefSurgeon,
    equipmentModels,
    setEquipmentModels,
    day,
    setDay,
    closureTime,
    setClosureTime,
    handleSetSteps,
    setActiveStep,
  } = props;

  const [surgeryName, setSurgeryName] = useState(null);
  const [equipmentModel, setEquipmentModel] = useState(null);
  const [equipmentQty, setEquipmentQty] = useState("1");
  const [isEquipmentModelUpdate, toggleEquipmentModelUpdate] = useState(false);
  const [selectedEquipmentModel, setSelectedEquipmentModel] = useState(null);
  const [deleteSurgery, setDeleteSurgery] = useState(false);
  const [setDate, toggleSetDate] = useState(false);
  const [selectDate, setSelectDate] = useState(moment(day));
  const [openEquipment, toggleEquipment] = useState(false);
  const [isEquipmentModelDelete, toggleEquipmentModelDelete] = useState(false);
  const [tempCheckDate, setTempCheckDate] = useState(false);
  const [tempCheckToday, setTempCheckToday] = useState(false);
  const [tempBookingMode, setTempBookingMode] = useState(false);
  const [tempChiefSurgeon, setTempChiefSurgeon] = useState(null);
  const [tempEquipments, setTempEquipments] = useState(equipmentModels);

  useEffect(() => {
    setTempChiefSurgeon(chiefSurgeon);
  }, [chiefSurgeon]);

  // useEffect(() => {
  //   dispatch(
  //     getDepartments({
  //       ignorePaging: true,
  //     })
  //   );
  //   dispatch(
  //     getSurgeryTypes(units[0]?.id, {
  //       ignorePaging: true,
  //       departmentId: department?.id,
  //     })
  //   );
  //   dispatch(getSurgeons(units[0]?.id), department?.id);
  //   dispatch(getEquipmentModels(units[0]?.id, { ignorePaging: true }));
  //   designation === GLOBAL_CONSTANTS.SURGEON && setChiefSurgeon(crew_details);
  // }, []);

  // useEffect(() => {
  //   dispatch(getSurgeons(units[0]?.id, speciality?.id));
  //   dispatch(
  //     getSurgeryTypes(units[0]?.id, {
  //       ignorePaging: true,
  //       departmentId: speciality?.id,
  //     })
  //   );
  // }, [speciality]);

  useEffect(() => {
    setTempCheckDate(selectDate.isAfter(moment().add(1, DATE_TIME_FORMAT.DAY)));
    setTempCheckToday(selectDate.isSame(moment(), DATE_TIME_FORMAT.DAY));
    setClosureTime(checkTimeDifference(units[0]?.ot_booking_closure_time));
    setTempBookingMode((!tempCheckDate && !closureTime) || tempCheckToday);
  }, [selectDate, tempCheckDate, closureTime, tempCheckToday]);

  useEffect(() => {
    if (surgeryName) {
      setSurgeryNames([...surgeryNames, surgeryName]);
      setSurgeryName(null);
    }
  }, [surgeryName]);

  useEffect(() => {
    if (surgeryNames?.length > 0) {
      let equipmentModels = {};

      for (const surgery of surgeryNames) {
        equipmentModels = {
          ...equipmentModels,
          ...surgery?.associated_equipment_models,
        };
      }

      setEquipmentModels(equipmentModels);
    }
  }, [surgeryNames]);

  useEffect(() => {
    if (equipmentModel) {
      if (tempEquipments[equipmentModel?.id]) {
        setTempEquipments({
          ...tempEquipments,
          [equipmentModel?.id]: {
            ...equipmentModel,
            quantity:
              +equipmentQty + tempEquipments[equipmentModel?.id]?.quantity,
          },
        });
      } else {
        setTempEquipments({
          ...tempEquipments,
          [equipmentModel?.id]: {
            ...equipmentModel,
            quantity: +equipmentQty,
          },
        });
      }

      setEquipmentModel(null);
    }
  }, [equipmentModel]);

  const handleSpecialityChange = (value) => {
    setSpeciality(value);
    setChiefSurgeon(null);
  };

  const handleDeleteSurgery = (surgery) => {
    setDeleteSurgery(surgery);
  };

  const handleDeleteModel = () => {
    const data = {};

    Object.values(tempEquipments).forEach((item) => {
      if (selectedEquipmentModel.id !== item?.id) {
        data[item.id] = { ...item };
      }
    });
    setTempEquipments({ ...data });
    toggleEquipmentModelDelete(false);
  };

  const handleSubmitProceed = () => {
    if (!tempChiefSurgeon || surgeryNames?.length < 1 || !speciality) {
      dispatch(handleAlertError(GLOBAL_MESSAGES.FILL_REQUIRED_FIELDS));
    } else {
      setChiefSurgeon(tempChiefSurgeon);
      setActiveStep(2);
      handleSetSteps(1, 2);
    }
  };

  const handleEquipment = () => {
    if (surgeryNames?.length > 0) {
      toggleEquipment(true);
    } else {
      dispatch(handleAlertError(GLOBAL_MESSAGES.SELECT_SURGERY_FIRST));
    }
  };

  return (
    <div className={styles["feasibility"]}>
      {deleteSurgery && (
        <NewModal
          isOpen={deleteSurgery}
          onCancel={() => setDeleteSurgery(null)}
        >
          <div className={styles["feasibility-overview__delete-surgery-modal"]}>
            <label>{`${GLOBAL_MESSAGES.REMOVE} "${deleteSurgery?.name}" from list ?`}</label>
            <div
              className={
                styles["feasibility-overview__delete-surgery-modal__button"]
              }
            >
              <NewButton
                shadow
                onClick={() => {
                  setSurgeryNames(
                    surgeryNames?.filter(
                      (data) => data.id !== deleteSurgery?.id
                    )
                  );
                  setDeleteSurgery(false);
                }}
              >
                {BUTTON_TYPES.REMOVE}
              </NewButton>
              <NewButton
                cancelButton
                shadow
                onClick={() => setDeleteSurgery(false)}
              >
                {BUTTON_TYPES.CANCEL}
              </NewButton>
            </div>
          </div>
        </NewModal>
      )}
      <div className={styles["feasibility-overview"]}>
        <h2>{GLOBAL_MESSAGES.ENTER_SURGERY_DETAILS}</h2>
        <div className={styles["feasibility-overview-check"]}>
          {designation === GLOBAL_CONSTANTS.SURGEON ? (
            <>
              <TextInput label="Speciality" value={speciality?.name} disabled />
              <TextInput
                label="Chief Surgeon"
                value={tempChiefSurgeon?.name}
                disabled
              />
            </>
          ) : (
            <>
              <AdvancedDropdown
                label="Speciality"
                required
                value={speciality}
                onChange={(value) => handleSpecialityChange(value)}
                options={departments}
                placeholder={GLOBAL_MESSAGES.SELECT}
              />
              <AdvancedDropdown
                label="Chief Surgeon"
                required
                value={tempChiefSurgeon}
                onChange={(value) => setTempChiefSurgeon(value)}
                options={surgeons}
                placeholder={GLOBAL_MESSAGES.SELECT}
              />
            </>
          )}
          <AdvancedDropdown
            label="Surgery Name"
            required={surgeryNames?.length === 0}
            value={surgeryName}
            onChange={(value) => setSurgeryName(value)}
            options={surgeryTypes?.filter(
              (surgery) =>
                !surgeryNames?.find(
                  (surgeryType) => surgeryType?.id === surgery?.id
                )
            )}
            placeholder={GLOBAL_MESSAGES.SELECT}
          />
          {surgeryNames?.length > 0 && (
            <div className={styles["feasibility-overview-check__surgeryNames"]}>
              {surgeryNames.map((surgery) => (
                <div
                  key={surgery?.id}
                  className={styles["feasibility-overview-check__surgeryName"]}
                >
                  <div
                    className={
                      styles["feasibility-overview-check__surgery-detail"]
                    }
                  >
                    <div>{surgery?.name}</div>
                    <div>
                      <span>{`${GLOBAL_CONSTANTS.STANDARD_PROCEDURE_TIME}: ${
                        surgery?.standard_procedure_time / 60000
                      } ${GLOBAL_CONSTANTS.MINS}`}</span>
                      <span>{`${GLOBAL_CONSTANTS.CLEANING_TIME}: ${
                        surgery?.buffer_time_required / 60000
                      } ${GLOBAL_CONSTANTS.MINS}`}</span>
                    </div>
                  </div>
                  <div
                    onClick={() => handleDeleteSurgery(surgery)}
                    className={styles["feasibility-overview-check__delete-btn"]}
                  >
                    <CgTrash color="#B51616" fontSize={24} />
                  </div>
                </div>
              ))}
            </div>
          )}
          {/* </div> */}
          {surgeryNames?.length === 0 && (
            <div className={styles["feasibility-overview-free-space"]}></div>
          )}
          {/* <div className={styles["feasibility-overview__bottom-container"]}> */}
          <div className={styles["feasibility-overview-check__button-group"]}>
            <NewButton popupButton shadow onClick={() => toggleSetDate(true)}>
              <label
                className={styles["feasibility-overview-check__button-label"]}
              >
                {moment(day).format(DATE_TIME_FORMAT.DD_MMM_YYYY)}
                <img src={iconCalendar} alt="icon-calendar" />
              </label>
            </NewButton>
            <NewButton popupButton shadow onClick={handleEquipment}>
              <label
                className={styles["feasibility-overview-check__button-label"]}
              >
                {GLOBAL_CONSTANTS.EQUIPMENTS}
                <IoIosCut size={14} />
              </label>
            </NewButton>
          </div>
        </div>
      </div>
      <div className={styles["feasibility-overview-check__button-proceed"]}>
        <NewButton largeButton onClick={handleSubmitProceed}>
          {BUTTON_TYPES.PROCEED}
        </NewButton>
      </div>
      {setDate && (
        <Modal
          isOpen={setDate}
          onCancel={() => {
            toggleSetDate(false);
            setSelectDate(moment(day));
          }}
          fullModal
          closeButton
          title={WORDINGS.SELECT_DATE}
        >
          <div className={styles["feasibility-overview__modal__select-date"]}>
            <label>
              {`${WORDINGS.SURGERY_DATE} : 
              ${moment(selectDate).format(DATE_TIME_FORMAT.Do_MMMM_YYYY)}`}
            </label>
            <NewCalender value={selectDate} setValue={setSelectDate} />
            <span
              className={
                styles["feasibility-overview__modal__select-date_booking-mode"]
              }
            >
              {tempBookingMode && WORDINGS.UNPLANNED_ALERT}
            </span>
            <div className={styles["feasibility_modal-button"]}>
              <NewButton
                className={styles["feasibility_modal-button"]}
                largeButton
                onClick={() => {
                  setDay(selectDate);
                  toggleSetDate(false);
                }}
              >
                {WORDINGS.DONE}
              </NewButton>
            </div>
          </div>
        </Modal>
      )}
      {openEquipment && (
        <Modal
          isOpen={openEquipment}
          fullModal
          closeButton
          onCancel={() => {
            toggleEquipment(false);
            setTempEquipments(equipmentModels);
          }}
          title={WORDINGS.SELECT_EQUIPMENTS}
        >
          <div className={styles["feasibility-overview__select-equipment"]}>
            <AdvancedDropdown
              value={equipmentModel}
              onChange={(value) => setEquipmentModel(value)}
              options={eqModels.filter((model) => !tempEquipments[model?.id])}
              placeholder={GLOBAL_MESSAGES.SELECT}
            />
            <div
              className={styles["feasibility-overview__select-equipment-list"]}
            >
              {Object.keys(tempEquipments).length > 0 ? (
                <div className={styles["feasibility-overview__equipments"]}>
                  {Object.values(tempEquipments)
                    ?.reverse()
                    ?.map((model) => (
                      <div
                        key={model?.id}
                        className={styles["feasibility-overview__equipment"]}
                      >
                        <div>{model?.name}</div>
                        <div
                          onClick={() => {
                            toggleEquipmentModelUpdate(true);
                            setSelectedEquipmentModel(model);
                          }}
                          className={
                            styles["feasibility-overview__equipment-edit-btn"]
                          }
                        >
                          <div>{model?.quantity}</div>
                          {/* <MdModeEdit color="#0E376E" size={18} /> */}
                          <img src={editIcon} alt="edit" />
                        </div>
                        <div
                          onClick={() => {
                            toggleEquipmentModelDelete(true);
                            setSelectedEquipmentModel(model);
                          }}
                          className={
                            styles["feasibility-overview__equipment-delete-btn"]
                          }
                        >
                          <CgTrash color="#B51616" fontSize={24} />
                        </div>
                      </div>
                    ))}
                </div>
              ) : (
                <p
                  className={
                    styles["feasibility-overview__equipment-not-selected"]
                  }
                >
                  {GLOBAL_MESSAGES.NO_SELECTED_EQUIPMENTS}
                </p>
              )}
              {isEquipmentModelUpdate && (
                <EquipmentModelUpdate
                  isOpen={isEquipmentModelUpdate}
                  toggleOpen={toggleEquipmentModelUpdate}
                  equipmentModel={selectedEquipmentModel}
                  setEquipmentModel={setSelectedEquipmentModel}
                  equipmentModels={tempEquipments}
                  setEquipmentModels={setTempEquipments}
                />
              )}
              {isEquipmentModelDelete && (
                <Modal
                  isOpen={isEquipmentModelDelete}
                  onCancel={() => toggleEquipmentModelDelete(false)}
                >
                  <div className={styles["equipment-model-delete"]}>
                    <div className={styles["equipment-model-delete__title"]}>
                      <span>{`${GLOBAL_MESSAGES.REMOVE} "${selectedEquipmentModel?.name}" ?`}</span>
                    </div>
                    <div
                      className={
                        styles["equipment-model-delete__button-container"]
                      }
                    >
                      <NewButton onClick={() => handleDeleteModel()}>
                        {BUTTON_TYPES.REMOVE}
                      </NewButton>
                      <NewButton
                        cancelButton
                        onClick={() => toggleEquipmentModelDelete(false)}
                      >
                        {BUTTON_TYPES.CANCEL}
                      </NewButton>
                    </div>
                  </div>
                </Modal>
              )}
            </div>
            <div
              className={
                styles["feasibility-overview__select-equipment-button"]
              }
            >
              <NewButton
                largeButton
                onClick={() => {
                  toggleEquipment(false);
                  setEquipmentModels(tempEquipments);
                }}
              >
                {WORDINGS.DONE}
              </NewButton>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default SurgeryDetails;
