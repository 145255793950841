import { useState } from "react";
import { joinClass } from "../../helpers/utils";
import styles from "./index.module.css";

const TextInput = ({
  value,
  onChange,
  type,
  label,
  dark,
  autoFocus,
  required,
  className,
  inputRef,
  disabled,
  validateDecimalNumber = false,
  validateSpecialCharacter = false,

  ...props
}) => {
  const [errorMessage, setErrorMessage] = useState("");

  const handleKeyDown = (event) => {
    if (
      !validateDecimalNumber &&
      type === "number" &&
      (event.key === "." || event.key === "," || event.key === "+")
    ) {
      event.preventDefault();
    } else if (type === "text" && !validateSpecialCharacter) {
      const pattern = /^[a-zA-Z0-9-_ ]+$/;

      if (!pattern.test(event.key)) {
        event.preventDefault();
        setErrorMessage("Do not contain special characters except - or _");
      } else setErrorMessage("");
    }
  };

  const handleOnPaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData("text");

    // Prevent pasting decimal point (period)
    if (
      !validateDecimalNumber &&
      type === "number" &&
      (pastedText.includes(".") ||
        pastedText.includes(",") ||
        pastedText.includes("+"))
    ) {
      event.preventDefault();
    } else if (type === "text" && !validateSpecialCharacter) {
      const pattern = /^[a-zA-Z0-9-_ ]+$/;
      if (!pattern.test(event.clipboardData.getData("text"))) {
        event.preventDefault();
        setErrorMessage("Do not contain special characters except - or _");
      } else setErrorMessage("");
    }
  };

  return (
    <div
      className={joinClass(
        styles["text-input"],
        dark && styles["text-input--dark"],
        className && className
      )}
    >
      {label && (
        <label className={styles["text-input__label"]}>
          {label}
          {required && (
            <span className={styles["text-input__required"]}>{" *"}</span>
          )}
        </label>
      )}
      <input
        className={joinClass(
          styles["text-input__input"],
          dark && styles["text-input__input--dark"],
          disabled && styles["text-input_disabled"]
        )}
        type={type}
        value={value}
        autoFocus={autoFocus}
        required={required}
        onChange={(event) => onChange(event.target.value)}
        ref={inputRef}
        disabled={disabled}
        {...props}
      />
    </div>
  );
};

TextInput.defaultProps = {
  type: "text",
};

export default TextInput;
