import requestMethods from "../../../helpers/request";
import {
  getItemFromLocalStorage,
  handleAlertError,
  handleAlertSuccess,
  history,
} from "../../../helpers/utils";
import { BOOKING_ACTIONS } from "./constants";

const handleErrorMessage = (error) => {
  return {
    type: "SET_TOASTER_DATA",
    notification: {
      message: error.message,
      title: "Error",
      type: "error",
    },
  };
};

const handleSuccessMessage = (message) => {
  return {
    type: "SET_TOASTER_DATA",
    notification: {
      message,
      title: "Success",
      type: "success",
    },
  };
};

export const getBookings = (unitId, params, loader) => async (dispatch) => {
  const { company: { id: companyId = "" } = {} } =
    getItemFromLocalStorage("user") || {};

  try {
    const res = await requestMethods.GET({
      endpoint: `v1/ot/schedule/request/web/${companyId}/${unitId}/get-all-requests`,
      params,
    });
    if (res.status) {
      dispatch({
        type: BOOKING_ACTIONS.GET_BOOKINGS_SUCCESS,
        payload: res.request_list,
        totalCount: res.total_count,
      });

      if (loader) loader(false);
    }
  } catch (error) {
    dispatch(handleAlertError(error.message));

    if (loader) loader(false);
  }
};

export const getDepartments = (params, stopLoader) => async (dispatch) => {
  const { company: { id: companyId = "" } = {} } =
    getItemFromLocalStorage("user") || {};

  const { page, size, sort, ignorePaging, otEnabled, searchText } = params;

  try {
    const res = await requestMethods.GET({
      endpoint: `v1/department/${companyId}/get`,
      params: {
        otEnabled: otEnabled || "enabled",
        page: page || 1,
        size: size || 25,
        ignorePaging: ignorePaging || false,
        sort: sort || 1,
        searchText: searchText || "",
      },
    });

    if (res.status) {
      dispatch({
        type: BOOKING_ACTIONS.GET_DEPARTMENTS_SUCCESS,
        payload: res.departments,
      });
      if (stopLoader) stopLoader(false);
    }
  } catch (error) {
    dispatch(handleAlertError(error.message));
    if (stopLoader) stopLoader(false);
  }
};

export const getSurgeryTypes =
  (unitId, params, stopLoader) => async (dispatch) => {
    const { company: { id: companyId = "" } = {} } =
      getItemFromLocalStorage("user") || {};

    const {
      page,
      size,
      sort,
      ignorePaging,
      name,
      crewType,
      ot,
      equipmentCategoryId,
      pharmacyStandard,
      surgeryNameType,
      departmentId,
    } = params;

    try {
      const res = await requestMethods.GET({
        endpoint: `/v1/ot/master/surgery_name/web/${companyId}/${unitId}/get_surgery_names`,
        params: {
          page: page || 1,
          size: size || 25,
          sort: sort || 1,
          ignorePaging: ignorePaging || false,
          name: name || "",
          ot: ot || "",
          crewType: crewType || "",
          equipmentCategoryId: equipmentCategoryId || "",
          departmentId: departmentId || "",
          surgeryNameType: surgeryNameType || "all",
          pharmacyStandard: pharmacyStandard || "",
        },
      });

      if (res.status) {
        dispatch({
          type: BOOKING_ACTIONS.GET_SURGERY_TYPES_SUCCESS,
          payload: res.type_of_surgery_master_list,
        });
        if (stopLoader) stopLoader(false);
      }
    } catch (error) {
      dispatch(handleAlertError(error.message));
      if (stopLoader) stopLoader(false);
    }
  };

export const getSurgeons =
  (unitId, department, stopLoader) => async (dispatch) => {
    const { company: { id: companyId = "" } = {} } =
      getItemFromLocalStorage("user") || {};

    try {
      const res = await requestMethods.GET({
        endpoint: `v1/ot/crew_master/web/${companyId}/${unitId}/get_chief_surgeons`,
        params: {
          ignore_paging: true,
          departmentId: department || "",
          sort: 1,
        },
      });

      if (res.status) {
        dispatch({
          type: BOOKING_ACTIONS.GET_SURGEON_SUCCESS,
          payload: res.chief_surgeon_list,
        });
        if (stopLoader) stopLoader(false);
      }
    } catch (error) {
      dispatch(handleAlertError(error.message));
      if (stopLoader) stopLoader(false);
    }
  };

export const getAnaesthetists = (unitId, stopLoader) => async (dispatch) => {
  const { company: { id: companyId = "" } = {} } =
    getItemFromLocalStorage("user") || {};

  try {
    const res = await requestMethods.GET({
      endpoint: `v1/ot/crew_master/web/${companyId}/${unitId}/get-all`,
      params: {
        ignore_paging: true,
        designation: "anaesthetist",
        sort: 1,
      },
    });

    if (res.status) {
      dispatch({
        type: BOOKING_ACTIONS.GET_ANAESTHETIST_SUCCESS,
        payload: res.crew_master_list,
      });
      if (stopLoader) stopLoader(false);
    }
  } catch (error) {
    dispatch(handleAlertError(error.message));
    if (stopLoader) stopLoader(false);
  }
};

export const getEquipmentModels =
  (unitId, params, stopLoader) => async (dispatch) => {
    const { company: { id: companyId = "" } = {} } =
      getItemFromLocalStorage("user") || {};

    const { page, size, sort, ignorePaging, mainCategoryId, subCategoryId } =
      params;

    try {
      const res = await requestMethods.GET({
        endpoint: `v1/ot/equipment-category/web/${companyId}/${unitId}/get-all`,
        params: {
          page: page || 1,
          size: size || 25,
          sort: sort || 1,
          ignore_paging: ignorePaging || false,
          mainCategoryId: mainCategoryId || "",
          subCategoryId: subCategoryId || "",
        },
      });

      if (res.status) {
        dispatch({
          type: BOOKING_ACTIONS.GET_EQUIPMENT_MODELS_SUCCESS,
          payload: res.models,
        });
        if (stopLoader) stopLoader(false);
      }
    } catch (error) {
      dispatch(handleAlertError(error.message));
      if (stopLoader) stopLoader(false);
    }
  };

export const getBookingAvailabilityOverview =
  (unitId, params, stopLoader) => async (dispatch) => {
    const { company: { id: companyId = "" } = {} } =
      getItemFromLocalStorage("user") || {};

    try {
      const res = await requestMethods.POST({
        endpoint: `v1/ot/schedule/availability/${companyId}/${unitId}/get-ot-availability-overview`,
        body: { ...params },
      });

      if (res.status) {
        dispatch({
          type: BOOKING_ACTIONS.GET_BOOKING_AVAILABILITY_OVERVIEW_SUCCESS,
          payload: res?.ot_slot_data,
        });
        if (stopLoader) stopLoader();
      }
    } catch (error) {
      if (stopLoader) stopLoader();
      dispatch(handleAlertError(error.message));
    }
  };

export const getEmergencyBookingAvailabilityOverview =
  (unitId, params, stopLoader) => async (dispatch) => {
    const { company: { id: companyId = "" } = {} } =
      getItemFromLocalStorage("user") || {};

    try {
      const res = await requestMethods.POST({
        endpoint: `v1/ot/schedule/availability/${companyId}/${unitId}/get-emergency-booking-slot-feasibility`,
        body: { ...params },
      });

      if (res.status) {
        dispatch({
          type: BOOKING_ACTIONS.GET_BOOKING_AVAILABILITY_OVERVIEW_SUCCESS,
          payload: res?.ot_slot_data,
        });
        if (stopLoader) stopLoader();
      }
    } catch (error) {
      if (stopLoader) stopLoader();
      dispatch(handleAlertError(error.message));
    }
  };

export const createBookingRequest =
  (unitId, body, stopLoader) => async (dispatch) => {
    try {
      const { company: { id: companyId = "" } = {} } =
        getItemFromLocalStorage("user") || {};

      const res = await requestMethods.POST({
        endpoint: `v1/ot/schedule/request/web/${companyId}/${unitId}/create-booking-request`,
        body: { ...body },
      });

      if (res.status) {
        // dispatch(handleSuccessMessage("Booking requested successfully"));
        if (stopLoader) stopLoader();
        history.replace("/requestSuccess");
      }
    } catch (error) {
      if (stopLoader) stopLoader();
      dispatch(handleAlertError(error.message));
    }
  };

export const getDesignations = (unitId, stopLoader) => async (dispatch) => {
  const { company: { id: companyId = "" } = {} } =
    getItemFromLocalStorage("user") || {};

  try {
    const res = await requestMethods.GET({
      endpoint: `v1/common-parameter/web/${companyId}/${unitId}/get-designations`,
    });
    if (res.status) {
      dispatch({
        type: BOOKING_ACTIONS.GET_DESIGNATION_SUCCESS,
        payload: res.ot_designations,
      });
      if (stopLoader) stopLoader(false);
    }
  } catch (error) {
    dispatch(handleAlertError(error.message));
    if (stopLoader) stopLoader(false);
  }
};

export const validateSurgeryTimeForRequest =
  (unitId, body, stopLoader) => async (dispatch) => {
    try {
      const { company: { id: companyId = "" } = {} } =
        getItemFromLocalStorage("user") || {};

      const res = await requestMethods.POST({
        endpoint: `v1/ot/schedule/request/web/${companyId}/${unitId}/validate-ot-availability`,
        body,
      });

      if (res.status) {
        if (stopLoader) stopLoader(res);
      }
    } catch (error) {
      if (stopLoader) stopLoader();
      dispatch(handleAlertError(error.message));
    }
  };

export const getRequestDetails =
  (unitId, requestId, stopLoader) => async (dispatch) => {
    try {
      const { company: { id: companyId = "" } = {} } =
        getItemFromLocalStorage("user") || {};

      const res = await requestMethods.GET({
        endpoint: `v1/ot/schedule/request/web/${companyId}/${unitId}/${requestId}/get-booking-request`,
      });

      if (res.status) {
        dispatch({
          type: BOOKING_ACTIONS.GET_REQUEST_DETAILS_SUCCESS,
          payload: res,
        });
        if (stopLoader) stopLoader();
      }
    } catch (error) {
      dispatch(handleAlertError(error.message));
      if (stopLoader) stopLoader();
    }
  };

export const clearBookings = () => {
  return { type: BOOKING_ACTIONS.CLEAR_BOOKINGS };
};

export const resetBookingAvailabilityOverview = () => ({
  type: BOOKING_ACTIONS.CLEAR_BOOKING_AVAILABILITY_OVERVIEW,
});

export const resetRequestDetails = () => ({
  type: BOOKING_ACTIONS.CLEAR_REQUEST_DETAILS,
});

export const getTentativeOverview =
  (unitId, params, stopLoader) => async (dispatch) => {
    try {
      const { company: { id: companyId = "" } = {} } =
        getItemFromLocalStorage("user") || {};
      const res = await requestMethods.POST({
        endpoint: `v1/ot/schedule/request/web/${companyId}/${unitId}/get-ot-slots-matrix`,
        body: params,
      });
      if (res.status) {
        dispatch({
          type: BOOKING_ACTIONS.GET_TENTATIVE_OVERVIEW_SUCCESS,
          payload: res,
        });
        if (stopLoader) stopLoader();
      }
    } catch (error) {
      dispatch(handleAlertError(error.message));
      if (stopLoader) stopLoader();
    }
  };

export const getEmergencyOverview =
  (unitId, params, stopLoader) => async (dispatch) => {
    try {
      const { company: { id: companyId = "" } = {} } =
        getItemFromLocalStorage("user") || {};
      const res = await requestMethods.POST({
        endpoint: `v1/ot/schedule/request/web/${companyId}/${unitId}/get-emergency-booking-overview`,
        body: params,
      });
      if (res.status) {
        dispatch({
          type: BOOKING_ACTIONS.GET_TENTATIVE_OVERVIEW_SUCCESS,
          payload: res,
        });
        if (stopLoader) stopLoader();
      }
    } catch (error) {
      dispatch(handleAlertError(error.message));
      if (stopLoader) stopLoader();
    }
  };
