import React from "react";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import moment from "moment";
import styles from "./index.module.css";

const DateSlider = ({
  day,
  setDay,
  filterKey,
  isEditable = true,
  leftEditable = true,
}) => {
  return (
    <div className={styles["date-slider"]}>
      {isEditable && (
        <AiFillCaretLeft
          color={leftEditable ? "#0e376e" : "#b6b6b6"}
          cursor={"pointer"}
          onClick={() => {
            if (leftEditable) {
              setDay(moment(day).subtract(1, "days"));
              localStorage.setItem(
                filterKey,
                JSON.stringify({
                  date: moment(day).subtract(1, "days"),
                })
              );
            }
          }}
        />
      )}
      <div>{moment(day).format("DD MMMM YYYY")}</div>
      {isEditable && (
        <AiFillCaretRight
          color="#0e376e"
          cursor={"pointer"}
          onClick={() => {
            setDay(moment(day).add(1, "days"));
            localStorage.setItem(
              filterKey,
              JSON.stringify({
                date: moment(day).add(1, "days"),
              })
            );
          }}
        />
      )}
    </div>
  );
};

export default DateSlider;
