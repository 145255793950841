export const BOOKING_ACTIONS = {
  GET_BOOKINGS_SUCCESS: "GET_BOOKINGS_SUCCESS",
  CLEAR_BOOKINGS: "CLEAR_BOOKINGS",
  GET_DEPARTMENTS_SUCCESS: "GET_DEPARTMENTS_SUCCESS",
  GET_SURGERY_TYPES_SUCCESS: "GET_SURGERY_TYPES_SUCCESS",
  GET_SURGEON_SUCCESS: "GET_SURGEON_SUCCESS",
  GET_ANAESTHETIST_SUCCESS: "GET_ANAESTHETIST_SUCCESS",
  GET_EQUIPMENT_MODELS_SUCCESS: "GET_EQUIPMENT_MODELS_SUCCESS",
  GET_BOOKING_AVAILABILITY_OVERVIEW_SUCCESS:
    "GET_BOOKING_AVAILABILITY_OVERVIEW_SUCCESS",
  CLEAR_BOOKING_AVAILABILITY_OVERVIEW: "CLEAR_BOOKING_AVAILABILITY_OVERVIEW",
  GET_DESIGNATION_SUCCESS: "GET_DESIGNATION_SUCCESS",
  GET_REQUEST_DETAILS_SUCCESS: "GET_REQUEST_DETAILS_SUCCESS",
  CLEAR_REQUEST_DETAILS: "CLEAR_REQUEST_DETAILS",
  GET_TENTATIVE_OVERVIEW_SUCCESS: "GET_TENTATIVE_OVERVIEW_SUCCESS",
};
