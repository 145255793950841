import React from "react";
import Card from "../../../components/Card";
import { removeUnderScore } from "../../../helpers/utils";
import { WORDINGS } from "../../../helpers/constants";
import styles from "./index.module.css";

const Crew = ({ requestSurgeryDetails }) => {
  return requestSurgeryDetails?.crew_data_details ? (
    <div className={styles["crew-details"]}>
      {Object.keys(requestSurgeryDetails?.crew_data_details).map((crewKey) =>
        requestSurgeryDetails?.crew_data_details[crewKey].length > 1
          ? requestSurgeryDetails?.crew_data_details[crewKey]?.map(
              (data, index) => (
                <Card
                  title={`${removeUnderScore(crewKey)} ${index + 1}`}
                  key={index}
                >
                  <div>{data?.name}</div>
                </Card>
              )
            )
          : requestSurgeryDetails?.crew_data_details[crewKey].length === 1 && (
              <Card title={removeUnderScore(crewKey)} key={crewKey}>
                <div>
                  {requestSurgeryDetails?.crew_data_details[crewKey].map(
                    (item) => item?.name
                  )}
                </div>
              </Card>
            )
      )}
    </div>
  ) : (
    <div className={styles["request-tab__no-item"]}>
      {WORDINGS.NO_CREW_DETAILS}
    </div>
  );
};

export default Crew;
