import React from "react";
import styles from "./index.module.css";

const ToggleButtonSwitch = ({
  isEmergency,
  buttonClick1,
  buttonClick2,
  button1,
  button2,
}) => {
  return (
    <div className={styles["toggle-switch"]}>
      <label
        className={!isEmergency ? styles["toggle-switch__active"] : ""}
        onClick={buttonClick1}
      >
        {button1}
      </label>
      <label
        className={isEmergency ? styles["toggle-switch__active"] : ""}
        onClick={buttonClick2}
      >
        {button2}
      </label>
      <div
        className={
          isEmergency
            ? styles["toggle-swtich__right"]
            : styles["toggle-swtich__left"]
        }
      ></div>
    </div>
  );
};

export default ToggleButtonSwitch;
