const initialState = {
  message: "",
  type: "",
  title: "",
};

export const toasterReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_TOASTER_DATA": {
      return {
        ...state,
        message: action.notification.message,
        type: action.notification.type,
        title: action.notification.title,
      };
    }
    case "RESET_TOASTER_DATA": {
      return {
        ...state,
        message: "",
        type: "",
        title: "",
      };
    }
    default:
      return state;
  }
};
