import "whatwg-fetch";
import { history, redirect, getItemFromLocalStorage } from "./utils";

const BASE_URL = `${process.env.REACT_APP_API_URL}/api`;

export function getUrlWithQueryParams(base, queryData = {}) {
  const queries = Object.keys(queryData);

  return queries.reduce((acc, query, index) => {
    const url = `${acc}${encodeURIComponent(query)}=${encodeURIComponent(
      queryData[query]
    )}`;

    return index + 1 < queries.length ? `${url}&` : url;
  }, `${base}?`);
}

export function getReqUrl({ params, endpoint }) {
  const base = BASE_URL;
  const url = `${base}/${endpoint}`;

  return params ? getUrlWithQueryParams(url, params) : url;
}

export function getReqOptions(method, { headers, body }) {
  const { token: { token = "" } = {} } = getItemFromLocalStorage("user") || {};
  const reqHeaders = {
    Accept: "application/json",
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
    ...headers,
  };
  const requestOptions = {
    method,
    mode: "cors",
  };
  if (
    method === "POST" ||
    method === "PUT" ||
    method === "PATCH" ||
    method === "DELETE"
  ) {
    if (body instanceof FormData) {
      requestOptions.body = body;
    } else {
      reqHeaders["Content-Type"] = "application/json";
      requestOptions.body = JSON.stringify(body);
    }
  }
  requestOptions.headers = reqHeaders;
  return requestOptions;
}

function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return response;
  }

  return response.json();
}

const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    const contentType = response.headers.get("Content-Type");
    if (contentType === "application/pdf") {
      return response.blob();
    }

    return parseJSON(response);
  } else if (response.status === 403) {
    localStorage.clear();
    history.push("/", {
      from: { pathname: history.location.pathname },
    });
  } else if (response.status === 401) {
    // localStorage.clear();
    // history.push("/", {
    //   from: { pathname: history.location.pathname },
    // });
  }
  return response.json().then((error) => {
    throw error;
  });
};

function request(method, reqOptions) {
  const isLoggedIn = getItemFromLocalStorage("isLoggedIn") || false;
  const expiresAt = getItemFromLocalStorage("userexpiry") || "";
  const theme = getItemFromLocalStorage("theme") || "false";

  if (isLoggedIn && expiresAt && expiresAt < new Date().getTime()) {
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("user");
    localStorage.removeItem("userexpiry");
    localStorage.removeItem("useraccess");
    localStorage.removeItem("tab");
    localStorage.setItem("theme", theme);
    redirect("/");
    return Promise.reject("Session expired!, please log in again");
  }
  const url = getReqUrl(reqOptions);
  const options = getReqOptions(method, reqOptions);
  options.credentials = reqOptions.credentials || "include";

  return fetch(url, options).then(checkStatus);
}

const requestMethods = {
  GET: (req) => request("GET", req),
  POST: (req) => request("POST", req),
  PUT: (req) => request("PUT", req),
  DELETE: (req) => request("DELETE", req),
  PATCH: (req) => request("PATCH", req),
};

export default requestMethods;
