export const BOOKING_MODE = {
  ALL: "all",
  PLANNED: "planned",
  UN_PLANNED: "unplanned",
};

export const BOOKING_TYPE = {
  NORMAL: "normal",
  EMERGENCY: "emergency",
};
export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const GLOBAL_CONSTANTS = {
  SURGEON: "surgeon",
  MINS: "mins",
  STANDARD_PROCEDURE_TIME: "Standard Procedure time",
  STANDARD_CLEANING_TIME: "Standard Cleaning Time",
  CLEANING_TIME: "Cleaning time",
  EQUIPMENTS: "Equipments",
  ITEM: "Item",
  VENDOR: "Vendor",
  QUANTITY: "Quantity",
  MR_NO: "MR No",
  BED_NO: "Bed No",
  PROCEDURE_TIME: "Procedure Time",
  NOTES: "Notes",
  NAME: "Name",
  START_TIME: "Start Time",
  END_TIME: "End Time",
};

export const GLOBAL_MESSAGES = {
  ENTER_AN_EMAIL: "Enter an email",
  ENTER_A_VALID_EMAIL: "Enter a valid email",
  ENTER_A_PASSWORD: "Enter a password",
  GOOD_MORNING: "Good Morning",
  GOOD_AFTERNOON: "Good Afternoon",
  GOOD_EVENING: "Good Evening",
  ENTER_SURGERY_DETAILS: "Enter Surgery Details",
  SELECT: "-Select-",
  REMOVE: "Remove",
  FILL_REQUIRED_FIELDS: "Please fill all required fields",
  ENTER_PATIENT_INFO: "Enter Patient Info",
  SKIP_PHARMACY_SUPPLIES: "Skip pharmacy supplies?",
  SKIP_PHARMACY_MESSAGE: "Do you want to skip selecting pharmacy supplies?",
  SELECT_PHARMACY_SUPPLIES: "Select Pharmacy Supplies",
  SELECT_OT_TIME_SLOT: "Select OT & Time Slot",
  SUCCESSFULLY_ADDED: "Successfully Added",
  SUCCESSFULLY_UPDATED: "Successfully Updated",
  TIME_SLOT_UPDATED: "Time Slot Updated",
  SLOT_NOT_AVAILABLE: "Slot not available, please choose another",
  SLOT_AVAILABLE: "Slot Available",
  OT_REQUEST_SUCCESS: "OT Requested Successfully",
  REQUEST_ANOTHER_OT: "Request Another OT",
  BACK_TO_HOME: "Back to Home Page",
  NORMAL: "Normal",
  EMERGENCY: "Emergency",
  SELECT_TIME_ERROR: "Select start time between",
  SELECT_SURGERY_FIRST: "Please Select Surgery first",
  NO_SELECTED_EQUIPMENTS: "Selected equipments will be displayed here",
  SELECT_OT_AND_TIME_SLOT: "Please Select OT & Time Slot",
  SELECT_TIME_SLOT: "Please Select Time Slot",
  EQUIPMENT_ADDED: "Equipment Added",
  AVAILABLE_OT: "Available OT's",
  SELECT_OT: "Please Select OT",
  SLOT_UPDATED_SUCCESS: "Slots updated successfully",
  PLEASE_CHOOSE_WITHOUT_CLEANING_TIME:
    "Please choose start and end time excluding cleaning time",
  ENTER_VALID_NAME: "Enter valid name",
  LISTED: "Listed",
  NON_LISTED: "Non-Listed",
};

export const PROGRESS_STATE = {
  PENDING: "pending",
  COMPLETED: "completed",
};

export const WORDINGS = {
  OT_REQUEST: "OT Request",
  SELECT_DATE: "Select Date",
  SURGERY_DATE: "Surgery Date",
  DONE: "Done",
  SELECT_EQUIPMENTS: "Select Equipments",
  SELECTED_SUPPLIES: "Selected Supplies",
  WHAT_TO_DO_TODAY: "What would you like to do today?",
  LOGOUT_NOW: "Logout Now?",
  UNPLANNED_ALERT: "You are requesting an unplanned booking",
  VIEW_SELECTED_SUPPLIES: "View Selected Supplies",
  VIEW_REQUEST_SUMMARY: "View Request Summary",
  PLANNED: "Planned",
  UN_PLANNED: "Unplanned",
  PHARMACY_SUPPLIES: "Pharmacy Supplies",
  SURGERY_DETAILS: "Surgery Details",
  PATIENT_INFO: "Patient Info",
  TIME_SLOT: "Time Slot",
  NO_EQUIPMENTS_FOUND: "No Equipments Found",
  NO_PHARMACY_SUPPLIES_FOUND: "No Pharmacy Supplies Found",
  NO_STANDARD_PHARMACY_LIST_FOUND: "No Standard Pharmacy List Found",
  UPDATE_SURGERY_TIME: "Update surgery time",
  PREFERRED: "Preferred OT’s",
  NON_PREFERRED: "Non-preferred OT’s",
  EMERGENCY: "Emergency",
  MY_REQUESTS: "My Requests",
  MY_SCHEDULES: "My Schedules",
  REQUEST_DETAILS: "Request Details",
  CONFIRMATIONS: "Confirmations",
  CREW: "Crew",
  BOOKING_MODE: "Booking Mode",
  OTHER_DETAILS: "Other Details",
  EQUIPMENTS: "Equipments",
  STANDARD_PHARMACY_LIST: "Standard Pharmacy List",
  STATUS: "Status",
  UPDATED_BY: "Updated By",
  UPDATED_ON: "Updated On",
  CONFIRMATION: "Confirmation",
  NO_REQUESTS_FOUND: "No Requests Found",
  NO_SCHEDULES_FOUND: "No Schedules Found",
  REQUEST_OT: "Request OT",
  SCHEDULE_DETAILS: "Schedule Details",
  NO_CREW_DETAILS: "No Crew Details",
  NO_CONFIRMATIONS: "No Confirmations",
  LOGIN: "Login",
  QUICK_REQUEST: "Quick Request",
  SELECT_SURGERY_DATE: "Select surgery date ",
  SURGERY_TIMING: "Surgery Timing",
  UPDATE_TIMINGS: "Update Timing",
  REQUEST_OVERVIEW: "Request Overview",
  INCLUDES_CLEANING_TIME: "includes cleaning time",
  BOOKING_OVERVIEW: "Booking Overview",
};

export const BUTTON_TYPES = {
  REMOVE: "Remove",
  CANCEL: "Cancel",
  ADD: "Add",
  SAVE: "Save",
  PROCEED: "Proceed",
  DONE: "Done",
  LOGOUT: "Logout",
  SKIP: "Skip Step",
  RESET: "Reset",
  SELECT: "Select",
  REQUEST: "Request",
  UPDATE: "Update",
  VALIDATE: "Validate",
  CHECK_AVAILABILITY: "Check Availability",
  FIND_AVAILABLE_OT: "Find available OT’s",
  EDIT_TIME: "Edit time",
  SET_TIME: "Set time",
};

export const DATE_TIME_FORMAT = {
  DD_MMM_YYYY: "DD MMM YYYY",
  Do_MMMM_YYYY: "Do MMMM YYYY",
  hh_mm_A: "hh:mm A",
  DAY: "day",
  X: "x",
  MILLISECONDS: "milliseconds",
  MINUTES: "minutes",
};

export const TAB_TYPES = {
  REQUEST_DETAILS: "requestDetails",
  CONFIRMATIONS: "confirmations",
  CREW: "crew",
  SCHEDULE_DETAILS: "scheduleDetails",
};

export const MODE = {
  unplanned: "Unplanned",
  planned: "Planned",
};

export const API_WORDINGS = {
  confirmed: "Confirmed",
  pending: "Pending",
  Anaesthetist_Readiness: "Anaesthetist Readiness",
  Blood_Bank: "Blood Bank",
  CSSD: "CSSD",
  Housekeeping_Readiness: "Housekeeping Readiness",
  Nurse_Readiness: "Nurse Readiness",
  Pharmacy: "Pharmacy",
  Technicians_Readiness: "Technicians Readiness",
};

export const PAGE_DATA = {
  DATE: "date",
  TAB: "tab",
  REDIRECT_PAGE: "redirectPage",
};

export const PAGES = {
  QUICK_BOOKING: "quickBookingRequest",
  REQUEST_OT: "newBookingRequest",
};

export const PROGRESS_STEP_LABELS = {
  OT_SELECTION: "OT selection",
  PATIENT_INFO: "Patient info",
  PHARMACY_SUPPLIES: "Pharmacy supplies",
};

export const PATIENT_NAME_REGEX = /^[A-Za-z\s]+$/;

export const BOOKING_OVERVIEW_TABS = {
  NON_LISTED: "Non-Listed",
  LISTED: "Listed",
  EMERGENCY: "emergency",
  NORMAL: "normal",
};
