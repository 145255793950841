import React from "react";
import { joinClass } from "../../helpers/utils";
import { PROGRESS_STATE } from "../../helpers/constants";
import styles from "./index.module.css";

function ProgressBar({ steps, setSteps, activeStep, setActiveStep }) {
  const handleClick = (clickedStepKey) => {
    setActiveStep(clickedStepKey);
    setSteps(
      steps.map((step) => {
        if (step.key >= clickedStepKey) {
          return { ...step, status: PROGRESS_STATE.PENDING };
        } else {
          return { ...step };
        }
      })
    );
  };

  return (
    <div className={styles["Progress-bar"]}>
      <div className={styles["Progress-bar__group"]}>
        {steps.map((stepItem, index) => (
          <React.Fragment key={stepItem.key}>
            <div className={styles["Progress-bar__step"]}>
              <div
                className={joinClass(
                  styles["Progress-bar__circle"],
                  stepItem.key === activeStep
                    ? styles[`Progress-bar__circle_active`]
                    : stepItem.status === PROGRESS_STATE.COMPLETED
                    ? styles[`Progress-bar__circle_completed`]
                    : ""
                )}
                onClick={() =>
                  stepItem.status === PROGRESS_STATE.COMPLETED ||
                  stepItem.key < activeStep
                    ? handleClick(stepItem.key)
                    : null
                }
              >
                {stepItem.status === PROGRESS_STATE.COMPLETED ? (
                  stepItem.key === activeStep ? (
                    stepItem.key
                  ) : (
                    <stepItem.icon color="#44793F" />
                  )
                ) : (
                  stepItem.key
                )}
              </div>
              <div className={styles["Progress-bar__label"]}>
                {stepItem.label}
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
      <div className={styles["Progress-bar__line"]}></div>
    </div>
  );
}

export default ProgressBar;
