import React, { useState } from "react";
import moment from "moment";
import {
  BUTTON_TYPES,
  DATE_TIME_FORMAT,
  GLOBAL_CONSTANTS,
  GLOBAL_MESSAGES,
} from "../../../helpers/constants";
import NewButton from "../../../components/NewButton";
import { createTimeObject, handleAlertSuccess } from "../../../helpers/utils";
import AdvancedDropdown from "../../../components/AdvancedDropdown";
import styles from "./index.module.css";
import { useDispatch } from "react-redux";

const UpdateSurgeryTime = (props) => {
  const dispatch = useDispatch();

  const {
    surgeryStartTime,
    surgeryEndTime,
    surgeryDate,
    setSurgeryStartTime,
    setSurgeryEndTime,
    toggleUpdateTime,
    bufferTime,
    procedureTime,
    setTempSelectedOt,
  } = props;

  const [tempStartTime, setTempStartTime] = useState(
    createTimeObject(surgeryStartTime)
  );
  const [tempEndTime, setTempEndTime] = useState(
    createTimeObject(
      moment(surgeryEndTime).subtract(bufferTime, "milliseconds")
    )
  );

  const handleChangeTime = () => {
    setTempSelectedOt(null);
    setSurgeryStartTime(+tempStartTime.time);
    setSurgeryEndTime(+tempEndTime.time + bufferTime);
    dispatch(handleAlertSuccess(GLOBAL_MESSAGES.SLOT_UPDATED_SUCCESS));
    toggleUpdateTime(false);
  };

  const roundToNearest30Minutes = (time) => {
    const minutes = time.minute();
    const remainder = minutes % 30;
    if (remainder === 0) {
      return time;
    }
    return time.add(30 - remainder, "minutes").startOf("minute");
  };

  const startTimes = () => {
    const data = [];

    let start;

    if (moment(surgeryDate).isSame(moment(), "day")) {
      start = roundToNearest30Minutes(moment());
    } else if (moment(surgeryDate).isAfter(moment(), "day")) {
      start = moment(surgeryDate).startOf(DATE_TIME_FORMAT.DAY);
    } else {
      return data;
    }

    while (moment(start) <= moment(surgeryDate).endOf(DATE_TIME_FORMAT.DAY)) {
      data.push({
        id: moment(start)
          .set({ seconds: 0, milliseconds: 0 })
          .format(DATE_TIME_FORMAT.X)
          .toString(),
        time: moment(start),
        label: moment(start).format(DATE_TIME_FORMAT.hh_mm_A),
      });

      start = moment(start).add(30, "minute");
    }

    return data;
  };

  const endTimes = () => {
    const data = [];
    let start = moment(tempStartTime?.time).add(30, "minutes");

    while (
      moment(start) < moment(tempEndTime.time).endOf(DATE_TIME_FORMAT.DAY)
    ) {
      data.push({
        id: moment(start).format(DATE_TIME_FORMAT.X).toString(),
        time: moment(start),
        label: moment(start).format(DATE_TIME_FORMAT.hh_mm_A),
      });
      start = moment(start).add(30, "minutes");
    }

    return data;
  };

  return (
    <div className={styles["update-time__update-time"]}>
      <div className={styles["update-time__update-time__container"]}>
        <label className={styles["update-time__container__notes"]}>
          {GLOBAL_MESSAGES.PLEASE_CHOOSE_WITHOUT_CLEANING_TIME}
        </label>
        <AdvancedDropdown
          value={tempStartTime}
          onChange={(value) => {
            setTempStartTime(value);
            setTempEndTime({
              id: moment(value?.time)
                .add(procedureTime, "milliseconds")
                .format(DATE_TIME_FORMAT.X)
                .toString(),
              time: moment(value?.time).add(procedureTime, "milliseconds"),
              label: moment(value?.time)
                .add(procedureTime, "milliseconds")
                .format(DATE_TIME_FORMAT.hh_mm_A),
            });
          }}
          label={GLOBAL_CONSTANTS.START_TIME}
          options={startTimes()}
          required
          isSearchable={false}
        />
        <AdvancedDropdown
          value={tempEndTime}
          onChange={(value) => {
            setTempEndTime(value);
          }}
          label={GLOBAL_CONSTANTS.END_TIME}
          options={endTimes()}
          required
          isSearchable={false}
        />
        <label
          className={styles["update-time__update-time__container-label"]}
        >{`${GLOBAL_CONSTANTS.CLEANING_TIME} - ${
          bufferTime / 60000
        } mins`}</label>
      </div>
      <NewButton largeButton onClick={handleChangeTime}>
        {BUTTON_TYPES.UPDATE}
      </NewButton>
    </div>
  );
};

export default UpdateSurgeryTime;
