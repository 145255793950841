import React from "react";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import moment from "moment";
import NewAccordion from "../../../components/NewAccordion";
import { WORDINGS } from "../../../helpers/constants";
import { removeUnderScore } from "../../../helpers/utils";
import styles from "./index.module.css";

const Confirmation = ({ requestSurgeryDetails }) => {
  return requestSurgeryDetails?.booking_confirmation_logs ? (
    <div className={styles["confirmation-tab"]}>
      {requestSurgeryDetails?.booking_confirmation_logs.map((logs, index) => (
        <div key={index} className={styles["confirmation-tab_container"]}>
          <label>{removeUnderScore(logs?.log_type)}</label>
          <NewAccordion
            title={
              <div className={styles["confirmation-tab__accordion-title"]}>
                {WORDINGS.STATUS}:
                <label
                  className={
                    logs?.status === "pending"
                      ? styles["confirmation-tab__accordion-title-pending"]
                      : styles["confirmation-tab__accordion-title-confirmed"]
                  }
                >
                  {logs?.status === "confirmed" ? (
                    <>
                      {removeUnderScore(logs?.status)}
                      <IoIosCheckmarkCircleOutline size={25} />
                    </>
                  ) : (
                    `${WORDINGS.CONFIRMATION} ${logs?.status}`
                  )}
                </label>
              </div>
            }
            disabled={logs?.status === "pending" ? true : false}
          >
            <div className={styles["confirmation-tab__details"]}>
              <label>{`${WORDINGS.UPDATED_BY}: ${logs?.confirmed_by?.name}`}</label>
              <label>{`${WORDINGS.UPDATED_ON}: ${moment(
                logs?.confirmed_on
              ).format("h:mm A, DD-MM-YYYY")}`}</label>
            </div>
          </NewAccordion>
        </div>
      ))}
    </div>
  ) : (
    <div className={styles["request-tab__no-item"]}>
      {WORDINGS.NO_CONFIRMATIONS}
    </div>
  );
};

export default Confirmation;
