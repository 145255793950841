import React, { useEffect, useState } from "react";
import DateSelection from "./DateSelection";
import moment from "moment";
import {
  checkTimeDifference,
  getItemFromLocalStorage,
  setRedirectPage,
} from "../../helpers/utils";
import styles from "./index.module.css";
import SurgeryDetails from "./SurgeryDetails";
import ProgressBar from "../../components/ProgressBar";
import { MdDone } from "react-icons/md";
import {
  BOOKING_TYPE,
  PAGES,
  PROGRESS_STATE,
  PROGRESS_STEP_LABELS,
} from "../../helpers/constants";
import OtSelection from "./OtSelection";
import PatientInfo from "./PatientInfo";
import PharmacySuplies from "./PharmacySupplies";
import QuickRequestDetails from "./QuickRequestDetails";

const QuickBookingRequest = () => {
  const {
    units = [],
    department,
    crew_details: chiefSurgeon,
  } = getItemFromLocalStorage("user") || {};
  const [step, setStep] = useState(0);
  const [surgeryDate, setSurgeryDate] = useState(moment());
  const [checkDate, setCheckDate] = useState(false);
  const [closureTime, setClosureTime] = useState(false);
  const [checkToday, setCheckToday] = useState(false);
  const [surgeryNames, setSurgeryNames] = useState([]);
  const [equipmentModels, setEquipmentModels] = useState([]);
  const [surgeryStartTime, setSurgeryStartTime] = useState(null);
  const [surgeryEndTime, setSurgeryEndTime] = useState(0);
  const [procedureTime, setProcedureTime] = useState(0);
  const [bufferTime, setBufferTime] = useState(0);
  const [selectedOt, setSelectedOt] = useState(null);
  const [patientName, setPatientName] = useState("");
  const [patientMR, setPatientMR] = useState("");
  const [notes, setNotes] = useState("");
  const [specialPharmacyList, setSpecialPharmacyList] = useState([]);
  const [bookingMode, setBookingMode] = useState(false);
  const [crewDetails, setCrewDetails] = useState(null);
  const [isEmergency, setIsEmergency] = useState(false);
  const [patientRecord, setPatientRecord] = useState(null);

  const [proStep, setProStep] = useState([
      {
        key: 1,
        label: PROGRESS_STEP_LABELS.OT_SELECTION,
        icon: MdDone,
        status: PROGRESS_STATE.PENDING,
      },
      {
        key: 2,
        label: PROGRESS_STEP_LABELS.PATIENT_INFO,
        icon: MdDone,
        status: PROGRESS_STATE.PENDING,
      },
      {
        key: 3,
        label: PROGRESS_STEP_LABELS.PHARMACY_SUPPLIES,
        icon: MdDone,
        status: PROGRESS_STATE.PENDING,
      },
    ]),
    [activeStep, setActiveStep] = useState(1);

  useEffect(() => {
    setCheckDate(surgeryDate.isAfter(moment().add(1, "day")));
    setCheckToday(surgeryDate.isSame(moment(), "day"));
    setClosureTime(checkTimeDifference(units[0]?.ot_booking_closure_time));
    setBookingMode((!checkDate && !closureTime) || checkToday);
  }, [surgeryDate, checkDate, closureTime, checkToday]);

  useEffect(() => {
    setRedirectPage(PAGES.QUICK_BOOKING);
  }, []);

  useEffect(() => {
    if (selectedOt?.available_mode === BOOKING_TYPE.EMERGENCY) {
      setIsEmergency(true);
    } else {
      setIsEmergency(false);
    }
  }, [selectedOt]);

  useEffect(() => {
    if (surgeryNames?.length > 0) {
      let addedProcedureTime = 0;
      const bufferTimes = [];
      let crewData = {};

      surgeryNames.forEach((surgery) => {
        bufferTimes.push(surgery?.buffer_time_required);
        addedProcedureTime =
          addedProcedureTime + surgery?.standard_procedure_time;
        crewData = { ...crewData, ...surgery?.crew_type?.crew_details };
      });

      setCrewDetails(crewData);
      setProcedureTime(addedProcedureTime);
      setBufferTime(Math.max(...bufferTimes));
    }
  }, [surgeryNames]);

  // useEffect(() => {
  //   const time = surgeryStartTime.format("HH:mm A");
  //   const date = surgeryDate.format("YYYY-MM-DD");
  //   setSurgeryStartTime(moment(`${date} ${time}`, "YYYY-MM-DD HH:mm A"));
  // }, [surgeryDate]);

  const handleSetSteps = (currentStep, nextStep) => {
    setProStep(
      proStep.map((step) => {
        if (step.key === currentStep) {
          return { ...step, status: PROGRESS_STATE.COMPLETED };
        } else if (step.key === nextStep) {
          return { ...step, status: PROGRESS_STATE.PENDING };
        } else {
          return { ...step };
        }
      })
    );
  };

  const dateSelectionProps = {
    units,
    surgeryDate,
    closureTime,
    setClosureTime,
    setSurgeryDate,
    setStep,
  };

  const surgeryDetailsProps = {
    units,
    surgeryDate,
    department,
    surgeryNames,
    closureTime,
    equipmentModels,
    surgeryStartTime,
    procedureTime,
    bufferTime,
    setSurgeryEndTime,
    setSurgeryStartTime,
    setEquipmentModels,
    setClosureTime,
    setSurgeryNames,
    setSurgeryDate,
    setStep,
  };

  const otSelectionProps = {
    units,
    surgeryStartTime,
    surgeryEndTime,
    selectedOt,
    surgeryName: surgeryNames[0],
    surgeryDate,
    bufferTime,
    procedureTime,
    setActiveStep,
    setSurgeryStartTime,
    setSurgeryEndTime,
    setSelectedOt,
    setStep,
    handleSetSteps,
  };

  const patientInfoProps = {
    patientName,
    patientMR,
    notes,
    patientRecord,
    chiefSurgeon,
    setPatientRecord,
    setPatientName,
    setPatientMR,
    setNotes,
    setActiveStep,
    handleSetSteps,
  };

  const specialPharmacyListProps = {
    specialPharmacyList,
    setSpecialPharmacyList,
    handleSetSteps,
    setActiveStep,
  };

  const quickRequestDetailsProps = {
    surgeryDate,
    surgeryNames,
    selectedOt,
    patientName,
    patientMR,
    notes,
    equipmentModels,
    specialPharmacyList,
    chiefSurgeon,
    speciality: department,
    bufferTime,
    bookingMode,
    units,
    crewDetails,
    surgeryStartTime,
    surgeryEndTime,
    isEmergency,
    setActiveStep,
  };

  return (
    <div className={styles["quick-booking"]}>
      {step === 0 && <DateSelection {...dateSelectionProps} />}
      {step === 1 && <SurgeryDetails {...surgeryDetailsProps} />}
      {step === 2 && (
        <div className={styles["quick-booking__progress-page"]}>
          <ProgressBar
            steps={proStep}
            setSteps={setProStep}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
          {activeStep === 1 && <OtSelection {...otSelectionProps} />}
          {activeStep === 2 && <PatientInfo {...patientInfoProps} />}
          {activeStep === 3 && (
            <PharmacySuplies {...specialPharmacyListProps} />
          )}
          {activeStep === 4 && (
            <QuickRequestDetails {...quickRequestDetailsProps} />
          )}
        </div>
      )}
    </div>
  );
};

export default QuickBookingRequest;
