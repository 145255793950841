import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { IoIosArrowBack } from "react-icons/io";
import { FaCartPlus } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";
import { CgTrash } from "react-icons/cg";
import {
  BUTTON_TYPES,
  GLOBAL_CONSTANTS,
  GLOBAL_MESSAGES,
  WORDINGS,
} from "../../../helpers/constants";
import Button from "../../../components/Button";
import Counter from "../../../components/Counter";
import TextInput from "../../../components/TextInput";
import NewButton from "../../../components/NewButton";
import Modal from "../../../components/Modal";
import { handleAlertError, handleAlertSuccess } from "../../../helpers/utils";
import editIcon from "../../../assets/Icons/editIcon.svg";
import styles from "./index.module.css";

const PharmacySupplies = (props) => {
  const {
    specialPharmacyList,
    setSpecialPharmacyList,
    handleSetSteps,
    setActiveStep,
  } = props;
  const dispatch = useDispatch();

  const [vendor, setVendor] = useState("");
  const [item, setItem] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [selectedPharmacyItem, setSelectedPharmacyItem] = useState(null);
  const [editPharmacyItem, toggleEditPharmacyItem] = useState(false);
  const [deletePharmacyItem, toggleDeletePharmacyItem] = useState(false);
  const [viewList, toggleViewList] = useState(false);
  const [tempPharmacyList, setTempPharmacyList] = useState(specialPharmacyList);
  const [newTempList, setNewTempList] = useState(tempPharmacyList);

  const vendorRef = useRef(null);

  const handleAdd = () => {
    if (item && quantity > 0 && item?.trim()) {
      if (selectedPharmacyItem?.item) {
        setTempPharmacyList(
          tempPharmacyList?.map((data, index) => {
            if (index === selectedPharmacyItem?.index) {
              return {
                vendor: vendor?.trim(),
                item: item?.trim(),
                quantity: quantity,
              };
            } else return data;
          })
        );
        dispatch(handleAlertSuccess(GLOBAL_MESSAGES.SUCCESSFULLY_UPDATED));
      } else {
        setTempPharmacyList([
          ...tempPharmacyList,
          { vendor: vendor?.trim(), item: item?.trim(), quantity: quantity },
        ]);
        dispatch(handleAlertSuccess(GLOBAL_MESSAGES.SUCCESSFULLY_ADDED));
      }

      setVendor("");
      setItem("");
      setQuantity(1);
      setSelectedPharmacyItem(null);
      toggleEditPharmacyItem(false);
    } else {
      dispatch(handleAlertError(GLOBAL_MESSAGES.FILL_REQUIRED_FIELDS));
    }
  };

  const handleUpdate = () => {
    if (item && quantity > 0 && item?.trim()) {
      if (selectedPharmacyItem?.item) {
        setNewTempList(
          newTempList?.map((data, index) => {
            if (index === selectedPharmacyItem?.index) {
              return {
                vendor: vendor?.trim(),
                item: item?.trim(),
                quantity: quantity,
              };
            } else return data;
          })
        );
        // dispatch(handleAlertSuccess(GLOBAL_MESSAGES.SUCCESSFULLY_UPDATED));
      }
    }
    setVendor("");
    setItem("");
    setQuantity(1);
    setSelectedPharmacyItem(null);
    toggleEditPharmacyItem(false);
  };
  useEffect(() => {
    if (selectedPharmacyItem?.item) {
      setVendor(selectedPharmacyItem?.vendor);
      setItem(selectedPharmacyItem?.item);
      setQuantity(selectedPharmacyItem?.quantity);
    } else {
      setVendor("");
      setItem("");
      setQuantity(1);
    }

    if (vendorRef?.current) vendorRef?.current?.focus();
  }, [selectedPharmacyItem]);

  useEffect(() => {
    if (viewList) {
      setNewTempList(tempPharmacyList);
    }
  }, [viewList]);

  const decrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const increment = () => {
    setQuantity(quantity + 1);
  };
  const handleSubmit = () => {
    setSpecialPharmacyList(tempPharmacyList);
    setActiveStep(4);
    handleSetSteps(3, 4);
  };
  return (
    <div className={styles["pharmacy-supplies"]}>
      {viewList && (
        <Modal
          fullModal
          isOpen={viewList}
          onCancel={() => toggleViewList(false)}
          title={WORDINGS.SELECTED_SUPPLIES}
          closeButton
        >
          <div className={styles["pharmacy-suplies__modal"]}>
            <div className={styles["pharmacy-supplies__modal-list"]}>
              {Object.keys(newTempList).length > 0 && (
                <div className={styles["pharmacy-supplies__list"]}>
                  {Object.values(newTempList)?.map((item, index) => (
                    <div
                      key={item?.vendor}
                      className={styles["pharmacy-supplies__list__item"]}
                    >
                      <div>{item?.item}</div>
                      <div
                        onClick={() => {
                          toggleEditPharmacyItem(true);
                          setSelectedPharmacyItem({ ...item, index });
                        }}
                        className={
                          styles["pharmacy-supplies__list__item__edit-btn"]
                        }
                      >
                        <div>{item?.quantity}</div>
                        <img src={editIcon} alt="edit" />
                      </div>
                      <div
                        onClick={() => {
                          toggleDeletePharmacyItem(true);
                          setSelectedPharmacyItem(item);
                        }}
                        className={
                          styles["pharmacy-supplies__list__item__delete-btn"]
                        }
                      >
                        <CgTrash color="#B51616" fontSize={24} />
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {deletePharmacyItem && (
                <Modal
                  isOpen={deletePharmacyItem}
                  onCancel={() => toggleDeletePharmacyItem(false)}
                >
                  <div className={styles["pharmacy-supplies__delete"]}>
                    <div className={styles["pharmacy-supplies__delete__title"]}>
                      <span>{`${GLOBAL_CONSTANTS.ITEM}: ${selectedPharmacyItem?.item}`}</span>
                    </div>
                    <div
                      className={
                        styles["pharmacy-supplies__delete__button-container"]
                      }
                    >
                      <NewButton
                        shadow
                        onClick={() => {
                          setNewTempList(
                            newTempList?.filter(
                              (item) => item !== selectedPharmacyItem
                            )
                          );
                          toggleDeletePharmacyItem(false);
                          setSelectedPharmacyItem(null);
                        }}
                        disabled={quantity < 1}
                      >
                        {BUTTON_TYPES.REMOVE}
                      </NewButton>
                      <NewButton
                        shadow
                        cancelButton
                        onClick={() => {
                          toggleDeletePharmacyItem(false);
                          setSelectedPharmacyItem(null);
                        }}
                      >
                        {BUTTON_TYPES.CANCEL}
                      </NewButton>
                    </div>
                  </div>
                </Modal>
              )}
              {editPharmacyItem && (
                <Modal
                  isOpen={editPharmacyItem}
                  onCancel={() => toggleEditPharmacyItem(false)}
                >
                  <div className={styles["pharmacy-supplies__update"]}>
                    <div className={styles["pharmacy-supplies__update__title"]}>
                      <span>{`${GLOBAL_CONSTANTS.ITEM}: ${selectedPharmacyItem?.item}`}</span>
                    </div>
                    <Counter
                      count={quantity}
                      increment={increment}
                      decrement={decrement}
                      minimumValue={1}
                    />
                    <div
                      className={
                        styles["pharmacy-supplies__update__button-container"]
                      }
                    >
                      <NewButton
                        shadow
                        onClick={() => {
                          if (quantity > 0) {
                            handleUpdate();
                          }
                        }}
                        disabled={quantity < 1}
                      >
                        {BUTTON_TYPES.UPDATE}
                      </NewButton>
                      <NewButton
                        shadow
                        cancelButton
                        onClick={() => {
                          toggleEditPharmacyItem(false);
                          setSelectedPharmacyItem(null);
                        }}
                      >
                        {BUTTON_TYPES.CANCEL}
                      </NewButton>
                    </div>
                  </div>
                </Modal>
              )}
            </div>
            <NewButton
              largeButton
              onClick={() => {
                setTempPharmacyList(newTempList);
                setSpecialPharmacyList(newTempList);
                toggleViewList(false);
              }}
            >
              {WORDINGS.DONE}
            </NewButton>
          </div>
        </Modal>
      )}

      <div className={styles["pharmacy-supplies__title"]}>
        <IoIosArrowBack size={26} onClick={() => setActiveStep(2)} />
        <h2>{GLOBAL_MESSAGES.SELECT_PHARMACY_SUPPLIES}</h2>
      </div>
      <div className={styles["pharmacy-supplies__input-group"]} id="test">
        <TextInput
          label="Vendor"
          type="text"
          autoFocus
          value={vendor}
          onChange={(value) => setVendor(value)}
          // required
        />
        <TextInput
          label="Item"
          type="text"
          value={item}
          onChange={(value) => setItem(value)}
          required
        />
        <Counter
          count={quantity}
          increment={increment}
          decrement={decrement}
          label="Quantity"
          minimumValue={1}
        />
        <div className={styles["pharmacy-supplies__button-container"]}>
          <NewButton smallButton onClick={handleAdd}>
            {BUTTON_TYPES.ADD}
          </NewButton>
          <NewButton
            smallButton
            cancelButton
            onClick={() => {
              setVendor("");
              setItem("");
              setQuantity(1);
            }}
            className={styles["special-pharmacy-list__cancel-button"]}
          >
            {BUTTON_TYPES.RESET}
          </NewButton>
        </div>
        {tempPharmacyList?.length > 0 && (
          <div>
            <NewButton largePopupButton onClick={() => toggleViewList(true)}>
              <label className={styles["special-pharmacy-list__view-list"]}>
                {WORDINGS.VIEW_SELECTED_SUPPLIES}
                <FaCartPlus />
              </label>
            </NewButton>
          </div>
        )}
      </div>
      <div>
        <Button
          className={styles["pharmacy-supplies__proceed-button"]}
          onClick={handleSubmit}
        >
          {BUTTON_TYPES.PROCEED}
        </Button>
      </div>
    </div>
  );
};

export default PharmacySupplies;
