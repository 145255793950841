import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  checkTimeDifference,
  getItemFromLocalStorage,
  setRedirectPage,
} from "../../helpers/utils";
import {
  getDepartments,
  getEquipmentModels,
  getSurgeons,
  getSurgeryTypes,
  resetBookingAvailabilityOverview,
} from "../BookingOverview/actions/bookingActions";
import {
  GLOBAL_CONSTANTS,
  PAGES,
  PROGRESS_STATE,
} from "../../helpers/constants";
import Header from "./Header";
import SurgeryDetails from "./SurgeryDetails";
import PatientInfo from "./PatientInfo";
import PharmacySupplies from "./PharmacySupplies";
import SlotSelection from "./SlotSelection";
import RequestOverView from "./RequestOverview";
import NewToaster from "../../components/NewToaster";
import { MdDone } from "react-icons/md";
import styles from "./index.module.css";

const NewBookingRequest = () => {
  const dispatch = useDispatch();
  const {
    units = [],
    department,
    crew_details,
    designation,
  } = getItemFromLocalStorage("user") || {};
  const { bookingAvailabilityOverview } = useSelector(
    (state) => state.bookingReducer
  );

  const { date = moment() } = getItemFromLocalStorage("bookingParameter") || {};
  const [activeStep, setActiveStep] = useState(1);
  const [day, setDay] = useState(moment(date));
  const [speciality, setSpeciality] = useState(department);
  const [surgeryNames, setSurgeryNames] = useState([]);
  const [chiefSurgeon, setChiefSurgeon] = useState(null);
  const [equipmentModels, setEquipmentModels] = useState([]);
  const [isCheckingAvailability, toggleCheckingAvailability] = useState(false);
  const [isfetchedAvailabilitySlot, toggleFetchedAvailabilitySlot] =
    useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [isFinalStep, toggleFinalStep] = useState(false);
  const [bookingMode, setBookingMode] = useState(false);
  const [checkDate, setCheckDate] = useState(false);
  const [closureTime, setClosureTime] = useState(false);
  const [checkToday, setCheckToday] = useState(false);
  const [patientName, setPatientName] = useState("");
  const [patientMR, setPatientMR] = useState("");
  const [bedNumber, setBedNumber] = useState("");
  const [notes, setNotes] = useState("");
  const [specialPharmacyList, setSpecialPharmacyList] = useState([]);
  const [selectedOt, setSelectedOt] = useState(null);
  const [procedureTime, setProcedureTime] = useState(0);
  const [bufferTime, setBufferTIme] = useState(0);
  const [crewDetails, setCrewDetails] = useState(null);
  const [skipPharmacy, setSkipPharmacy] = useState(null);
  const [isEmergency, setIsEmergency] = useState(false);
  const [patientRecord, setPatientRecord] = useState(null);

  const [steps, setSteps] = useState([
    {
      key: 1,
      label: "Surgery Details",
      icon: MdDone,
      status: PROGRESS_STATE.PENDING,
    },
    {
      key: 2,
      label: "Patient Info",
      icon: MdDone,
      status: PROGRESS_STATE.PENDING,
    },
    {
      key: 3,
      label: "Pharmacy Supplies",
      icon: MdDone,
      status: PROGRESS_STATE.PENDING,
    },
    {
      key: 4,
      label: "Slot Selection",
      icon: MdDone,
      status: PROGRESS_STATE.PENDING,
    },
  ]);

  useEffect(() => {
    if (bookingAvailabilityOverview?.length > 0 && !isCheckingAvailability) {
      toggleFetchedAvailabilitySlot(true);
    } else {
      if (isfetchedAvailabilitySlot) toggleFetchedAvailabilitySlot(false);
      if (selectedSlot?.id) setSelectedSlot(null);
      if (isFinalStep) toggleFinalStep(false);
    }
  }, [bookingAvailabilityOverview, isCheckingAvailability]);

  useEffect(() => {
    return () => {
      dispatch(resetBookingAvailabilityOverview());
    };
  }, []);

  useEffect(() => {
    dispatch(
      getDepartments({
        ignorePaging: true,
      })
    );
    dispatch(
      getSurgeryTypes(units[0]?.id, {
        ignorePaging: true,
        departmentId: department?.id,
      })
    );
    dispatch(getSurgeons(units[0]?.id), department?.id);
    dispatch(getEquipmentModels(units[0]?.id, { ignorePaging: true }));
    designation === GLOBAL_CONSTANTS.SURGEON && setChiefSurgeon(crew_details);
  }, []);

  useEffect(() => {
    dispatch(getSurgeons(units[0]?.id, speciality?.id));
    dispatch(
      getSurgeryTypes(units[0]?.id, {
        ignorePaging: true,
        departmentId: speciality?.id,
      })
    );
  }, [speciality]);

  useEffect(() => {
    setCheckDate(day.isAfter(moment().add(1, "day")));
    setCheckToday(day.isSame(moment(), "day"));
    setClosureTime(checkTimeDifference(units[0]?.ot_booking_closure_time));
    setBookingMode((!checkDate && !closureTime) || checkToday);
  }, [day, checkDate, closureTime, checkToday]);

  const handleSetSteps = (currentStep, nextStep) => {
    setSteps(
      steps.map((step) => {
        if (step.key === currentStep) {
          return { ...step, status: PROGRESS_STATE.COMPLETED };
        } else if (step.key === nextStep) {
          return { ...step, status: PROGRESS_STATE.PENDING };
        } else {
          return { ...step };
        }
      })
    );
  };

  const commonProps = {
    day,
    setDay,
    equipmentModels,
    speciality,
    surgeryNames,
    chiefSurgeon,
    bookingMode,
    checkDate,
    closureTime,
    checkToday,
    setBookingMode,
    setCheckDate,
    setClosureTime,
    setCheckToday,
    handleSetSteps,
    setActiveStep,
  };

  const patientInfo = {
    patientName,
    patientMR,
    bedNumber,
    notes,
    setPatientName,
    setPatientMR,
    setBedNumber,
    setNotes,
    handleSetSteps,
    setActiveStep,
    skipPharmacy,
    setSkipPharmacy,
    patientRecord,
    setPatientRecord,
    chiefSurgeon,
  };

  const feasibilityCheckProps = {
    setSpeciality,
    setSurgeryNames,
    setChiefSurgeon,
    setEquipmentModels,
    isCheckingAvailability,
    toggleCheckingAvailability,
    selectedOt,
    setSelectedOt,
    isEmergency,
    setIsEmergency,
    ...commonProps,
  };

  const bookingDetailsProps = {
    day,
    selectedOt,
    surgeryNames,
    speciality,
    chiefSurgeon,
    patientName,
    patientMR,
    bedNumber,
    notes,
    equipmentModels,
    specialPharmacyList,
    procedureTime,
    bufferTime,
    crewDetails,
    handleSetSteps,
    setActiveStep,
    bookingMode,
    isEmergency,
  };

  const specialPharmacyListProps = {
    specialPharmacyList,
    setSpecialPharmacyList,
    handleSetSteps,
    setActiveStep,
  };

  useEffect(() => {
    setRedirectPage(PAGES.REQUEST_OT);
  }, []);

  useEffect(() => {
    if (surgeryNames?.length > 0) {
      let addedProcedureTime = 0;
      const bufferTimes = [];
      let crewData = {};

      surgeryNames.forEach((surgery) => {
        bufferTimes.push(surgery?.buffer_time_required);
        addedProcedureTime =
          addedProcedureTime + surgery?.standard_procedure_time;
        crewData = { ...crewData, ...surgery?.crew_type?.crew_details };
      });

      setCrewDetails(crewData);
      setProcedureTime(addedProcedureTime);
      setBufferTIme(Math.max(...bufferTimes));
    }
  }, [surgeryNames]);

  return (
    <div className={styles["booking-request"]}>
      <Header
        steps={steps}
        setSteps={setSteps}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
      />
      <div className={styles["booking-request__tabs"]}>
        {activeStep === 1 && <SurgeryDetails {...feasibilityCheckProps} />}
        {activeStep === 2 && <PatientInfo {...patientInfo} />}
        {activeStep === 3 && <PharmacySupplies {...specialPharmacyListProps} />}
        {activeStep === 4 && <SlotSelection {...feasibilityCheckProps} />}
        {activeStep === 5 && <RequestOverView {...bookingDetailsProps} />}
      </div>
    </div>
  );
};

export default NewBookingRequest;
