import React from "react";
import styles from "./index.module.css";
import { joinClass } from "../../helpers/utils";
import { MdClose } from "react-icons/md";

const Modal = ({
  children,
  isOpen,
  onCancel,
  bottomModal,
  fullModal,
  closeButton,
  title,
  className,
}) => {
  return (
    isOpen && (
      <>
        <div
          className={joinClass(styles["back-drop"])}
          onClick={() => {
            onCancel(false);
          }}
        />
        <div
          className={joinClass(
            bottomModal ? styles["bottom-modal"] : styles["modal"],
            isOpen ? styles["modal-active"] : styles["modal-inactive"],
            fullModal && styles["modal-full"],
            className
          )}
        >
          {closeButton && (
            <div className={styles["modal-header"]}>
              <div className={styles["modal-header__title"]}>{title}</div>
              <div
                className={styles["modal__close-button"]}
                onClick={() => onCancel(false)}
              >
                {<MdClose size={30} />}
              </div>
            </div>
          )}
          <div className={styles["content"]}>{children}</div>
        </div>
      </>
    )
  );
};

export default Modal;
