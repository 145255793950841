import React, { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  DATE_TIME_FORMAT,
  GLOBAL_CONSTANTS,
  MODE,
  WORDINGS,
} from "../../../helpers/constants";
import scissors from "../../../assets/Icons/scissors-cut-01.svg";
import clock from "../../../assets/Icons/clock.svg";
import hospital from "../../../assets/Icons/hospital.svg";
import userGroup from "../../../assets/Icons/user-group.png";
import { FaStethoscope } from "react-icons/fa";
import equimentIcon from "../../../assets/Icons/scissorsCut.svg";
import pharmacyIcon from "../../../assets/Icons/prescriptionIcon.svg";
import pharmacyListIcon from "../../../assets/Icons/medicalDoc.svg";
import equipmentDark from "../../../assets/Icons/cutDark.svg";
import prescriptionDark from "../../../assets/Icons/prescriptionDark.svg";
import NewButton from "../../../components/NewButton";
import Modal from "../../../components/Modal";
import styles from "./index.module.css";

const ScheduleTab = () => {
  const { scheduleDetails = {} } = useSelector(
    (state) => state.myScheduleReducer
  );

  const [isEquipments, toggleIsEquipments] = useState(false),
    [isPharmacySupplies, toggleIsPharmacySupplies] = useState(false),
    [isStandardPharmacyList, toggleIsStandardPharmacyList] = useState(false);

  return (
    <div className={styles["schedule-tab"]}>
      {isEquipments && (
        <Modal
          isOpen={isEquipments}
          onCancel={() => toggleIsEquipments(false)}
          title={
            <label className={styles["schedule-tab__modal-title"]}>
              {WORDINGS.EQUIPMENTS}
              <img src={equipmentDark} alt="equipments" />
            </label>
          }
          closeButton
        >
          <div className={styles["schedule-tab__equipments-modal"]}>
            <div className={styles["schedule-tab__equipments-list"]}>
              <div>{GLOBAL_CONSTANTS.EQUIPMENTS}</div>
              <div>{GLOBAL_CONSTANTS.QUANTITY}</div>
            </div>
            {scheduleDetails?.ot_equipment_models?.length > 0 ? (
              Object.values(scheduleDetails?.ot_equipment_models)?.map(
                (item, index) => (
                  <div
                    key={index}
                    className={styles["schedule-tab__equipments-list"]}
                  >
                    <div>{item?.name}</div>
                    <div>{item?.equipment_count}</div>
                  </div>
                )
              )
            ) : (
              <div className={styles["schedule-tab__no-item"]}>
                {WORDINGS.NO_EQUIPMENTS_FOUND}
              </div>
            )}
          </div>
        </Modal>
      )}
      {isPharmacySupplies && (
        <Modal
          isOpen={isPharmacySupplies}
          onCancel={() => toggleIsPharmacySupplies(false)}
          title={
            <label className={styles["schedule-tab__modal-title"]}>
              {WORDINGS.PHARMACY_SUPPLIES}
              <img src={prescriptionDark} alt="equipments" />
            </label>
          }
          closeButton
        >
          <div className={styles["schedule-tab__pharmacy-modal"]}>
            <div className={styles["schedule-tab__pharmacy-list"]}>
              <div>{GLOBAL_CONSTANTS.VENDOR}</div>
              <div>{GLOBAL_CONSTANTS.ITEM}</div>
              <div>{GLOBAL_CONSTANTS.QUANTITY}</div>
            </div>
            {scheduleDetails?.pharmacy_special_request.length > 0 ? (
              Object.values(scheduleDetails?.pharmacy_special_request)?.map(
                (item, index) => (
                  <div
                    key={index}
                    className={styles["schedule-tab__pharmacy-list"]}
                  >
                    <div>{item?.vendor}</div>
                    <div>{item?.item}</div>
                    <div>{item?.quantity}</div>
                  </div>
                )
              )
            ) : (
              <div className={styles["schedule-tab__no-item"]}>
                {WORDINGS.NO_PHARMACY_SUPPLIES_FOUND}
              </div>
            )}
          </div>
        </Modal>
      )}
      {isStandardPharmacyList && (
        <Modal
          isOpen={isStandardPharmacyList}
          onCancel={() => toggleIsStandardPharmacyList(false)}
          title={
            <label className={styles["schedule-tab__modal-title"]}>
              {WORDINGS.STANDARD_PHARMACY_LIST}
            </label>
          }
          closeButton
        >
          <div className={styles["schedule-tab__equipments-modal"]}>
            <div className={styles["schedule-tab__equipments-list"]}>
              <div>{GLOBAL_CONSTANTS.NAME}</div>
              <div>{GLOBAL_CONSTANTS.QUANTITY}</div>
            </div>
            {scheduleDetails?.pharmacy_standard_list.length > 0 ? (
              scheduleDetails?.pharmacy_standard_list?.map((item, index) => (
                <div
                  key={index}
                  className={styles["schedule-tab__equipments-list"]}
                >
                  <div>{item?.name}</div>
                  <div>{item?.quantity}</div>
                </div>
              ))
            ) : (
              <div className={styles["schedule-tab__no-item"]}>
                {WORDINGS.NO_STANDARD_PHARMACY_LIST_FOUND}
              </div>
            )}
          </div>
        </Modal>
      )}
      <label className={styles["schedule-tab_day"]}>
        {moment(scheduleDetails?.surgery_start_time).format(
          DATE_TIME_FORMAT.Do_MMMM_YYYY
        )}
      </label>
      <div className={styles["schedule-tab__label-button"]}>
        {scheduleDetails?.ot?.name}
      </div>
      <label className={styles["schedule-tab_day"]}>
        {`${WORDINGS.BOOKING_MODE} : ${MODE[scheduleDetails?.booking_mode]}`}
      </label>
      <div className={styles["schedule-tab__detail"]}>
        <section>
          <label>{WORDINGS.SURGERY_DETAILS}</label>
          <div className={styles["schedule-tab__details"]}>
            <div
              className={
                scheduleDetails?.types_of_surgeries?.length > 1
                  ? styles["schedule-tab__surgery-name"]
                  : ""
              }
            >
              <img src={scissors} alt="surgery" />
              {scheduleDetails?.types_of_surgeries?.length > 1 ? (
                <label>
                  {scheduleDetails?.types_of_surgeries?.map(
                    (surgery, index) => (
                      <div key={index}>
                        {index + 1}.{surgery.name}
                      </div>
                    )
                  )}
                </label>
              ) : (
                <label>
                  {scheduleDetails?.types_of_surgeries?.map(
                    (surgery, index) => (
                      <div key={index}>{surgery.name}</div>
                    )
                  )}
                </label>
              )}
            </div>
            <div>
              <img src={hospital} alt="Speciality" />
              <label>{scheduleDetails?.department?.name}</label>
            </div>
            <div>
              <FaStethoscope size={20} color="#0E376E" />
              {scheduleDetails?.crew_details?.chief_surgeon?.length > 1 ? (
                <label>
                  {scheduleDetails?.crew_details?.chief_surgeon?.map(
                    (surgeon, index) => (
                      <div key={index}>
                        {index + 1}. {surgeon.name}
                      </div>
                    )
                  )}
                </label>
              ) : (
                <label>
                  {scheduleDetails?.crew_details?.chief_surgeon?.map(
                    (surgeon, index) => (
                      <div key={index}>{surgeon.name}</div>
                    )
                  )}
                </label>
              )}
              {/* <label>
                {scheduleDetails?.crew_details?.chief_surgeon?.map(
                  (surgen, index) => surgen.name
                )}
              </label> */}
            </div>
          </div>
        </section>
        <section>
          <label>{WORDINGS.PATIENT_INFO}</label>
          <div className={styles["schedule-tab__details"]}>
            {scheduleDetails?.patient_name && (
              <div>
                <img src={userGroup} alt="icon" width={20} height={20} />
                {scheduleDetails?.patient_name}
              </div>
            )}
            {scheduleDetails?.patient_mrn && (
              <div>{`${GLOBAL_CONSTANTS.MR_NO}: ${scheduleDetails?.patient_mrn}`}</div>
            )}
            {scheduleDetails?.bed_number && (
              <div>{`${GLOBAL_CONSTANTS.BED_NO}: ${scheduleDetails?.bed_number}`}</div>
            )}
            {scheduleDetails?.notes && (
              <div>{`${GLOBAL_CONSTANTS.NOTES}: ${scheduleDetails?.notes}`}</div>
            )}
          </div>
        </section>
        <section>
          <label>{WORDINGS.TIME_SLOT}</label>
          <div className={styles["schedule-tab__details"]}>
            <div>
              <img src={clock} alt="clock" />
              {`${moment(scheduleDetails?.start).format(
                DATE_TIME_FORMAT.hh_mm_A
              )} - ${moment(scheduleDetails?.end).format(
                DATE_TIME_FORMAT.hh_mm_A
              )}`}
            </div>
            <div>
              <label>{`${GLOBAL_CONSTANTS.PROCEDURE_TIME}: ${
                (scheduleDetails?.surgery_end_time -
                  scheduleDetails?.surgery_start_time) /
                60000
              } ${GLOBAL_CONSTANTS.MINS}`}</label>
            </div>
            <div>
              <label>{`${GLOBAL_CONSTANTS.CLEANING_TIME}: ${
                scheduleDetails?.buffer_time / 60000
              } ${GLOBAL_CONSTANTS.MINS}`}</label>
            </div>
          </div>
        </section>
        <div className={styles["schedule-tab__detail-bottom-container"]}>
          <label>{WORDINGS.OTHER_DETAILS}</label>
          <div
            className={styles["schedule-tab__detail-bottom-container-buttons"]}
          >
            <NewButton modalButton onClick={() => toggleIsEquipments(true)}>
              {
                <div className={styles["schedule-tab__modal-buttons"]}>
                  <img src={equimentIcon} alt="Equiments_icon" />
                  <label>{WORDINGS.EQUIPMENTS}</label>
                </div>
              }
            </NewButton>
            <NewButton
              modalButton
              onClick={() => toggleIsPharmacySupplies(true)}
            >
              {
                <div className={styles["schedule-tab__modal-buttons"]}>
                  <img src={pharmacyIcon} alt="Equiments_icon" />
                  <label>{WORDINGS.PHARMACY_SUPPLIES}</label>
                </div>
              }
            </NewButton>
            <NewButton
              modalButton
              onClick={() => toggleIsStandardPharmacyList(true)}
            >
              {
                <div className={styles["schedule-tab__modal-buttons"]}>
                  <img src={pharmacyListIcon} alt="Equiments_icon" />
                  <label>{WORDINGS.STANDARD_PHARMACY_LIST}</label>
                </div>
              }
            </NewButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleTab;
