import React from "react";
import { joinClass } from "../../helpers/utils";
import styles from "./index.module.css";

export const TextArea = ({
  className,
  label,
  id,
  rows,
  cols,
  value,
  onChange,
  autoFocus,
  required,
  totalCount,
}) => {
  return (
    <div className={joinClass(styles.textarea, className)}>
      {label && (
        <label htmlFor={id}>
          {label}
          {required && <span>{" *"}</span>}
        </label>
      )}
      <textarea
        id={id}
        rows={rows}
        cols={cols}
        value={value?.trimLeft()}
        onChange={(event) => onChange(event.target.value)}
        autoFocus={autoFocus}
        required={required}
        maxLength={totalCount}
      />
      {totalCount && <label>{`${value.length}/${totalCount}`}</label>}
    </div>
  );
};
