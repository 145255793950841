import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  getEmergencyOverview,
  getTentativeOverview,
} from "./actions/bookingActions";
import { getItemFromLocalStorage } from "../../helpers/utils";
import DateSlider from "../../components/DateSlider";
import Loader from "../../components/Loader";
import { useClearLocalStorageExceptFilters } from "../../helpers/hooks";
import styles from "./index.module.css";
import {
  BOOKING_MODE,
  BOOKING_OVERVIEW_TABS,
  WORDINGS,
} from "../../helpers/constants";
import ToggleButtonSwitch from "../../components/ToggleButtonSwitch";
import Legend from "../../components/Legend";
import TimelineChart from "./TimelineChart";
import { TbCalendar, TbClockBolt } from "react-icons/tb";

const BookingOverview = () => {
  const dispatch = useDispatch();

  const { units = [] } = getItemFromLocalStorage("user") || {};
  const { date = moment() } = getItemFromLocalStorage("bookingParameter") || {};
  const { tab = BOOKING_OVERVIEW_TABS.LISTED } =
    getItemFromLocalStorage("bookingOverviewTab") || {};

  const { tentativeOverview } = useSelector((state) => state.bookingReducer);
  const { ot_slot_data = [] } = tentativeOverview;

  const [day, setDay] = useState(moment(date));
  const [loader, setLoader] = useState(false);
  const [selectedTab, setSelectedTab] = useState(tab);

  useClearLocalStorageExceptFilters(["bookingParameter"]);

  const getNormalBookings = () => {
    setLoader(true);
    dispatch(
      getTentativeOverview(
        units[0]?.id,
        {
          ot_ids: [],
          department: null,
          surgery_types: [],
          timestamp: +moment(day),
        },
        () => setLoader(false)
      )
    );
  };

  const getEmergencyBookings = () => {
    setLoader(true);
    dispatch(
      getEmergencyOverview(
        units[0]?.id,
        {
          ot_ids: [],
          department: null,
          surgery_types: [],
          timestamp: +moment(day),
        },
        () => setLoader(false)
      )
    );
  };

  useEffect(() => {
    if (selectedTab === BOOKING_OVERVIEW_TABS.LISTED) {
      getNormalBookings();
    } else {
      getEmergencyBookings();
    }
  }, [day, selectedTab]);

  const statusColorMap = {
    surgery: "#f4b404",
    cleaning: "#4484f4",
    available: "#35b41c",
    disabled: "#DBE2E9",
    maintenance: "#f44",
  };

  const emergencyColorMap = {
    surgery: "#f4b404",
    emergency: "#35b41c",
    cleaning: "#4484f4",
    available: "#DBE2E9",
    maintenance: "#f44",
  };

  const legendMap = {
    scheduled: <TbCalendar color="#0084ff" />,
    confirmed: <TbCalendar color="#3ece3e" />,
    pending: <TbCalendar color="#b6b6b6" />,
    unplanned: <TbClockBolt color="#FD51E8" />,
  };

  const groups = useMemo(() => {
    return ot_slot_data.map(({ ot }) => ({
      id: ot.id,
      title: ot.name,
    }));
  }, [ot_slot_data]);

  const items = useMemo(() => {
    const data = [];
    ot_slot_data.map(({ ot, logs = [] }, pIndex) => {
      logs.forEach((log, index) => {
        data.push({
          ...log,
          id: `${pIndex}${index}`,
          start_time: log.start,
          end_time: log.end,
          group: ot.id,
          canMove: false,
          canResize: false,
          canChangeGroup: false,
        });
      });
    });
    return data;
  }, [ot_slot_data]);

  const handleItemWithChildren = (item) => {
    if (item?.booking_confirmation_logs?.length > 0) {
      if (
        item?.booking_confirmation_logs?.every(
          (log) => log.status === "confirmed"
        )
      ) {
        return (
          <section>
            {item?.booking_mode === BOOKING_MODE.UN_PLANNED && (
              <div className={styles["booking-overview__child-unplanned"]}>
                <TbClockBolt color="#FD51E8" />
              </div>
            )}
            <div className={styles["booking-overview__child"]}>
              <TbCalendar
                color={item?.status === "booked" ? "#3ece3e" : "#0084ff"}
                size={14}
              />
            </div>
          </section>
        );
      } else {
        return (
          <section>
            {item?.booking_mode === BOOKING_MODE.UN_PLANNED && (
              <div className={styles["booking-overview__child-unplanned"]}>
                <TbClockBolt color="#FD51E8" />
              </div>
            )}
            <div className={styles["booking-overview__child"]}>
              <TbCalendar
                size={14}
                color={
                  item?.status === "booked"
                    ? "#b6b6b6"
                    : item?.status === "postponed"
                    ? "#f4b404"
                    : item?.status === "canceled"
                    ? "#f44"
                    : "#0084ff"
                }
              />
            </div>
          </section>
        );
      }
    } else {
      if (item.status === "booked" || item?.status === "scheduled") {
        return (
          <section>
            {item?.booking_mode === BOOKING_MODE.UN_PLANNED && (
              <div className={styles["booking-overview__child-unplanned"]}>
                <TbClockBolt color="#FD51E8" />
              </div>
            )}
            <div className={styles["booking-overview__child"]}>
              <TbCalendar
                size={14}
                color={
                  item?.status === "booked"
                    ? "#b6b6b6"
                    : item?.status === "postponed"
                    ? "#f4b404"
                    : item?.status === "canceled"
                    ? "#f44"
                    : "#0084ff"
                }
              />
            </div>
          </section>
        );
      }
    }
  };

  return (
    <div className={styles["booking-overview"]}>
      <h2>{WORDINGS.BOOKING_OVERVIEW}</h2>
      <DateSlider day={day} setDay={setDay} filterKey={"bookingParameter"} />
      <ToggleButtonSwitch
        isEmergency={selectedTab === BOOKING_OVERVIEW_TABS.NON_LISTED}
        button1={BOOKING_OVERVIEW_TABS.LISTED}
        button2={BOOKING_OVERVIEW_TABS.NON_LISTED}
        buttonClick1={() => {
          setSelectedTab(BOOKING_OVERVIEW_TABS.LISTED);
          localStorage.setItem(
            "bookingOverviewTab",
            JSON.stringify({ tab: BOOKING_OVERVIEW_TABS.LISTED })
          );
        }}
        buttonClick2={() => {
          setSelectedTab(BOOKING_OVERVIEW_TABS.NON_LISTED);
          localStorage.setItem(
            "bookingOverviewTab",
            JSON.stringify({ tab: BOOKING_OVERVIEW_TABS.NON_LISTED })
          );
        }}
      />
      <div className={styles["booking-overview__legend-group"]}>
        <div className={styles["booking-overview__legend"]}>
          <Legend
            colorMap={
              selectedTab === BOOKING_OVERVIEW_TABS.LISTED
                ? statusColorMap
                : emergencyColorMap
            }
            isIcons={false}
          />
        </div>
        <div className={styles["booking-overview__legend"]}>
          <Legend colorMap={legendMap} isIcons={true} />
        </div>
      </div>
      {loader ? (
        <Loader />
      ) : ot_slot_data?.length > 0 ? (
        <div className={styles["booking-overview__timeline-chart"]}>
          <TimelineChart
            groups={groups}
            items={items}
            otStartTime={tentativeOverview?.overall_start_time}
            otEndTime={tentativeOverview?.overall_end_time}
            statusColorMap={
              selectedTab === BOOKING_OVERVIEW_TABS.LISTED
                ? statusColorMap
                : emergencyColorMap
            }
            date={moment(day)}
            handleItemWithChildren={handleItemWithChildren}
          />
        </div>
      ) : (
        <div className={styles["booking-overview__no-records"]}>
          {"No records to display"}
        </div>
      )}
    </div>
  );
};

export default BookingOverview;
