import React, { Suspense } from "react";
import { Route, Redirect } from "react-router-dom";
import Layout from "../Layout";
import FallBackUI from "../FallbackUI";

export const ProtectedRoute = ({
  component: Component,
  useLayout = true,
  ...rest
}) => {
  const isLoggedIn = JSON.parse(localStorage.getItem("isLoggedIn"));

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          !useLayout ? (
            <Suspense fallback={FallBackUI}>
              <Component {...props} {...rest} />
            </Suspense>
          ) : (
            <Layout>
              <Suspense fallback={FallBackUI}>
                <Component {...props} {...rest} />
              </Suspense>
            </Layout>
          )
        ) : (
          <Redirect
            exact
            to={{
              pathname: "/",
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
};
