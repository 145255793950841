import moment from "moment";
import React from "react";
import Timeline, {
  DateHeader,
  SidebarHeader,
  TimelineHeaders,
} from "react-calendar-timeline";
import { GLOBAL_CONSTANTS } from "../../../helpers/constants";
import "react-calendar-timeline/lib/Timeline.css";
import { joinClass } from "../../../helpers/utils";
import styles from "./index.module.css";

const TimelineChart = (props) => {
  const {
    groups = [],
    items = [],
    statusColorMap,
    date,
    handleSlotClick,
    handleCursor,
    selectedNewSlot,
    otStartTime,
    otEndTime,
    setHoveredItem,
    handleItemWithChildren,
    showSelection = false,
    tooltipContainerClass = "",
    timelineContainerClass = "",
  } = props;

  const timelineHeaderStyle = {
    borderTopRightRadius: "8px",
    backgroundColor: "#fff",
    userSelect: "none",
  };

  const calendarHeaderStyle = {
    borderTopRightRadius: "8px",
    userSelect: "none",
  };

  const sidebarHeaderStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    fontWeight: "700",
    backgroundColor: "#1aff",
    userSelect: "none",
  };

  const dateHeaderStyle = {
    fontWeight: "700",
    fontSize: "14px",
    color: "#000",
    userSelect: "none",
  };
  const groupStyle = {
    textAlign: "left",
    paddingLeft: "10px",
    fontSize: "14px",
    fontWeight: "600",
    color: "#000",
    userSelect: "none",
  };

  return (
    <section>
      <Timeline
        className={joinClass(styles["timeline"], timelineContainerClass)}
        itemRenderer={({ item, itemContext, getItemProps, getResizeProps }) => {
          return (
            <div
              {...getItemProps(item.itemProps)}
              style={{
                ...getItemProps(item.itemProps).style,
                background:
                  showSelection && item.selected
                    ? "#1A78F2"
                    : statusColorMap[item.event_type],
                border:
                  showSelection && item.selected
                    ? "1px solid #1A78F2"
                    : selectedNewSlot?.id === item.id
                    ? "3px solid #ea0000"
                    : "0.5px solid #fff",
              }}  
            >
              {handleItemWithChildren && handleItemWithChildren(item)}
            </div>
          );
        }}
        visibleTimeStart={
          otStartTime
            ? otStartTime
            : +moment(date).startOf(GLOBAL_CONSTANTS.DAY)
        }
        visibleTimeEnd={
          otEndTime ? otEndTime : +moment(date).endOf(GLOBAL_CONSTANTS.DAY)
        }
        groups={groups}
        items={items}
        groupRenderer={({ group }) => {
          return (
            <div style={groupStyle}>
              <span>{group.title}</span>
            </div>
          );
        }}
        canMove={false}
        canResize={false}
        canChangeGroup={false}
      >
        <TimelineHeaders
          style={timelineHeaderStyle}
          calendarHeaderStyle={calendarHeaderStyle}
        >
          <SidebarHeader>
            {({ getRootProps }) => {
              return (
                <div
                  style={{
                    ...getRootProps().style,
                    ...sidebarHeaderStyle,
                  }}
                >
                  {"OT's"}
                </div>
              );
            }}
          </SidebarHeader>
          <DateHeader style={dateHeaderStyle} labelFormat="HH:mm" />
        </TimelineHeaders>
      </Timeline>
    </section>
  );
};

export default TimelineChart;
