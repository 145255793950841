import { EMAIL_REGEX, GLOBAL_MESSAGES } from "../../helpers/constants";

export const validateEmail = (username, setEmailError) => {
  if (username === "") {
    setEmailError(GLOBAL_MESSAGES.ENTER_AN_EMAIL);
    return false;
  } else if (!EMAIL_REGEX.test(username)) {
    setEmailError(GLOBAL_MESSAGES.ENTER_A_VALID_EMAIL);
    return false;
  }
  setEmailError("");
  return true;
};

export const isPasswordNull = (password, setPasswordError) => {
  if (password === "") {
    setPasswordError(GLOBAL_MESSAGES.ENTER_A_PASSWORD);
    return false;
  }
  setPasswordError("");
  return true;
};
