import { NOTIFICATION_ACTIONS } from "./constants";

const initialState = {
  notifications: [],
  notificationCount: 0,
};

export const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION_ACTIONS.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.payload,
        notificationCount: action.totalCount,
      };
    case NOTIFICATION_ACTIONS.CLEAR_NOTIFICATION_STORE:
      return {
        ...state,
        notifications: [],
        notificationCount: 0,
      };
    default:
      return state;
  }
};
