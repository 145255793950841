import React from "react";
import { WORDINGS } from "../../helpers/constants";
import ProgressBar from "../../components/ProgressBar";
import styles from "./index.module.css";

function Header({ steps, setSteps, activeStep, setActiveStep }) {
  return (
    <div className={styles["Request-Header"]}>
      <header>{WORDINGS.REQUEST_OT}</header>
      <ProgressBar
        steps={steps}
        setSteps={setSteps}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
      />
    </div>
  );
}

export default Header;
