import React from "react";
import styles from "./index.module.css";

const Legend = ({ colorMap, isIcons }) => {
  return (
    <div className={styles["legend"]}>
      {Object.keys(colorMap).map((status, index) => (
        <div
          key={index}
          style={{ display: "flex", alignItems: "center", gap: "8px" }}
        >
          {isIcons ? (
            colorMap[status]
          ) : (
            <span
              style={{
                width: "10px",
                height: "10px",
                backgroundColor: colorMap[status],
                borderRadius: "50%",
              }}
            ></span>
          )}
          <label>{status.charAt(0).toUpperCase() + status.slice(1)}</label>
        </div>
      ))}
    </div>
  );
};

export default Legend;
