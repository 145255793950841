import { useEffect } from "react";

export const useClearLocalStorageExceptFilters = (filters) => {
  const allFilterKeys = [
    "notificationsParameter",
    "mySchedulesParameter",
    "bookingParameter",
  ];
  const modifiedFilterKeys = [...allFilterKeys];

  useEffect(() => {
    modifiedFilterKeys.forEach((filterKey) => {
      if (!filters.includes(filterKey)) {
        localStorage.removeItem(filterKey);
      }
    });
  }, []);
};
