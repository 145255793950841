import React from "react";
import { history } from "../../helpers/utils";
import homeIcon from "../../assets/Icons/home.png";
import styles from "./index.module.css";
import NewToaster from "../NewToaster";
import { useSelector } from "react-redux";

const Layout = ({ children }) => {
  const { message } = useSelector((state) => state.newToasterReducer);

  return (
    <div className={styles["layout"]}>
      {message && <NewToaster />}
      <div className={styles["layout__home-icon"]}>
        <img
          src={homeIcon}
          alt="home"
          onClick={() => {
            history.push("/home");
          }}
        />
      </div>
      {children}
    </div>
  );
};

export default Layout;
