import React from "react";
import Calendar from "react-calendar";
import moment from "moment";
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from "react-icons/io";
import "./Calendar.css";

function NewCalender({ value, setValue, disableOlder }) {
  const date = new Date();
  return (
    <div>
      <Calendar
        calendarType="gregory"
        onChange={(value, event) => setValue(moment(value))}
        value={value}
        minDetail="decade"
        minDate={disableOlder ? null : date}
        prevLabel={<IoIosArrowRoundBack fontSize={32} />}
        nextLabel={<IoIosArrowRoundForward fontSize={32} />}
        showNeighboringMonth={false}
      />
    </div>
  );
}

export default NewCalender;
