import { combineReducers } from "redux";
import { userReducer } from "../Pages/login/userReducer";
import { myScheduleReducer } from "../Pages/MySchedules/actions/myScheduleReducer";
import { notificationsReducer } from "../Pages/Notifications/actions/notificationReducer";
import { toasterReducer } from "../components/Toaster/toasterReducer";
import { bookingReducer } from "../Pages/BookingOverview/actions/bookingReducer";
import { newToasterReducer } from "../components/NewToaster/newtoasterReducer";
import { quickBookingReducer } from "../Pages/QuickBookingRequest/Actions/quickbookingReducer";

export const rootReducer = combineReducers({
  userReducer,
  myScheduleReducer,
  notificationsReducer,
  toasterReducer,
  bookingReducer,
  newToasterReducer,
  quickBookingReducer,
});
