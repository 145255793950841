import requestMethods from "../../../helpers/request";
import {
  getItemFromLocalStorage,
  handleAlertError,
} from "../../../helpers/utils";
import { QUICK_BOOKING_ACTIONS } from "./constants";

export const getQuickSlotAvailability =
  (unitId, params, stopLoader) => async (dispatch) => {
    const { company: { id: companyId = "" } = {} } =
      getItemFromLocalStorage("user") || {};

    const { surgeryStartTime, surgeryEndTime, surgeryTypeId } = params;
    try {
      const res = await requestMethods.POST({
        endpoint: `v1/ot/schedule/request/web/${companyId}/${unitId}/get-quick-booking-slots`,
        body: {
          surgery_start_time: surgeryStartTime,
          surgery_end_time: surgeryEndTime,
          surgery_type_id: surgeryTypeId,
          ot: "",
        },
      });

      if (res.status) {
        dispatch({
          type: QUICK_BOOKING_ACTIONS.GET_QUICK_BOOKING_AVAILABILITY_SUCCESS,
          payload: res,
        });
        if (stopLoader) stopLoader(false);
      }
    } catch (error) {
      dispatch(handleAlertError(error.message));
      if (stopLoader) stopLoader(false);
    }
  };

export const getPatientList =
  (unitId, params, stopLoader) => async (dispatch) => {
    try {
      const { company: { id: companyId = "" } = {} } =
        getItemFromLocalStorage("user") || {};

      const res = await requestMethods.GET({
        endpoint: `v1/ot/patient-record/web/${companyId}/${unitId}/get-all-patient-records`,
        params,
      });

      if (res.status) {
        dispatch({
          type: QUICK_BOOKING_ACTIONS.GET_PATIENT_LIST_SUCCESS,
          payload: res?.patient_record_details,
        });
        if (stopLoader) stopLoader();
      }
    } catch (error) {
      dispatch(handleAlertError(error.message));
      if (stopLoader) stopLoader();
    }
  };
