import { BOOKING_ACTIONS } from "./constants";

const initialState = {
  bookings: [],
  totalCount: 0,
  departments: [],
  surgeryTypes: [],
  surgeons: [],
  anaesthetists: [],
  equipmentModels: [],
  bookingAvailabilityOverview: [],
  designations: [],
  requestDetails: {},
  tentativeOverview: {},
};

export const bookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case BOOKING_ACTIONS.GET_BOOKINGS_SUCCESS:
      return {
        ...state,
        bookings: action.payload,
        totalCount: action.totalCount,
      };
    case BOOKING_ACTIONS.CLEAR_BOOKINGS:
      return {
        ...state,
        bookings: [],
        totalCount: 0,
      };
    case BOOKING_ACTIONS.GET_DEPARTMENTS_SUCCESS: {
      return {
        ...state,
        departments: action.payload,
      };
    }
    case BOOKING_ACTIONS.GET_SURGERY_TYPES_SUCCESS: {
      return { ...state, surgeryTypes: action.payload };
    }
    case BOOKING_ACTIONS.GET_SURGEON_SUCCESS: {
      return { ...state, surgeons: action.payload };
    }
    case BOOKING_ACTIONS.GET_ANAESTHETIST_SUCCESS: {
      return { ...state, anaesthetists: action.payload };
    }
    case BOOKING_ACTIONS.GET_EQUIPMENT_MODELS_SUCCESS: {
      return { ...state, equipmentModels: action.payload };
    }
    case BOOKING_ACTIONS.GET_DESIGNATION_SUCCESS: {
      return { ...state, designations: action.payload };
    }
    case BOOKING_ACTIONS.GET_REQUEST_DETAILS_SUCCESS: {
      return { ...state, requestDetails: action.payload };
    }
    case BOOKING_ACTIONS.GET_BOOKING_AVAILABILITY_OVERVIEW_SUCCESS: {
      return { ...state, bookingAvailabilityOverview: action.payload };
    }
    case BOOKING_ACTIONS.CLEAR_BOOKING_AVAILABILITY_OVERVIEW: {
      return { ...state, bookingAvailabilityOverview: [] };
    }
    case BOOKING_ACTIONS.CLEAR_REQUEST_DETAILS: {
      return { ...state, requestDetails: {} };
    }
    case BOOKING_ACTIONS.GET_TENTATIVE_OVERVIEW_SUCCESS: {
      return { ...state, tentativeOverview: action.payload };
    }
    default:
      return state;
  }
};
