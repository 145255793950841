import React from "react";
import {
  Route,
  Router,
  Switch,
} from "react-router-dom/cjs/react-router-dom.min";
import { history } from "../helpers/utils";
import Login from "../Pages/login";
import Components from "./Components";
import { ProtectedRoute } from "../components/ProtectedRoute";

export const routePath = {
  login: "/",
  home: "/home",
  mySchedules: "/newMySchedules",
  bookingRequest: "/newBookingRequest",
  requestDetails: "/requestDetails/:requestId",
  requestSuccess: "/requestSuccess",
  myRequests: "/myRequests",
  schedueDetails: "/scheduleDetails/:scheduleId",
  quickBookingRequest: "/quickBookingRequest",
  bookingOverview: "/bookingOverview",
};

const protectedRoutes = [
  {
    exact: true,
    path: routePath.home,
    component: Components.HomePage,
    useLayout: false,
  },
  {
    exact: true,
    path: routePath.bookingRequest,
    component: Components.NewBookingRequest,
  },
  {
    exact: true,
    path: routePath.myRequests,
    component: Components.MyRequests,
  },
  {
    exact: true,
    path: routePath.requestDetails,
    component: Components.RequestDetails,
  },
  {
    exact: true,
    path: routePath.mySchedules,
    component: Components.NewMySchedules,
  },
  {
    exact: true,
    path: routePath.schedueDetails,
    component: Components.ScheduleDetails,
  },
  {
    exact: true,
    path: routePath.requestSuccess,
    component: Components.RequestSuccess,
    useLayout: false,
  },
  {
    exact: true,
    path: routePath.quickBookingRequest,
    component: Components.QuickBookingRequest,
  },
  {
    exact: true,
    path: routePath.bookingOverview,
    component: Components.BookingOverview,
  },
];

const Routes = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={Login} />
        {protectedRoutes.map((route) => (
          <ProtectedRoute key={route.path} {...route} />
        ))}
        <Route path="*" component={Login} />
      </Switch>
    </Router>
  );
};

export default Routes;
